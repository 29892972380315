import axiosClient from "utils/api/axios-client";

const contactApi = {
  getAll: (params) => {
    const url = "/api/v1/contacts";
    return axiosClient.get(url, { params });
  },

  get: (id) => {
    const url = `/api/v1/contacts/${id}`;
    return axiosClient.get(url);
  },
};

export default contactApi;
