import {
  CreditCardOutlined,
  CrownOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileSyncOutlined,
  FireOutlined,
  FormOutlined,
  GiftOutlined,
  ReloadOutlined,
  ShopOutlined,
  ShoppingOutlined,
  StarOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LowerBalanceRestaurant from "./LowerBalanceRestaurant";
import TopRestaurant from "./TopRestaurant";
import TopShipper from "./TopShipper";
import dashboardApi from "./api";
import { useSelector } from "react-redux";
import { Forbidden } from "components/index";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: "Hôm nay",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Hôm qua",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Tuần này",
    value: [dayjs().startOf("week"), dayjs()],
  },
  {
    label: "Tuần trước",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "Tháng này",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Tháng trước",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Tất cả",
    value: [dayjs("01/07/2023", "DD/MM/YYYY"), dayjs()],
  },
];
function Dashboard() {
  const { userInfo } = useSelector((state) => state.auth);

  const [range, setRange] = useState([dayjs(), dayjs()]);
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({});

  useEffect(() => {
    if (userInfo?.role === "customer_service") {
      location.href = "/tai-xe/vi-tri";
    }
  }, [userInfo]);

  const getStats = async () => {
    setIsLoading(true);
    try {
      const data = await dashboardApi.getDashboardStats(
        range[0].format("YYYY-MM-DD"),
        range[1].format("YYYY-MM-DD")
      );
      if (!data || data.error) {
        toast.error(data.error);
        setStats({});
      } else {
        setStats(data);
      }
    } catch (er) {
      setStats({});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, [range]);

  const onRangeChange = (dates) => {
    setRange(dates);
  };

  const onRefresh = () => {
    getStats();
  };

  if (userInfo?.role === "customer_service") {
    return <Forbidden />;
  }

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ padding: "0 10px", marginBottom: "20px" }}
      >
        <h2 style={{ fontSize: "28px" }}>
          <DashboardOutlined /> GOKA Dashboard
        </h2>
        <Space size="middle">
          <RangePicker
            presets={rangePresets}
            value={range}
            onChange={onRangeChange}
          />
          <Button
            type="primary"
            icon={<ReloadOutlined />}
            onClick={onRefresh}
          />
        </Space>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tổng đơn"
              value={stats.totalOrders || 0}
              prefix={<FormOutlined />}
              loading={isLoading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Đơn đang chạy"
              value={stats.processingOrders || 0}
              loading={isLoading}
              prefix={<FileSyncOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Đơn hoàn thành"
              value={stats.completedOrders || 0}
              loading={isLoading}
              prefix={<FileDoneOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Đơn hủy"
              value={stats.canceledOrders || 0}
              suffix={`/ ${stats.percentCanceledOrders || 0}%`}
              loading={isLoading}
              prefix={<FileExcelOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Người dùng mới"
              value={stats.newUsers || 0}
              loading={isLoading}
              prefix={<UserAddOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Giá trị đơn hàng"
              value={parseFloat((stats.total || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<ShoppingOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tổng tiền voucher"
              value={parseFloat((stats.totalVoucher || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<GiftOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Thưởng tài xế"
              value={parseFloat((stats.totalShipperRewards || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<StarOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tổng tiền quán nhận"
              value={parseFloat((stats.totalRestaurantReceive || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<ShopOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tổng tiền tài xế nhận"
              value={parseFloat((stats.totalShipperReceive || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<TeamOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tổng tiền app nhận"
              value={parseFloat((stats.totalAppReceive || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<CreditCardOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Lợi nhuận app"
              value={parseFloat((stats.totalProfit || 0).toFixed(2))}
              suffix="₫"
              loading={isLoading}
              prefix={<CrownOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Title level={3} style={{ marginBottom: "20px" }}>
        <FireOutlined /> Top quán bán chạy
      </Title>
      <TopRestaurant isLoading={isLoading} dataSource={stats.topRestaurants} />
      <br />
      <Title level={3} style={{ marginBottom: "20px" }}>
        <UserSwitchOutlined /> Top tài xế
      </Title>
      <TopShipper isLoading={isLoading} dataSource={stats.topShippers} />
      <br />
      <Title level={3} style={{ marginBottom: "20px" }}>
        <WarningOutlined /> Quán số dư thấp
      </Title>
      <LowerBalanceRestaurant
        isLoading={isLoading}
        dataSource={stats.lowerBalanceRestaurants}
      />
    </>
  );
}

export default Dashboard;
