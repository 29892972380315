import axiosClient from "utils/api/axios-client";

const shipperApi = {
  getAll: (params) => {
    const url = "/api/v1/shippers";
    return axiosClient.get(url, { params });
  },
  getList: () => {
    const url = "/api/v1/shippers/list";
    return axiosClient.get(url);
  },
  getOnlines: () => {
    const url = "/api/v1/shippers/online";
    return axiosClient.get(url);
  },
  getPerformance: (id, day) => {
    const url = `/api/v1/shippers/${id}/performance?day=${day}`;
    return axiosClient.get(url);
  },
  getTracking: (id, from, to) => {
    const url = `/api/v1/shippers/${id}/tracking-on-off?from=${from}&to=${to}`;
    return axiosClient.get(url);
  },
  get: (id) => {
    const url = `/api/v1/shippers/${id}`;
    return axiosClient.get(url);
  },
  create: (shipper) => {
    const url = `/api/v1/shippers/`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, shipper, config);
  },
  update: (id, shipper) => {
    const url = `/api/v1/shippers/${id}`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.put(url, shipper, config);
  },
  updateGetFreePick: (id, getFreePick) => {
    const url = `/api/v1/shippers/${id}/get-freepick`;
    return axiosClient.patch(url, { getFreePick });
  },
  delete: (id) => {
    const url = `/api/v1/shippers/${id}`;
    return axiosClient.delete(url);
  },
  block: (id, blockData) => {
    const url = `/api/v1/shippers/${id}/block`;
    return axiosClient.patch(url, blockData);
  },
  updateWeeklyPoint: (id, data) => {
    const url = `/api/v1/shippers/${id}/weekly-point`;
    return axiosClient.patch(url, data);
  },
  resetWeeklyPoint: () => {
    const url = `/api/v1/shippers/reset-weekly-point`;
    return axiosClient.patch(url);
  },
};

export default shipperApi;
