// import shipper from "../assets/img/shipper.png";
import { SyncOutlined, WarningOutlined } from "@ant-design/icons";
import { Badge, Tag } from "antd";
import { getPhotoUrl } from "utils/common";

function ShipperStatus({ shipper }) {
  if (!shipper.isReady) return <Tag color="default">Đang tắt</Tag>;
  if (!shipper.processingOrders?.length) {
    if (shipper.location)
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          Chờ đơn
        </Tag>
      );
    else
      return (
        <Tag icon={<WarningOutlined />} color="error">
          Mất định vị
        </Tag>
      );
  }
  return (
    <>
      {shipper.processingOrders.map((orderProcessing) => {
        if (orderProcessing.deliveryStatus === "confirm-customer")
          return (
            <Tag
              key={orderProcessing._id}
              color="gold"
              style={{
                marginBottom: shipper.processingOrders?.length ? "3px" : 0,
              }}
            >
              Chờ xác nhận
            </Tag>
          );
        if (orderProcessing.deliveryStatus === "going-to-restaurant")
          return (
            <Tag
              key={orderProcessing._id}
              color="magenta"
              style={{
                marginBottom: shipper.processingOrders?.length ? "3px" : 0,
              }}
            >
              Đang đến nhà hàng
            </Tag>
          );
        if (orderProcessing.deliveryStatus === "arrived-restaurant")
          return (
            <Tag
              key={orderProcessing._id}
              color="cyan"
              style={{
                marginBottom: shipper.processingOrders?.length ? "3px" : 0,
              }}
            >
              Đã đến nhà hàng
            </Tag>
          );
        if (orderProcessing.deliveryStatus === "picked-up")
          return (
            <Tag
              key={orderProcessing._id}
              color="green"
              style={{
                marginBottom: shipper.processingOrders?.length ? "3px" : 0,
              }}
            >
              Đã lấy hàng
            </Tag>
          );
      })}
    </>
  );
}

function ShipperItem(props) {
  const { shipper, selectedShipper, setSelectedShipper } = props;

  return (
    <li
      className={`shipper-item ${
        selectedShipper?.phone === shipper.phone ? "active" : ""
      }`}
      onClick={() => setSelectedShipper(shipper)}
    >
      <div className="shipper-avatar">
        <Badge dot={true} status={shipper.isReady ? "success" : "default"}>
          <img
            src={getPhotoUrl(shipper.avatar?.path || null)}
            alt={shipper.name}
          />
        </Badge>
      </div>
      <div className="shipper-info">
        <h4>{shipper.name}</h4>
        <span>
          {shipper.orderCountToday} đơn - <ShipperStatus shipper={shipper} />
        </span>
      </div>
    </li>
  );
}

export default ShipperItem;
