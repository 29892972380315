import { Descriptions, Drawer } from "antd";
import { flatten } from "flat";
import { useEffect, useState } from "react";
import { priceToCurrency } from "utils/common";
import orderApi from "../api";

function OrderDetailDrawer(props) {
  const { open, onClose, order } = props;

  const [detail, setDetail] = useState(null);

  useEffect(() => {
    const getOrderCost = async () => {
      const data = await orderApi.getCost(order._id);
      setDetail({ ...flatten(data) });
    };
    getOrderCost();
  }, [order]);

  const detailFields = [
    {
      key: "code",
      label: "Mã đơn",
      isNotCurrency: true,
      labelStyle: {
        fontSize: "18px",
      },
      contentStyle: {
        fontSize: "18px",
      },
    },
    {
      key: "dishCount",
      label: "Số món",
      isNotCurrency: true,
    },
    {
      key: "distance",
      label: "Khoảng cách",
      render: (detailOrder) => `${detailOrder.distance}km`,
    },
    {
      key: "dishFeeActual",
      label: "Tổng giá món",
    },
    {
      key: "dishFee",
      label: "Tổng giá món sau giảm giá",
    },
    {
      key: "deliveryFeeActual",
      label: "Phí ship",
    },
    {
      key: "deliveryFee",
      label: "Phí ship sau giảm giá",
    },
    {
      key: "serviceFee",
      label: "Phí dịch vụ",
    },
    {
      key: "toDoorFee",
      label: "Phí giao tận cửa",
    },
    {
      key: "tip",
      label: "Tip cho tài xế",
    },
    {
      key: "discount",
      label: "Giảm giá",
    },
    {
      key: "discountObj",
      label: "Đối tượng giảm giá",
      isNotCurrency: true,
    },
    {
      key: "discountRestaurantPaid",
      label: "Số tiền giảm giá nhà hàng chịu",
    },
    {
      key: "discountAppPaid",
      label: "Số tiền giảm giá app chịu",
    },
    {
      key: "totalActual",
      label: "Tổng giá trị đơn hàng ban đầu",
    },
    {
      key: "total",
      label: "Tổng giá trị đơn hàng sau giảm giá",
    },
    {
      key: "totalRestaurantFee",
      label: "Số tiền shipper trả nhà hàng",
    },
    {
      key: "totalUserFee",
      label: "Số tiền shipper thu khách",
    },
    {
      key: "restaurantCharge",
      label: "% chiết khấu nhà hàng",
      render: (detail) => `${detail.restaurantCharge}%`,
    },
    {
      key: "restaurantReceiveActual",
      label: "Số tiền nhà hàng thực tế nhận được",
    },
    {
      key: "restaurantTransValue",
      label: "Số tiền cần cộng trừ vào ví nhà hàng",
    },
    {
      key: "shipperCharge",
      label: "% chiết khấu shipper",
      render: (detail) => `${detail.shipperCharge}%`,
    },
    {
      key: "shipperReceiveActual",
      label: "Số tiền shipper thực tế nhận được",
    },
    {
      key: "shipperTransValue",
      label: "Số tiền cần cộng trừ vào ví shipper",
    },
    {
      key: "appReceiveActual",
      label: "Số tiền app thực tế nhận được",
    },
    {
      key: "deliveryExtraActive",
      label: "Chế độ giờ cao điểm",
      render: (detailOrder) =>
        detailOrder.deliveryExtraActive ? "Có" : "Không",
    },
    {
      key: "deliveryExtraPercent",
      label: "Tăng phí ship giờ cao điểm",
      render: (detailOrder) =>
        detailOrder.deliveryExtraValueType === "percent"
          ? `${detailOrder.deliveryExtraValue}%`
          : priceToCurrency(detailOrder.deliveryExtraValue),
    },
    {
      key: "paymentMethod",
      label: "Phương thức thanh toán",
      isNotCurrency: true,
    },
  ];

  return (
    detail && (
      <Drawer
        title="Chi tiết phí"
        placement="right"
        onClose={onClose}
        open={open}
        width={500}
      >
        <Descriptions bordered>
          {detailFields.map((field) => (
            <Descriptions.Item
              label={field.label}
              key={field.key}
              span={24}
              labelStyle={{ ...(field.labelStyle || {}), fontWeight: 600 }}
              contentStyle={{ ...(field.contentStyle || {}) }}
            >
              {field.render
                ? field.render(detail)
                : field.isNotCurrency
                ? detail[field.key]
                : priceToCurrency(detail[field.key])}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Drawer>
    )
  );
}

export default OrderDetailDrawer;
