import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Modal, Select, Upload } from "antd";
import DebounceSelect from "components/common/DebounceSelect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import { getBase64, getPhotoUrl } from "utils/common";

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.tz.setDefault("Asia/Ho_Chi_Minh")

const { RangePicker } = DatePicker;

function RestaurantLabelDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onSubmit, onCancel, restaurantLabel } = props;
  const [imageUrl, setImageUrl] = useState(
    getPhotoUrl(restaurantLabel?.image?.path || null, "w=200&h=102")
  );
  const [image, setImage] = useState(null);

  const initialValues = restaurantLabel
    ? {
        ...restaurantLabel,
        restaurants: restaurantLabel.restaurants.map((e) => ({
          key: e._id,
          label: e.name,
          value: e._id,
        })),
        startTime: dayjs(restaurantLabel.startTime).format("YYYY-MM-DD HH:mm"),
        endTime: dayjs(restaurantLabel.endTime).format("YYYY-MM-DD HH:mm"),
        time: [
          dayjs(restaurantLabel.startTime, "YYYY-MM-DD HH:mm").add(7, "hour"),
          dayjs(restaurantLabel.endTime, "YYYY-MM-DD HH:mm").add(7, "hour"),
        ],
      }
    : {
        name: "",
        slug: "",
        startTime: "",
        endTime: "",
        priority: 99,
        active: true,
      };
  const onValuesChange = (changedValues) => {
    if (changedValues.time) {
      form.setFieldValue(
        "startTime",
        changedValues.time[0].format("YYYY-MM-DD HH:mm")
      );
      form.setFieldValue(
        "endTime",
        changedValues.time[1].format("YYYY-MM-DD HH:mm")
      );
    }
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        const restaurantLabel = { ...initialValues, ...values };
        if (restaurantLabel.restaurants) {
          restaurantLabel.restaurants = restaurantLabel.restaurants.map(
            (e) => e.value
          );
        }

        console.log(restaurantLabel);

        Object.entries({
          data: JSON.stringify(restaurantLabel),
          image,
        }).forEach(([key, value]) => {
          formData.append(key, value);
        });

        delete restaurantLabel.time;

        onSubmit(restaurantLabel, formData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const beforeUpload = async (file) => {
    const validType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!validType) {
      toast.error("Chỉ chấp nhận file ảnh!");
    } else {
      const preview = await getBase64(file);
      setImageUrl(preview);
      setImage(file);
    }
    return false;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      title="Nhãn quán"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
      width={680}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="startTime" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="endTime" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên nhãn"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input placeholder="Tên nhãn" />
        </Form.Item>
        <Form.Item
          name="restaurants"
          label="DS quán"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <DebounceSelect
            mode="multiple"
            placeholder="Chọn danh sách quán thuộc nhãn"
            initOptions={
              restaurantLabel?.restaurants
                ? restaurantLabel.restaurants.map((e) => ({
                    key: e._id,
                    label: e.name,
                    value: e._id,
                  }))
                : []
            }
            fetchOptions={commonApi.searchRestaurant}
            convertOptions={(data) =>
              data.map((res) => ({
                key: res._id,
                label: res.name,
                value: res._id,
              }))
            }
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item name="priority" label="Thứ tự ưu tiên">
          <Input type={"number"} min={0} />
        </Form.Item>

        <Form.Item
          name="time"
          label="Thời giạn hiển thị"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <RangePicker
            showTime
            style={{ width: "100%" }}
            placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item label="Hình ảnh">
          <Upload
            accept={"image/*"}
            name="image"
            listType="picture-card"
            className="avatar-uploader-cover"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="image" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="active"
          label="Trạng thái"
          rules={[{ required: true }]}
        >
          <Select placeholder="--Trạng thái--">
            <Select.Option value={true}>Active</Select.Option>
            <Select.Option value={false}>In-active</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

RestaurantLabelDetailModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  restaurantLabel: PropTypes.object,
};

RestaurantLabelDetailModal.defaultProps = {
  isProcessing: false,
  open: false,
  restaurantLabel: null,
};

export default RestaurantLabelDetailModal;
