import { Button, List, Modal } from "antd";
import dayjs from "dayjs";

const EDITED_BY = {
  admin: "Hệ thống",
  shipper: "Tài xế",
  merchant: "Chủ quán",
};

export default function EditHistoryModal({ open, onClose, order }) {
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        title="Lịch sử chỉnh sửa đơn hàng"
        footer={[
          <Button type="primary" key="back" onClick={onClose}>
            OK
          </Button>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={order?.history || []}
          renderItem={(item) => (
            <List.Item
              actions={[
                <h3
                  style={{ color: item.amount.includes("+") ? "green" : "red" }}
                  key="amount"
                >
                  {item.amount}
                </h3>,
              ]}
            >
              <List.Item.Meta
                title={<h3 style={{ marginBottom: 10 }}>{item.reason}</h3>}
                description={`Chỉnh sửa bới ${
                  EDITED_BY[item.editedBy]
                } - ${dayjs(item.editedAt).format("HH:mm")}`}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}
