import { StarOutlined, StopOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Image,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { getPhotoUrl, priceToCurrency } from "utils/common";

function TopShipper(props) {
  const { dataSource, isLoading } = props;

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tên tài xế",
      render: (text, record) => (
        <div className="flex items-center gap-x-4">
          <Badge
            dot={true}
            status={record.info.isReady ? "success" : "default"}
          >
            {record.info.avatar[0] ? (
              <Image
                alt="Avatar of shipper"
                src={getPhotoUrl(record.info.avatar[0]?.path || null)}
                width="50px"
                height="50px"
                rootClassName="rounded-full overflow-hidden"
              />
            ) : (
              <Avatar
                className="select-none"
                alt="Avatar of user"
                size={50}
                style={{ backgroundColor: "#FFD22F" }}
              >
                {record.info.name.toUpperCase()}
              </Avatar>
            )}
          </Badge>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography.Text strong>{record.info.name}</Typography.Text>
            <Typography.Text>{record.info.phone}</Typography.Text>
            {record.info.oblocked ? (
              <Tooltip
                title={`Lý do: ${record.info.blockedReason}`}
                color="red"
              >
                <Tag icon={<StopOutlined />} color="error">
                  Bị khóa đến{" "}
                  {moment(record.info.blockedTo).format("HH:mm DD/MM/YY")}
                </Tag>
              </Tooltip>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Đánh giá",
      dataIndex: ["info", "rating"],
      align: "center",
      render: (data) => (
        <>
          <StarOutlined /> {data}
        </>
      ),
    },
    {
      title: "Độ ưu tiên",
      align: "center",
      dataIndex: ["info", "priority"],
    },
    // {
    //   title: "Chế độ",
    //   dataIndex: "mode",
    // },
    {
      title: "Số dư ví",
      dataIndex: ["info", "balance"],
      render: (data) => priceToCurrency(data),
    },
    // {
    //   title: "Chỉ số",
    //   dataIndex: "score",
    // },
    {
      title: "Tổng đơn",
      dataIndex: "totalOrders",
      align: "center",
    },
    // {
    //   title: "Nhận đơn",
    //   dataIndex: "isReady",
    //   align: "center",
    //   render: (isReady) =>
    //     isReady ? (
    //       <Tag color="#4caf50">Sẵn sàng</Tag>
    //     ) : (
    //       <Tag color="#f44336">Không nhận</Tag>
    //     ),
    // },
    {
      title: "FreePick",
      align: "center",
      width: "10%",
      render: (record) => (
        <Switch size="default" checked={record.info.getFreePick} />
      ),
    },
    {
      title: "Nhận đơn",
      // dataIndex: "isOpen",
      align: "center",
      width: "10%",
      render: (record) => (
        <Switch size="default" checked={record.info.isReady} />
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: ["info", "active"],
      align: "center",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
  ];

  return (
    <Table
      bordered
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.info._id}
      pagination={false}
    />
  );
}

export default TopShipper;
