import { createSlice } from "@reduxjs/toolkit";
import keyApi from "./api";
import { toast } from "react-toastify";

const initialState = {
  keys: [],
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const mapServiceSlice = createSlice({
  name: "mapService",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setKeys: (state, action) => {
      state.keys = action.payload;
    },
    addKey: (state, action) => {
      state.isCompleted = true;
      state.keys.unshift(action.payload);
    },
    editKey: (state, action) => {
      state.isCompleted = true;
      state.keys = state.keys.map((key) =>
        key._id === action.payload._id ? action.payload : key
      );
    },
    removeKey: (state, action) => {
      state.isCompleted = true;
      state.keys = state.keys.filter((key) => key._id !== action.payload);
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setKeys,
  addKey,
  editKey,
  removeKey,
} = mapServiceSlice.actions;

export const fetchKeys = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await keyApi.getMapKeys(params);
      dispatch(setKeys(data.data));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const createKey = (key) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await keyApi.createMapKey(key);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addKey(data));
        toast.success("Thêm key mới thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateKey = (key) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await keyApi.updateMapKey(key);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editKey(data));
        toast.success("Cập nhật key thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const deleteKey = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await keyApi.deleteMapKey(id);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(removeKey(id));
        toast.success("Xóa key thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default mapServiceSlice.reducer;
