import {
  CloseOutlined,
  FieldTimeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  classNames,
  getBase64,
  getPhotoThumbnailUrl,
  getPhotoUrl,
  parseTime,
} from "utils/common";
import restaurantApi from "../../api";
import * as restaurantActions from "../../slice";

const getTimeTemp = (time) => {
  return {
    0: time[0].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
    1: time[1].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
    2: time[2].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
    3: time[3].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
    4: time[4].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
    5: time[5].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
    6: time[6].map((t) => ({
      open: parseTime(t.open),
      close: parseTime(t.close),
    })),
  };
};

const { RangePicker } = DatePicker;

export default function TabInfo({
  form,
  restaurant,
  setTime,
  setOpenTimeModal,
  image,
  setImage,
  cover,
  setCover,
  onCancel,
}) {
  const [imageUrl, setImageUrl] = useState(
    getPhotoThumbnailUrl(restaurant?.avatar?.path || null)
  );
  const [coverUrl, setCoverUrl] = useState(
    getPhotoUrl(restaurant?.cover?.path || null, "w=200&h=102")
  );
  const [isGettingGeoAddress, setIsGettingGeoAddress] = useState(false);

  const { categories } = useSelector((state) => state.category);
  const { isProcessing } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  const onGetTime = () => {
    const time = { ...form.getFieldValue("time") };
    setTime(getTimeTemp(time));
    setOpenTimeModal(true);
  };

  const restaurantStatusProperties = [
    { name: "active", label: "Kích hoạt" },
    { name: "isNew", label: "Quán mới" },
    { name: "isHot", label: "Quán nổi bật" },
    { name: "isPartner", label: "Quán đối tác" },
  ];

  const tagOptions = [
    { label: "Orange Tag", value: "#d46b08;#fff7e6" },
    { label: "Blue Tag", value: "#0958d9;#e6f4ff" },
    { label: "Green Tag", value: "#389e0d;#f6ffed" },
    { label: "Geekblue Tag", value: "#1d39c4;#f0f5ff" },
    { label: "Purple Tag", value: "#531dab;#f9f0ff" },
  ];

  const shouldUpdateRestaurantStatusFormItem = (prev, current) => {
    for (const property of restaurantStatusProperties) {
      if (prev[property.name] !== current[property.name]) {
        return true;
      }
    }
    return false;
  };

  const beforeUpload = async (file) => {
    const validType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!validType) {
      toast.error("Chỉ chấp nhận file ảnh!");
    } else {
      const preview = await getBase64(file);
      setImageUrl(preview);
      setImage(file);
    }
    return false;
  };

  const beforeUploadCover = async (file) => {
    const validType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!validType) {
      toast.error("Chỉ chấp nhận file ảnh!");
    } else {
      const preview = await getBase64(file);
      setCoverUrl(preview);
      setCover(file);
    }
    return false;
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        const newRestaurantData = { ...values };
        newRestaurantData.tags = newRestaurantData.tags.map((tag) => {
          const _tag = {
            color: tag.color.split(";")[0],
            bgColor: tag.color.split(";")[1],
            text: tag.text,
          };
          if (tag.time) {
            _tag.startTime = tag.time[0].format("YYYY-MM-DD HH:mm");
            _tag.endTime = tag.time[1].format("YYYY-MM-DD HH:mm");
          }
          return _tag;
        });
        delete newRestaurantData.account;
        delete newRestaurantData.banking;
        Object.entries({
          data: JSON.stringify(newRestaurantData),
          image,
          cover,
        }).forEach(([key, value]) => {
          formData.append(key, value);
        });
        dispatch(restaurantActions.updateRestaurantInfo(restaurant, formData));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onGetGeoAddress = async () => {
    if (!isGettingGeoAddress) {
      const address = form.getFieldValue("address");
      try {
        setIsGettingGeoAddress(true);
        const data = await restaurantApi.getGeoAddress(address);
        const { long, lat } = data;
        form.setFieldsValue({ long, lat });
      } catch (e) {
        toast.error("Lấy tọa độ thất bại! Vui lòng thử lại sau");
      } finally {
        setIsGettingGeoAddress(false);
      }
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={14}>
          <Form.Item
            name="name"
            label="Tên"
            rules={[
              {
                required: true,
                message: "Trường này là bắt buộc",
              },
            ]}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 7 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 17 },
            }}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="slug"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            label={false}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="categories"
        label="Danh mục"
        rules={[{ required: true, type: "array" }]}
      >
        <Select mode="multiple" placeholder="--Danh mục--">
          {categories.map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="address"
        label="Địa chỉ"
        rules={[
          {
            required: true,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <Input
          addonAfter={
            <div
              className={classNames(
                "select-none",
                isGettingGeoAddress ? "opacity-50" : "cursor-pointer"
              )}
              onClick={onGetGeoAddress}
            >
              Lấy tọa độ
            </div>
          }
        />
      </Form.Item>
      <Row gutter={24}>
        <Col span={14}>
          <Form.Item
            name="long"
            label="Tọa độ"
            labelCol={{
              xs: { span: 24 },
              sm: { span: 7 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 17 },
            }}
          >
            <Input addonBefore="Kinh độ" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="lat"
            label={false}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Input addonBefore="Vĩ độ" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="time" label="T.gian mở cửa">
        <div
          onClick={onGetTime}
          style={{
            display: "inline-block",
            cursor: "pointer",
            color: "#1890ff",
            margin: "4px 8px",
          }}
        >
          <FieldTimeOutlined /> Thiết lập giờ mở cửa
        </div>
      </Form.Item>
      <Form.Item
        name="phone"
        label="Số điện thoại"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="quote" label="Ghi chú">
        <Input />
      </Form.Item>
      <Form.Item name="keywords" label="Từ khóa tìm kiếm">
        <TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          placeholder="Vui lòng nhập từ khóa liên quan có thể tìm kiếm quán"
        />
      </Form.Item>
      <Form.Item label="Tags">
        <Form.List name="tags">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  align="start"
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, "color"]}
                    style={{ width: "140px", marginBottom: "10px" }}
                  >
                    <Select options={tagOptions} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "text"]}
                    style={{ width: "135px", marginBottom: "10px" }}
                    rules={[
                      {
                        required: true,
                        message: "Nội dung không được để trống",
                      },
                    ]}
                  >
                    <Input placeholder="Nội dung" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "time"]}
                    style={{ width: "330px", marginBottom: "10px" }}
                  >
                    <RangePicker
                      showTime
                      style={{ width: "100%" }}
                      placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                  <Button
                    style={{ height: "32px" }}
                    size={"small"}
                    onClick={() => remove(name)}
                  >
                    <CloseOutlined />
                  </Button>
                </Space>
              ))}
              <Button
                style={{ height: "32px" }}
                size={"small"}
                onClick={() => add({ color: tagOptions[0].value, text: "" })}
              >
                <PlusOutlined /> Thêm tag
              </Button>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item label="Ảnh đại diện" rules={[{ required: true }]}>
        <Space size={"large"}>
          <Upload
            accept={"image/*"}
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="image" style={{ width: "100%" }} />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
          <Space align="start">
            Ảnh Cover:
            <Upload
              accept={"image/*"}
              name="image"
              listType="picture-card"
              className="avatar-uploader avatar-uploader-cover"
              showUploadList={false}
              beforeUpload={beforeUploadCover}
            >
              {coverUrl ? (
                <img src={coverUrl} alt="image" style={{ width: "100%" }} />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Space>
        </Space>
      </Form.Item>
      <Row>
        <Col xs={0} sm={4}></Col>
        <Col xs={24} sm={20}>
          <Form.Item shouldUpdate={shouldUpdateRestaurantStatusFormItem}>
            {() => (
              <Space size="large" wrap>
                {restaurantStatusProperties.map((property) => {
                  const isStatusTrue = form.getFieldValue(property.name);
                  return (
                    <Form.Item
                      name={property.name}
                      key={property.name}
                      valuePropName="checked"
                    >
                      <Switch
                        defaultChecked={isStatusTrue}
                        checkedChildren={property.label}
                        unCheckedChildren={property.label}
                      />
                    </Form.Item>
                  );
                })}
              </Space>
            )}
          </Form.Item>
        </Col>
      </Row>
      {restaurant && (
        <>
          <Button
            className="float-right"
            type="primary"
            onClick={onSubmit}
            loading={isProcessing}
          >
            Lưu thay đổi thông tin quán ăn
          </Button>
          <Button
            className="float-right mr-4"
            onClick={onCancel}
            disabled={isProcessing}
          >
            Hủy
          </Button>
        </>
      )}
    </>
  );
}
