import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Switch,
  TimePicker,
} from "antd";
import DebounceSelect from "components/common/DebounceSelect";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import { formatTime, parseTime } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";
import voucherApi from "../api";

const { TextArea, Search } = Input;
const { RangePicker } = DatePicker;

const properties = [
  { name: "active", label: "Kích hoạt" },
  { name: "isSecret", label: "Mã bí mật" },
  { name: "canMerchantJoin", label: "Cho phép quán tham gia" },
];

const PAYMENT_METHODS = [
  { label: "Tiền mặt", value: "cod" },
  { label: "Ví GOKA", value: "goka-wallet" },
  { label: "VietQR", value: "qrcode" },
];

function VoucherDetailModal(props) {
  const [form] = Form.useForm();
  const {
    open,
    isProcessing,
    onSubmit,
    onCancel,
    onVerify,
    onReject,
    voucher,
  } = props;
  const [isGettingCode, setIsGetingCode] = useState(false);
  const [owner, setOwner] = useState(voucher?.owner || "app");
  const [isApplyAllUser, setIsApplyAllUser] = useState(
    voucher?.isApplyAllUser || true
  );
  const [isApplyAllRestaurant, setIsApplyAllRestaurant] = useState(
    voucher?.isApplyAllRestaurant || true
  );
  const [isApplyAllPaymentMethod, setIsApplyAllPaymentMethod] = useState(
    voucher?.isApplyAllPaymentMethod || true
  );

  useEffect(() => {
    setOwner(voucher?.owner || "app");
    setIsApplyAllUser(voucher?.isApplyAllUser ?? true);
    setIsApplyAllRestaurant(voucher?.isApplyAllRestaurant ?? true);
    setIsApplyAllPaymentMethod(voucher?.isApplyAllPaymentMethod ?? true);
  }, [open, voucher]);

  const initialValues = voucher
    ? {
        ...voucher,

        startTime: voucher.startTime ?? 0,
        endTime: voucher.endTime ?? 1439,
        restaurant: voucher.restaurant
          ? { label: voucher.restaurant.name, value: voucher.restaurant._id }
          : undefined,
        users: voucher.users.map((e) => ({
          key: e._id,
          label: `${e.name}-${e._id}`,
          value: e._id,
        })),
        restaurants: voucher.restaurants.map((e) => ({
          key: e._id,
          label: e.name,
          value: e._id,
        })),
        date: [
          dayjs(voucher.startDate, "YYYY-MM-DD").add(1, "day"),
          dayjs(voucher.endDate, "YYYY-MM-DD"),
        ],
        time: [
          dayjs(parseTime(voucher.startTime ?? 0), "HH:mm"),
          dayjs(parseTime(voucher.endTime ?? 1439), "HH:mm"),
        ],
        isApplyAllPaymentMethod: voucher.isApplyAllPaymentMethod ?? true,
        paymentMethods: voucher.paymentMethods ?? [],
      }
    : {
        startDate: "",
        endDate: "",
        startTime: 0,
        endTime: 1439,
        owner: "app",
        discountObj: "delivery-fee",
        discountValueType: "direct",
        isApplyAllUser: true,
        isApplyAllRestaurant: true,
        isApplyAllPaymentMethod: true,
        active: true,
        time: [dayjs("00:00", "HH:mm"), dayjs("23:59", "HH:mm")],
      };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const voucher = { ...initialValues, ...values };
        if (voucher.restaurant) {
          voucher.restaurant = voucher.restaurant.value;
        }
        if (voucher.restaurants) {
          voucher.restaurants = voucher.restaurants.map((e) => e.value);
        }
        if (voucher.users) {
          voucher.users = voucher.users.map((e) => e.value);
        }
        delete voucher.date;
        delete voucher.time;

        console.log(voucher);
        onSubmit(voucher);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onGetCode = async () => {
    try {
      setIsGetingCode(true);
      const code = await voucherApi.getCode();
      if (!code) toast.error("Không lấy được mã voucher random");
      else {
        form.setFieldsValue({ code });
        toast.success("Lấy mã voucher thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsGetingCode(false);
    }
  };

  const shouldUpdatePropertyFormItem = (prev, current) => {
    for (const property of properties) {
      if (prev[property.name] !== current[property.name]) {
        return true;
      }
    }
    return false;
  };

  const onValueChange = (changedValues, allValues) => {
    if (changedValues.date) {
      form.setFieldValue(
        "startDate",
        changedValues.date[0].format("YYYY-MM-DD")
      );
      form.setFieldValue("endDate", changedValues.date[1].format("YYYY-MM-DD"));
    } else if (changedValues.time) {
      form.setFieldValue(
        "startTime",
        formatTime(changedValues.time[0].format("HH:mm"))
      );
      form.setFieldValue(
        "endTime",
        formatTime(changedValues.time[1].format("HH:mm"))
      );
    } else if (changedValues.owner) {
      setOwner(allValues.owner);
    } else if (changedValues.isApplyAllUser !== undefined) {
      setIsApplyAllUser(allValues.isApplyAllUser);
    } else if (changedValues.isApplyAllRestaurant !== undefined) {
      setIsApplyAllRestaurant(allValues.isApplyAllRestaurant);
    } else if (changedValues.isApplyAllPaymentMethod !== undefined) {
      setIsApplyAllPaymentMethod(allValues.isApplyAllPaymentMethod);
    }
  };

  const actions = [
    <Button key="back" onClick={onCancel}>
      Huỷ
    </Button>,
    <Button
      key="submit"
      type="primary"
      className="btn btn-primary"
      onClick={onOK}
      loading={isProcessing}
    >
      Lưu
    </Button>,
  ];
  if (voucher?.isMerchantCreated && !voucher.isVerify) {
    actions.splice(
      2,
      0,
      <Button
        key="verify"
        type="primary"
        onClick={onVerify}
        loading={isProcessing}
      >
        Duyệt
      </Button>,
      <Button key="reject" danger onClick={onReject} loading={isProcessing}>
        Từ chối
      </Button>
    );
  }

  return (
    <Modal
      title="Voucher khuyến mãi"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
      width={840}
      footer={actions}
    >
      <Form
        {...formItemLayoutHorizontal}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 19 },
        }}
        form={form}
        initialValues={initialValues}
        onValuesChange={onValueChange}
      >
        <Form.Item name="startDate" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="endDate" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="startTime" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="endTime" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name={"code"}
          label="Mã voucher"
          rules={[{ required: true, message: "Trường này là bắt buộc" }]}
        >
          {/* <Input placeholder="Địa chỉ cụ thể" /> */}
          <Search
            disabled={voucher}
            allowClear
            enterButton="Lấy ngẫu nhiên"
            loading={isGettingCode}
            onSearch={onGetCode}
            placeholder="Mã voucher"
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên voucher"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input placeholder="Tên voucher" />
        </Form.Item>
        <Form.Item
          name="content"
          label="Nội dung"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 2, maxRows: 5 }}
            placeholder="Nội dung chi tiết của voucher"
          />
        </Form.Item>
        <Form.Item
          name="date"
          label="Thời gian"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <RangePicker
            style={{ width: "100%" }}
            placeholder={["Thời gian bắt đầu", "Thời gian kết thúc"]}
            format="YYYY-MM-DD"
          />
        </Form.Item>
        <Form.Item
          name="time"
          label="Khung giờ áp dụng"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <TimePicker.RangePicker
            style={{ width: "100%" }}
            placeholder={["Từ", "Đến"]}
            format="HH:mm"
          />
        </Form.Item>
        <Form.Item name="owner" label="Chủ voucher" required>
          <Radio.Group>
            <Radio value="app">Voucher App</Radio>
            <Radio value="restaurant">Voucher Quán</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="restaurant"
          label="Quán chủ voucher"
          rules={[
            {
              required: owner !== "app",
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <DebounceSelect
            disabled={owner === "app"}
            mode="single"
            initOptions={
              voucher?.restaurant
                ? [
                    {
                      key: voucher.restaurant._id,
                      label: voucher.restaurant.name,
                      value: voucher.restaurant._id,
                    },
                  ]
                : []
            }
            placeholder="Chọn quán chủ voucher này"
            fetchOptions={commonApi.searchRestaurant}
            convertOptions={(data) =>
              data.map((res) => ({
                key: res._id,
                label: res.name,
                value: res._id,
              }))
            }
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item name="discountObj" label="Giảm vào" required>
          <Radio.Group>
            <Radio value="delivery-fee">Phí ship</Radio>
            <Radio value="order-value">Tiền món</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="discountValueType" label="Giá trị giảm" required>
          <Radio.Group>
            <Radio value="direct">Số tiền</Radio>
            <Radio value="percent">Phần trăm %</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="discountRestaurantPaidPercent"
          label="% Quán chịu"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input
            type={"number"}
            min={0}
            placeholder="% Quán chịu cho voucher này"
          />
        </Form.Item>
        <Form.Item
          name="discountAppPaidPercent"
          label="% App chịu"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input
            type={"number"}
            min={0}
            placeholder="% App chịu cho voucher này"
          />
        </Form.Item>
        <Form.Item label="Áp dụng cho" style={{ marginBottom: 0 }}>
          <Form.Item
            name="isFirstTimeOrder"
            valuePropName="checked"
            style={{
              display: "inline-block",
              marginRight: "20px",
            }}
          >
            <Switch
              checkedChildren="Đơn đầu tiên"
              unCheckedChildren="Đơn đầu tiên"
            />
          </Form.Item>
          <Form.Item
            name="isApplyAllUser"
            valuePropName="checked"
            style={{
              display: "inline-block",
              marginRight: "20px",
            }}
          >
            <Switch
              checkedChildren="Tất cả người dùng"
              unCheckedChildren="Tất cả người dùng"
            />
          </Form.Item>
          <Form.Item
            name="isApplyAllRestaurant"
            valuePropName="checked"
            style={{
              display: "inline-block",
              marginRight: "20px",
            }}
          >
            <Switch
              checkedChildren="Tất cả quán"
              unCheckedChildren="Tất cả quán"
            />
          </Form.Item>
          <Form.Item
            name="isApplyAllPaymentMethod"
            valuePropName="checked"
            style={{
              display: "inline-block",
              marginRight: "20px",
            }}
          >
            <Switch
              checkedChildren="Tất cả phương thức thanh toán"
              unCheckedChildren="Tất cả phương thức thanh toán"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="users"
          label="DS user áp dụng"
          // rules={[
          //     {
          //         required: isApplyAllUser,
          //         message: "Trường này là bắt buộc",
          //     },
          // ]}
        >
          {/* <TextArea
                        disabled={isApplyAllUser}
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        placeholder="Danh sách ID người nhận cách nhau dấu ,"
                    /> */}
          <DebounceSelect
            mode="multiple"
            disabled={isApplyAllUser}
            placeholder="Chọn danh sách người dùng áp dụng"
            initOptions={
              voucher?.users
                ? voucher.users.map((e) => ({
                    key: e._id,
                    label: `${e.name}-${e._id}`,
                    value: e._id,
                  }))
                : []
            }
            fetchOptions={commonApi.searchUser}
            convertOptions={(data) =>
              data.map((user) => ({
                key: user._id,
                label: `${user.name}-${user._id}`,
                value: user._id,
              }))
            }
            // onChange={(newValue) => {
            //     console.log(newValue);
            //     setValue(newValue);
            // }}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="restaurants"
          label="DS quán áp dụng"
          // rules={[
          //     {
          //         required: isApplyAllRestaurant,
          //         message: "Trường này là bắt buộc",
          //     },
          // ]}
        >
          <DebounceSelect
            mode="multiple"
            disabled={isApplyAllRestaurant}
            placeholder="Chọn danh sách quán áp dụng"
            initOptions={
              voucher?.restaurants
                ? voucher.restaurants.map((e) => ({
                    key: e._id,
                    label: e.name,
                    value: e._id,
                  }))
                : []
            }
            fetchOptions={commonApi.searchRestaurant}
            convertOptions={(data) =>
              data.map((res) => ({
                key: res._id,
                label: res.name,
                value: res._id,
              }))
            }
            // onChange={(newValue) => {
            //     console.log(newValue);
            //     setValue(newValue);
            // }}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item name="paymentMethods" label="DS PTTT áp dụng">
          <Checkbox.Group
            disabled={isApplyAllPaymentMethod}
            options={PAYMENT_METHODS}
          />
        </Form.Item>
        <Form.Item
          name="value"
          label="Giá trị giảm"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input type={"number"} min={0} placeholder="Giá trị giảm" />
        </Form.Item>
        <Form.Item name="maxDiscount" label="Giá trị giảm tối đa">
          <Input
            type={"number"}
            min={0}
            placeholder="Bỏ trống nếu không giới hạn"
          />
        </Form.Item>
        <Form.Item name="minOrderValue" label="GT đơn hàng tối thiểu">
          <Input
            type={"number"}
            min={0}
            placeholder="Giá trị đơn hàng tối thiểu"
          />
        </Form.Item>
        <Form.Item name="perUserActiveTimes" label="Số lượt tối đa/người dùng">
          <Input
            type={"number"}
            min={0}
            placeholder="Bỏ trống nếu không giới hạn"
          />
        </Form.Item>
        <Form.Item name="count" label="Tổng số lượt tối đa">
          <Input
            type={"number"}
            min={0}
            placeholder="Bỏ trống nếu không giới hạn"
          />
        </Form.Item>
        <Row>
          <Col xs={0} sm={5}></Col>
          <Col xs={24} sm={19}>
            <Form.Item shouldUpdate={shouldUpdatePropertyFormItem}>
              {() => (
                <Space size="large" wrap>
                  {properties.map((property) => {
                    const isTrue = form.getFieldValue(property.name);
                    return (
                      <Form.Item
                        name={property.name}
                        key={property.name}
                        valuePropName="checked"
                      >
                        <Switch
                          defaultChecked={isTrue}
                          checkedChildren={property.label}
                          unCheckedChildren={property.label}
                        />
                      </Form.Item>
                    );
                  })}
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

VoucherDetailModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  voucher: PropTypes.object,
};

VoucherDetailModal.defaultProps = {
  isProcessing: false,
  open: false,
  voucher: null,
};

export default VoucherDetailModal;
