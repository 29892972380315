import {
  FireOutlined,
  PictureOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import AdDetailModal from "./components/DetailModal";
import * as adActions from "./slice";

function Ad() {
  const dispatch = useDispatch();
  const { ads, pagination, isLoading, isProcessing, isCompleted } = useSelector(
    (state) => state.ad
  );

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingAd, setSelectingAd] = useState(null);
  const [searchRestaurant, setSearchRestaurant] = useState("");

  useEffect(() => {
    dispatch(adActions.fetchAds(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingAd(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (ad = null) => {
    setSelectingAd(ad);
    setOpenDetailModal(true);
  };

  const onDelete = (ad) => {
    Modal.confirm({
      title: `Xác nhận xoá quảng cáo`,
      content: `Bạn có chắc chắn muốn xoá quảng cáo này?`,
      onOk: () => dispatch(adActions.deleteAd(ad._id)),
      centered: true,
    });
  };

  const onSubmit = (ad, formData) => {
    if (ad._id) {
      dispatch(adActions.updateAd(ad, formData));
    } else {
      dispatch(adActions.createAd(formData));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingAd(null);
    dispatch(adActions.setIsProcessing(false));
  };

  const onChangeRestaurantSearch = (e) => {
    setSearchRestaurant(e.target.value);
  };

  const onSearch = (value) => {
    setSearchRestaurant(value);
    dispatch(
      adActions.fetchAds({
        ...pagination,
        restaurant: value,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      adActions.fetchAds({
        ...pagination,
        restaurant: searchRestaurant,
      })
    );
  };
  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Hình ảnh",
      dataIndex: "banner",
      width: "170px",
      render: (image) =>
        image ? (
          <Image
            alt="Image of ad"
            src={getPhotoUrl(image?.path || null)}
            width={166}
            height={66}
          />
        ) : null,
    },
    {
      title: "Quán",
      dataIndex: "restaurant",
      render: (restaurant) => restaurant.name,
    },
    {
      title: "Loại hình",
      width: 300,
      render: (record) => (
        <>
          <Typography.Text style={{ display: "block", marginBottom: "4px" }}>
            {record.adType?.name || "Không xác định"}
          </Typography.Text>
          {record.type === "banner" ? (
            <Tag icon={<PictureOutlined />} color="magenta" bordered={false}>
              Banner
            </Tag>
          ) : record.type === "list" ? (
            <Tag icon={<UnorderedListOutlined />} color="gold" bordered={false}>
              List
            </Tag>
          ) : (
            <Tag icon={<FireOutlined />} color="cyan" bordered={false}>
              Khác
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Thống kê",
      width: 150,
      render: (record) => (
        <>
          <Typography.Text>{record.showCount} hiển thị</Typography.Text>
          <br />
          <Typography.Text>{record.clickCount} lượt click</Typography.Text>
        </>
      ),
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      width: 140,
      render: (startDate) => dayjs(startDate).format("DD/MM/YYYY"),
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      width: 140,
      render: (endDate) => dayjs(endDate).format("DD/MM/YYYY"),
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      width: 80,
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: 120,
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onDelete={onDelete}
        />
      ),
    },
  ];
  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Quảng cáo
        </Typography.Title>
        <Space size="middle">
          <Input.Search
            placeholder="Tìm theo ID quán"
            onChange={onChangeRestaurantSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onOpenDetailModal()}
          >
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={ads}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <AdDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          ad={selectingAd}
        />
      )}
    </>
  );
}

export default Ad;
