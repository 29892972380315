import { Form, Input, InputNumber, Modal, Select, Space } from "antd";
import { useState } from "react";
import orderApi from "../api";
import { toast } from "react-toastify";
import { currencyToPrice, priceToCurrency } from "utils/common";

export default function FastEditModal({ open, onClose, order, onRefresh }) {
  const [exp, setExp] = useState("-");
  const [value, setValue] = useState(0);
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (order, { exp, value, reason }) => {
    setIsLoading(true);
    try {
      // Call API here
      console.log(order, exp, value, reason);
      const amount = exp === "+" ? value : -value;
      const data = await orderApi.fastEdit(order._id, { amount, reason });
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        toast.success("Chỉnh sửa chênh lệch đơn hàng thành công");
        onClose();
        onRefresh();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={`Sửa chênh lệch đơn hàng #${order.code}`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(order, { exp, value, reason })}
      onCancel={onClose}
      confirmLoading={isLoading}
    >
      <Form layout="vertical">
        <Form.Item label="Số tiền chênh lệch">
          <Space.Compact>
            <Select
              value={exp}
              options={[
                { value: "+", label: "+" },
                { value: "-", label: "-" },
              ]}
              onChange={(e) => setExp(e)}
              style={{ width: 60 }}
            />
            <InputNumber
              min={0}
              value={value}
              onChange={(e) => setValue(e)}
              style={{ width: 120 }}
              formatter={(value) => priceToCurrency(value)}
              parser={(value) => currencyToPrice(value)}
            />
          </Space.Compact>
        </Form.Item>
        <Form.Item label="Lý do sửa">
          <Input.TextArea
            placeholder="Thêm topping..."
            onChange={(e) => setReason(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
