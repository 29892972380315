import { Layout } from "antd";
import * as authActions from "features/auth/slice";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import DashboardSider from "./DashboardSider";
const { Content, Footer } = Layout;

export default function DashboardLayout() {
  const { isLoggedIn, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(authActions.checkTokenAndLogin());
  }, [location.pathname]);

  if (!isLoggedIn || !userInfo) {
    return <></>;
  }
  return (
    <>
      <Layout hasSider>
        <DashboardSider />
        <Layout>
          <DashboardHeader />
          <Content className="p-6">
            <div className="bg-white p-6">
              <Outlet />
            </div>
          </Content>
          <Footer className="!bg-white flex justify-between	items-center">
            <span>Copyright by Goka © 2023</span>
            {/* <span>
              Developed by{" "}
              <a href="https://hoaidev.com" target={"_blank"} rel="noreferrer">
                Hoai Phan.Inc
              </a>
            </span> */}
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
