import {
  CloseOutlined,
  CommentOutlined,
  DiffOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { ACCOUNT_ROLES } from "utils/constants";

export default function Actions({
  record,
  onViewDetail,
  onViewCost,
  onViewReject,
  onViewEditHistory,
  onEdit,
  onFastEdit,
  onCancel,
  onViewChat,
}) {
  const { userInfo } = useSelector((state) => state.auth);

  const KEY_ACTIONS = {
    VIEW_ORDER: "view_order",
    VIEW_COST: "view_cost",
    VIEW_REJECT: "view_reject",
    VIEW_CHAT: "view_chat",
    VIEW_EDIT_HISTORY: "view_edit_history",
    EDIT: "edit",
    FAST_EDIT: "fast_edit",
    CANCEL: "cancel",
  };

  const onClick = ({ key }) => {
    switch (key) {
      case KEY_ACTIONS.VIEW_ORDER:
        onViewDetail(record);
        break;
      case KEY_ACTIONS.VIEW_COST:
        onViewCost(record);
        break;
      case KEY_ACTIONS.VIEW_REJECT:
        onViewReject(record);
        break;
      case KEY_ACTIONS.VIEW_CHAT:
        onViewChat(record);
        break;
      case KEY_ACTIONS.VIEW_EDIT_HISTORY:
        onViewEditHistory(record);
        break;
      case KEY_ACTIONS.EDIT:
        onEdit(record);
        break;
      case KEY_ACTIONS.FAST_EDIT:
        onFastEdit(record);
        break;
      case KEY_ACTIONS.CANCEL:
        onCancel(record);
        break;
      default:
        break;
    }
  };

  const actions = {
    super_admin: [
      {
        key: KEY_ACTIONS.VIEW_ORDER,
        label: "Xem chi tiết đơn",
        icon: <EyeOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_COST,
        label: "Xem chi tiết phí",
        icon: <DollarOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_CHAT,
        label: "Xem lịch sử chat",
        icon: <CommentOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_REJECT,
        label: "DS tài xế bỏ qua",
        icon: <UserDeleteOutlined />,
      },
      {
        key: KEY_ACTIONS.FAST_EDIT,
        label: "Sửa chênh lệch",
        icon: <EditOutlined />,
        disabled: !record.canEdit,
      },
      {
        key: KEY_ACTIONS.EDIT,
        label: "Sửa đơn hàng",
        icon: <FormOutlined />,
        disabled: record.status === "wait-pay",
      },
      {
        key: KEY_ACTIONS.CANCEL,
        disabled: ![ACCOUNT_ROLES.SUPER_ADMIN, ACCOUNT_ROLES.ADMIN].includes(
          userInfo.role
        ),
        danger: true,
        label: "Hủy đơn",
        icon: <CloseOutlined />,
      },
    ],
  };

  if (record.editedCount) {
    actions.super_admin.splice(6, 0, {
      key: KEY_ACTIONS.VIEW_EDIT_HISTORY,
      label: "Xem lịch sử sửa",
      icon: <DiffOutlined />,
    });
  }

  actions.admin = actions.super_admin;

  return (
    <Dropdown menu={{ items: actions["super_admin"] || [], onClick }}>
      <Button>
        <Space>
          Thao tác
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
