import {
  DownOutlined,
  EyeOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({ record, onView, onBlock, onActivate }) {
  const KEY_ACTIONS = {
    VIEW: "view",
    BLOCK: "block",
    ACTIVATE: "activate",
  };

  const onClick = ({ key }) => {
    switch (key) {
      case KEY_ACTIONS.VIEW:
        onView(record);
        break;
      case KEY_ACTIONS.BLOCK:
        onBlock(record);
        break;
      case KEY_ACTIONS.ACTIVATE:
        onActivate(record);
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      key: KEY_ACTIONS.VIEW,
      label: "Xem chi tiết",
      icon: <EyeOutlined />,
    },
    {
      key: KEY_ACTIONS.BLOCK,
      label: `${record.blocked ? "Bỏ chặn" : "Chặn"} người dùng`,
      icon: record.blocked ? <UnlockOutlined /> : <LockOutlined />,
      danger: !record.blocked,
    },
    {
      key: KEY_ACTIONS.ACTIVATE,
      label: `${record.active ? "Hủy kích hoạt" : "Kích hoạt"} tài khoản`,
    },
  ];

  return (
    <Dropdown menu={{ items: actions, onClick }}>
      <Button>
        <Space>
          Thao tác
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
