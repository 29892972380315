import axiosClient from "utils/api/axios-client";

const configApi = {
  getConfig: () => {
    const url = "/api/v1/config";
    return axiosClient.get(url);
  },

  update: (config) => {
    const url = `/api/v1/config`;
    return axiosClient.put(url, config);
  },
};

export default configApi;
