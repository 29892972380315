import axiosClient from "utils/api/axios-client";

const accountApi = {
  getAll: (params) => {
    const url = "/api/v1/super-accounts";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/super-accounts/${id}`;
    return axiosClient.get(url);
  },
  create: (account) => {
    const url = `/api/v1/super-accounts/`;
    return axiosClient.post(url, account);
  },
  update: (account) => {
    const url = `/api/v1/super-accounts/${account._id}`;
    return axiosClient.put(url, account);
  },
  resetPassword: (id) => {
    const url = `/api/v1/super-accounts/${id}/reset-password`;
    return axiosClient.patch(url);
  },
  delete: (id) => {
    const url = `/api/v1/super-accounts/${id}`;
    return axiosClient.delete(url);
  },
};

export default accountApi;
