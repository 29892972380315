import axiosClient from "utils/api/axios-client";

const orderApi = {
  getAll: (params) => {
    const url = "/api/v1/new-orders";
    return axiosClient.get(url, {
      params,
    });
  },
  getDetail: (id) => {
    const url = `/api/v1/orders/${id}/detail`;
    return axiosClient.get(url);
  },
  getCost: (id) => {
    const url = `/api/v1/orders/${id}/cost`;
    return axiosClient.get(url);
  },
  getRejects: (id) => {
    const url = `/api/v1/orders/${id}/reject`;
    return axiosClient.get(url);
  },
  assignShipper: (id, shipper) => {
    const url = `/api/v1/orders/${id}/assign`;
    return axiosClient.post(url, {
      shipper,
    });
  },
  transferShipper: (id, shipper) => {
    const url = `/api/v1/orders/${id}/transfer`;
    return axiosClient.post(url, {
      shipper,
    });
  },
  cancel: (id, reason) => {
    const url = `/api/v1/orders/${id}/cancel`;
    return axiosClient.delete(url, {
      data: {
        reason,
      },
    });
  },
  getMessages: (id) => {
    const url = `/api/v1/orders/${id}/chats`;
    return axiosClient.get(url);
  },
};

export default orderApi;
