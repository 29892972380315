import axiosClient from "utils/api/axios-client";

const api = {
  getMapService: () => {
    const url = "/api/v1/super-config/map-service";
    return axiosClient.get(url);
  },
  updateMapService: (data) => {
    const url = "/api/v1/super-config/map-service";
    return axiosClient.put(url, data);
  },
  getMapKeyLimit: () => {
    const url = "/api/v1/super-config/map-key-limit";
    return axiosClient.get(url);
  },
  updateMapKeyLimit: (data) => {
    const url = "/api/v1/super-config/map-key-limit";
    return axiosClient.put(url, data);
  },
  getMapKeys: () => {
    const url = "/api/v1/super-config/map-keys";
    return axiosClient.get(url);
  },
  createMapKey: (key) => {
    const url = "/api/v1/super-config/map-keys";
    return axiosClient.post(url, key);
  },
  updateMapKey: (key) => {
    const url = `/api/v1/super-config/map-keys/${key._id}`;
    return axiosClient.put(url, key);
  },
  deleteMapKey: (id) => {
    const url = `/api/v1/super-config/map-keys/${id}`;
    return axiosClient.delete(url);
  },
};

export default api;
