import { Collapse, Modal, Typography } from "antd";
import { useState } from "react";
import DishRow from "./DishRow";
import NewDishDisplay from "./NewDishDisplay";

const { Panel } = Collapse;

const calculateTotalCost = (dish) => {
  return (
    dish.price * dish.quantity +
    dish.customizes.reduce((sum, option) => {
      const totalCost = option.options.reduce((sumChildOption, childOption) => {
        sumChildOption += childOption.price * (childOption.quantity || 0);
        return sumChildOption;
      }, 0);
      sum += totalCost;
      return sum;
    }, 0)
  );
};

export default function DishesModal({
  menus,
  open,
  onClose,
  onConfirm,
  selectedDishes,
}) {
  const [newDishes, setNewDishes] = useState([...selectedDishes]);

  const onSelectNewDish = (dish) => {
    // console.log(dish);
    setNewDishes([
      ...newDishes,
      {
        ...dish,
        totalCost: calculateTotalCost(dish),
      },
    ]);
  };

  const onSaveEditDish = (dish) => {
    setNewDishes(
      newDishes.map((_dish) => {
        if (_dish._id === dish._id) {
          return {
            ...dish,
            totalCost: calculateTotalCost(dish),
          };
        }
        return _dish;
      })
    );
  };

  const onRemoveDish = (removeDish) => {
    setNewDishes(newDishes.filter((dish) => dish._id !== removeDish._id));
  };

  return (
    <>
      <Modal
        open={open}
        onOk={() => onConfirm(newDishes)}
        onCancel={onClose}
        width={800}
      >
        <div style={{ maxHeight: "750px", overflowY: "auto" }}>
          <Typography.Title level={4}>Món đã chọn</Typography.Title>
          <table className="w-full" cellSpacing={0} cellPadding={10}>
            <thead>
              <tr>
                <th className="w-20"></th>
                <th>Món ăn</th>
                <th>Giá tiền</th>
              </tr>
            </thead>
            <tbody>
              {newDishes.map((dish) => {
                let dishInfo = null;
                for (let i = 0; i < menus.length; ++i) {
                  const menu = menus[i];
                  const dishExistInMenu = menu.dishes.find(
                    (_dish) => _dish._id === dish.dishId
                  );
                  if (dishExistInMenu) {
                    dishInfo = dishExistInMenu;
                    break;
                  }
                }
                return (
                  <NewDishDisplay
                    key={dish._id}
                    dish={dish}
                    dishInfo={dishInfo}
                    onRemoveDish={onRemoveDish}
                    onSaveDish={onSaveEditDish}
                  />
                );
              })}
            </tbody>
          </table>
          <Typography.Title level={4}>Thêm món</Typography.Title>
          <Collapse bordered={false} defaultActiveKey={[0]}>
            {menus.map((menu) => (
              <Panel header={menu.name} key={menu._id}>
                {/* <Typography.Title level={4}>{menu.name}</Typography.Title> */}
                <table className="w-full" cellSpacing={0} cellPadding={10}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Món ăn</th>
                      <th>Giá tiền</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menu.dishes.map((dish) => {
                      return (
                        <DishRow
                          key={dish._id}
                          dish={dish}
                          onConfirmSelect={onSelectNewDish}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </Panel>
            ))}
          </Collapse>
        </div>
      </Modal>
    </>
  );
}
