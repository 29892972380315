import { createSlice } from "@reduxjs/toolkit";
import regionApi from "./api";
import { toast } from "react-toastify";

const initialState = {
  regions: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setRegions: (state, action) => {
      state.regions = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    addRegion: (state, action) => {
      state.isCompleted = true;
      state.regions.unshift(action.payload);
    },
    editRegion: (state, action) => {
      state.isCompleted = true;
      state.regions = state.regions.map((region) =>
        region._id === action.payload._id ? action.payload : region
      );
    },
    removeRegion: (state, action) => {
      state.isCompleted = true;
      state.regions = state.regions.filter(
        (region) => region._id !== action.payload
      );
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setRegions,
  setPagination,
  addRegion,
  editRegion,
  removeRegion,
} = regionSlice.actions;

export const fetchRegions = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await regionApi.getAll(params);
      dispatch(setRegions(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const createRegion = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await regionApi.create(formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addRegion(data));
        toast.success("Thêm phân cụm mới thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateRegion = (region) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await regionApi.update(region);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editRegion(data));
        toast.success("Cập nhật phân cụm thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const deleteRegion = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await regionApi.delete(id);
      if (data.success) {
        dispatch(removeRegion(id));
        toast.success("Xóa phân cụm thành công!");
      } else {
        toast.error("Lỗi hệ thống, xóa phân cụm thất bại!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default regionSlice.reducer;
