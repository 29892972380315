import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import CategoryDetailModal from "./components/DetailModal";
import * as categoryActions from "./slice";

function Category() {
  const dispatch = useDispatch();
  const { categories, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.category);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingCategory, setSelectingCategory] = useState(null);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(categoryActions.fetchCategories(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingCategory(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (category = null) => {
    setSelectingCategory(category);
    setOpenDetailModal(true);
  };

  const onDelete = (category) => {
    Modal.confirm({
      title: `Xác nhận xoá danh mục`,
      content: `Bạn có chắc chắn muốn xoá danh mục này?`,
      onOk: () => dispatch(categoryActions.deleteCategory(category._id)),
      centered: true,
    });
  };

  const onSubmit = (category, formData) => {
    if (category._id) {
      dispatch(categoryActions.updateCategory(category, formData));
    } else {
      dispatch(categoryActions.createCategory(formData));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingCategory(null);
    dispatch(categoryActions.setIsProcessing(false));
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = () => {
    dispatch(
      categoryActions.fetchCategories({
        ...pagination,
        name: searchName,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      categoryActions.fetchCategories({
        ...pagination,
        name: searchName,
      })
    );
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Hình ảnh",
      width: "10%",
      dataIndex: "image",
      render: (image) => (
        <Image
          alt="Image of food category"
          src={getPhotoUrl(image?.path || null)}
          width={80}
          height={80}
        />
      ),
    },
    {
      title: "Tên danh mục",
      dataIndex: "name",
      width: "20%",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Ưu tiên",
      dataIndex: "priority",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: "Hiển thị trên trang chủ",
      dataIndex: "inHome",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.inHome - b.inHome,
      render: (inHome) =>
        inHome ? (
          <span className="text-xl">
            <CheckOutlined />
          </span>
        ) : (
          <span className="text-slate-300 text-xl">
            <CloseOutlined />
          </span>
        ),
    },
    {
      title: "Cho phép tìm kiếm",
      dataIndex: "inFilter",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.inFilter - b.inFilter,
      render: (inFilter) =>
        inFilter ? (
          <span className="text-xl">
            <CheckOutlined />
          </span>
        ) : (
          <span className="text-slate-300 text-xl">
            <CloseOutlined />
          </span>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "15%",
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>Danh mục đồ ăn</Typography.Title>
        <Space size="middle">
          <Input.Search
            placeholder="Tìm theo tên"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onOpenDetailModal()}
          >
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={categories}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <CategoryDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          category={selectingCategory}
        />
      )}
    </>
  );
}

export default Category;
