import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import shipperApi from "./api";
import { set } from "lodash";

const showTotal = (total) => `Tổng: [${total}]`;

const initialState = {
  shippers: [],
  mapShippers: [],
  inQueueShippers: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const shipperSlice = createSlice({
  name: "shipper",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setShippers: (state, action) => {
      state.shippers = action.payload;
    },
    setMapShippers: (state, action) => {
      state.mapShippers = action.payload;
    },
    setInQueueShippers: (state, action) => {
      state.inQueueShippers = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = { ...action.payload };
    },
    addShipper: (state, action) => {
      state.isCompleted = true;
      state.shippers.unshift(action.payload);
    },
    editShipper: (state, action) => {
      state.isCompleted = true;
      state.shippers = state.shippers.map((shipper) =>
        shipper._id === action.payload._id ? action.payload : shipper
      );
    },
    editShipperGetFreePick: (state, action) => {
      state.shippers = state.shippers.map((shipper) =>
        shipper._id === action.payload._id
          ? { ...shipper, getFreePick: action.payload.getFreePick }
          : shipper
      );
    },
    removeShipper: (state, action) => {
      state.isCompleted = true;
      state.shippers = state.shippers.filter(
        (shipper) => shipper._id !== action.payload
      );
    },
    resetWeeklyPoint: (state) => {
      state.shippers = state.shippers.map((shipper) => ({
        ...shipper,
        weeklyPoint: 0,
      }));
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setShippers,
  setMapShippers,
  setInQueueShippers,
  setPagination,
  addShipper,
  editShipper,
  editShipperGetFreePick,
  removeShipper,
  resetWeeklyPoint,
} = shipperSlice.actions;

export const fetchMapShippers = (loading) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    if (loading) await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      const data = await shipperApi.getList();
      const shippersInQueue = data
        .filter((s) => s.inQueue)
        .sort((a, b) => a.queueIndex - b.queueIndex);
      console.log(shippersInQueue);
      dispatch(setInQueueShippers(shippersInQueue));
      dispatch(setMapShippers(data));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const fetchShippers = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await shipperApi.getAll(params);
      dispatch(setShippers(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const createShipper = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.create(formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addShipper(data));
        toast.success("Thêm tài xế mới thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateShipper = (shipper, formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.update(shipper._id, formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editShipper(data));
        toast.success("Cập nhật tài xế thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const deleteShipper = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.delete(id);
      if (data.success) {
        dispatch(removeShipper(id));
        toast.success("Xóa tài xế thành công!");
      } else {
        toast.error("Lỗi hệ thống, xóa tài xế thất bại!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateGetPreePickShipper = (id, getFreePick) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.updateGetFreePick(id, getFreePick);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editShipperGetFreePick({ _id: id, getFreePick }));
        toast.success("Cập nhật thành công!");
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const blockShipper = (shipper, blockData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.block(shipper._id, blockData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editShipper({ ...shipper, ...data.data }));
        toast.success("Cập nhật trạng thái khóa tài xế thành công!");
        dispatch(setIsCompleted(true));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const editWeeklyPointShipper = (shipper, editData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.updateWeeklyPoint(shipper._id, editData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editShipper({ ...shipper, ...data.data }));
        toast.success("Cập nhật điểm hiệu suất tài xế thành công!");
        dispatch(setIsCompleted(true));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const resetWeekyPointShipper = () => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await shipperApi.resetWeeklyPoint();
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(resetWeeklyPoint());
        toast.success("Đặt lại điểm hiệu suất tài xế thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default shipperSlice.reducer;
