import { Form, Input, Modal } from "antd";
import { useSelector } from "react-redux";
import { getSlug } from "utils/common";

export default function MenuDetailModal(props) {
  const [form] = Form.useForm();
  const { open, onSubmit, onCancel, restaurant, menu } = props;

  const { isProcessing } = useSelector((state) => state.restaurant);

  const initialValues = menu || {
    name: "",
    slug: "",
    priority: 99,
    restaurant: restaurant._id,
  };
  const onValuesChange = (changedValues) => {
    if (changedValues.name) {
      form.setFieldValue("slug", getSlug(changedValues.name));
    }
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const menu = { ...initialValues, ...values };
        onSubmit(menu);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Modal
      title="Danh mục đồ ăn"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="name"
          label="Tên thực đơn"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug" dependencies={["name"]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="priority" label="Thứ tự ưu tiên">
          <Input type={"number"} min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
