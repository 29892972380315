import { Avatar, Button, Image, Row, Space, Tooltip } from "antd";

import { SyncOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as shipperActions from "../slice";
import List from "./components/List";
import Map from "./components/Map";
import { getPhotoUrl } from "utils/common";

function ShipperMap() {
  const dispatch = useDispatch();
  const {
    mapShippers: shippers,
    inQueueShippers,
    isLoading,
  } = useSelector((state) => state.shipper);
  const [selectedShipper, setSelectedShipper] = useState(null);

  useEffect(() => {
    dispatch(shipperActions.fetchMapShippers());
  }, []);

  const handleRefresh = async () => {
    setSelectedShipper(null);
    dispatch(shipperActions.fetchMapShippers(true));
  };

  const onShippers = shippers?.filter((s) => s.isReady).length || 0;
  const totalProcessingOrders =
    shippers?.reduce(
      (total, currentValue) =>
        total + (currentValue.processingOrders?.length || 0),
      0
    ) || 0;
  const runShippers =
    shippers?.filter((s) => s.isReady && s.processingOrders?.length).length ||
    0;
  const waitShippers =
    shippers?.filter(
      (s) => s.isReady && s.location && !s.processingOrders?.length
    ).length || 0;
  // const offShippers = shippers?.filter(s => !s.isReady).length || 0;
  const doneOrders = shippers.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.orderCountToday;
  }, 0);

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ padding: "0 10px 10px" }}
      >
        <h2 style={{ margin: "0 0 6px 0", fontSize: "24px" }}>
          Tracking tài xế | {onShippers} Online | {runShippers} Đang giao{" "}
          {totalProcessingOrders} đơn | {waitShippers} Chờ đơn | {doneOrders}{" "}
          Đơn hoàn thành
        </h2>
        <Button type="primary" icon={<SyncOutlined />} onClick={handleRefresh}>
          Cập nhật
        </Button>
      </Row>
      <Row align="middle" style={{ padding: "0 10px 10px" }}>
        <Space size={"middle"}>
          <h2 style={{ margin: "0 0 6px 0", fontSize: "24px" }}>Hàng chờ: </h2>
          {inQueueShippers.map((shipper) => (
            <Tooltip key={shipper._id} title={shipper.name}>
              {shipper.avatar ? (
                <Image
                  alt={shipper.name}
                  src={getPhotoUrl(shipper.avatar?.path || null)}
                  width="40px"
                  height="40px"
                  rootClassName="rounded-full overflow-hidden"
                />
              ) : (
                <Avatar
                  className="select-none"
                  alt={shipper.name}
                  size={40}
                  style={{ backgroundColor: "#FFD22F" }}
                >
                  {shipper.name[0].toUpperCase()}
                </Avatar>
              )}
            </Tooltip>
          ))}
        </Space>
      </Row>

      <div className="shipper-map">
        {isLoading ? (
          <div className="shipper-loading">
            <h1>
              <SyncOutlined spin />
            </h1>
          </div>
        ) : null}
        <List
          shippers={shippers}
          selectedShipper={selectedShipper}
          setSelectedShipper={setSelectedShipper}
        />
        <Map
          shippers={shippers}
          selectedShipper={selectedShipper}
          setSelectedShipper={setSelectedShipper}
        />
      </div>
    </>
  );
}

export default ShipperMap;
