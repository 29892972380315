import configApi from "./api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialConfig = {
  config: {},
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

const config = createSlice({
  name: "configs",
  initialState: initialConfig,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      state.isProcessing = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
  },
});

const { reducer, actions } = config;

export const { setIsLoading, setIsProcessing, setConfig } = actions;

export const fetchConfig = () => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await configApi.getConfig();
      dispatch(setConfig(data.data));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateConfig = (config) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await configApi.update(config);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(setConfig(data.data));
        toast.success("Thiết lập cấu hình thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};

export default reducer;
