import { Button, Form, Image, Input, Modal, Upload } from "antd";
import { getPhotoUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

export default function UpdateInfoModal({
  open,
  onClose,
  onVerify,
  onReject,
  isProcessing,
  restaurant,
}) {
  const updateInfo = restaurant?.updateInfo || {};

  return (
    <>
      <Modal
        title={`Duyệt thông tin quán [${restaurant.name}]`}
        okText="Lưu"
        cancelText="Huỷ"
        centered
        open={open}
        onCancel={onClose}
        confirmLoading={isProcessing}
        width={600}
        footer={[
          <Button
            key="verify"
            type="primary"
            loading={isProcessing}
            onClick={onVerify}
          >
            Duyệt thay đổi
          </Button>,
          <Button key="reject" danger loading={isProcessing} onClick={onReject}>
            Từ chối
          </Button>,
          <Button key="close" onClick={onClose}>
            Huỷ
          </Button>,
        ]}
      >
        <Form {...formItemLayoutHorizontal}>
          <Form.Item label="Tên mới">
            <Input value={updateInfo.name} readOnly />
          </Form.Item>
          <Form.Item label="Slug">
            <Input value={updateInfo.slug} readOnly disabled />
          </Form.Item>
          <Form.Item label="SĐT">
            <Input value={updateInfo.phone} readOnly />
          </Form.Item>
          {updateInfo.avatar && (
            <Form.Item label="Avatar">
              {/* <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                disabled={true}
              >
                <img
                  src={getPhotoUrl(updateInfo.avatar?.path || null)}
                  alt="image"
                  style={{ width: "100%" }}
                />
              </Upload> */}
              <Image
                width={100}
                height={100}
                src={getPhotoUrl(updateInfo.avatar?.path || null)}
              />
            </Form.Item>
          )}
          {updateInfo.cover && (
            <Form.Item label="Cover">
              <Image
                width={200}
                height={102}
                src={getPhotoUrl(updateInfo.cover?.path || null)}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}
