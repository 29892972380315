import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Typography } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import { classNames } from "utils/common";
import * as restaurantActions from "../../slice";
const { Paragraph } = Typography;

export default function TabAccount({ form, restaurant, onCancel }) {
  const [generatePasswordLoading, setGeneratePasswordLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isProcessing } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  const onGeneratePassword = async () => {
    try {
      setGeneratePasswordLoading(true);
      const data = await commonApi.generatePassword();
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        toast.success("Tạo mật khẩu thành công");
        form.setFieldValue(["account", "password"], data.password);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setGeneratePasswordLoading(false);
    }
  };

  const onSubmit = () => {
    const accountData = form.getFieldValue("account");
    console.log(accountData);
    dispatch(
      restaurantActions.updateRestaurantAccount(restaurant, accountData)
    );
  };

  return (
    <>
      <Form.Item name={["account", "name"]} label="Họ tên">
        <Input />
      </Form.Item>
      <Form.Item name={["account", "phone"]} label="Số điện thoại">
        <Input />
      </Form.Item>
      <Form.Item name={["account", "email"]} label="Email">
        <Input />
      </Form.Item>
      <Form.Item name={["account", "active"]} label="Trạng thái">
        <Select>
          <Select.Option value={true}>Active</Select.Option>
          <Select.Option value={false}>In-active</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={["account", "password"]} label="Mật khẩu">
        <Input
          // disabled={Boolean(restaurant)}
          type={showPassword ? "text" : "password"}
          addonBefore={
            <div
              className={"select-none cursor-pointer flex items-center gap-x-2"}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </div>
          }
          addonAfter={
            generatePasswordLoading ? (
              <LoadingOutlined spin />
            ) : (
              <div
                className={classNames(
                  "select-none",
                  restaurant ? "cursor-pointer" : "cursor-pointer"
                )}
                onClick={onGeneratePassword}
              >
                Tạo mật khẩu
              </div>
            )
          }
        />
      </Form.Item>
      <Form.Item name={["account", "balance"]} label="Số dư ví">
        <Input readOnly />
      </Form.Item>
      <Form.Item name={["account", "telegramId"]} label="Telegram ID">
        <Input />
      </Form.Item>
      <Form.Item name={["account", "withdrawCode"]} label="Mã nạp tiền">
        <Input readOnly />
      </Form.Item>
      {restaurant?.merchant ? (
        <Form.Item name="appToken" label="FCM Token">
          {restaurant?.merchant.appTokens?.map((token) => (
            <Paragraph copyable key={token}>
              {token}
            </Paragraph>
          )) || null}
        </Form.Item>
      ) : null}
      {restaurant && (
        <>
          <Button
            className="float-right"
            type="primary"
            onClick={onSubmit}
            loading={isProcessing}
          >
            Lưu thay đổi tài khoản chủ quán
          </Button>
          <Button
            className="float-right mr-4"
            onClick={onCancel}
            disabled={isProcessing}
          >
            Hủy
          </Button>
        </>
      )}
    </>
  );
}
