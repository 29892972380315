import { Button, Col, Divider, InputNumber, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "./api";
import MapKeys from "./keys";

const { Option } = Select;

export default function MapService() {
  const [mapService, setMapService] = useState("GOONG");
  const [isLoadingMapService, setIsLoadingMapService] = useState(true);
  const [isProcessingMapService, setIsProcessingMapService] = useState(false);
  const [mapKeyLimit, setMapKeyLimit] = useState(0);
  const [isLoadingMapKeyLimit, setIsLoadingMapKeyLimit] = useState(true);
  const [isProcessingMapKeyLimit, setIsProcessingMapKeyLimit] = useState(false);

  useEffect(() => {
    const getMapService = async () => {
      try {
        setIsLoadingMapService(true);
        const data = await api.getMapService();
        setMapService(data.data);
      } catch (error) {
        console.error(error);
        toast.error("Lỗi khi lấy dữ liệu map service");
      } finally {
        setIsLoadingMapService(false);
      }
    };
    const getMapKeyLimit = async () => {
      try {
        setIsLoadingMapKeyLimit(true);
        const data = await api.getMapKeyLimit();
        setMapKeyLimit(data.data);
      } catch (error) {
        console.error(error);
        toast.error("Lỗi khi lấy dữ liệu map key limit");
      } finally {
        setIsLoadingMapKeyLimit(false);
      }
    };
    getMapService();
    getMapKeyLimit();
  }, []);

  const handleChangeMapService = (value) => {
    setMapService(value);
  };

  const handleChangeMapLimitKey = (value) => {
    setMapKeyLimit(value);
  };

  const onSaveMapService = async () => {
    setIsProcessingMapService(true);
    try {
      await api.updateMapService({ mapService });
      toast.success("Cập nhật thành công");
    } catch (error) {
      console.error(error);
      toast.error("Lỗi khi cập nhật map service");
    } finally {
      setIsProcessingMapService(false);
    }
  };

  const onSaveMapKeyLimit = async () => {
    setIsProcessingMapKeyLimit(true);
    try {
      await api.updateMapKeyLimit({ mapKeyLimit });
      toast.success("Cập nhật thành công");
    } catch (error) {
      console.error(error);
      toast.error("Lỗi khi cập nhật map key limit");
    } finally {
      setIsProcessingMapKeyLimit(false);
    }
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Divider orientation="left">Map Service</Divider>
          {isLoadingMapService ? (
            <div
              style={{
                margin: "20px 0",
                padding: "30px 50px",
                textAlign: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              <Select
                value={mapService}
                onChange={handleChangeMapService}
                style={{ width: 200 }}
              >
                <Option value="GOONG">GOONG MAP</Option>
                <Option value="GOOGLE">GOOGLE MAP</Option>
                <Option value="MAPCIR">MAPCIR</Option>
                <Option value="HMAP">HMAP</Option>
              </Select>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 10 }}
                loading={isProcessingMapService}
                onClick={onSaveMapService}
              >
                Cập nhật
              </Button>
            </>
          )}
        </Col>
        <Col span={12}>
          <Divider orientation="left">Lượt request tối đa Google Map</Divider>
          {isLoadingMapKeyLimit ? (
            <div
              style={{
                margin: "20px 0",
                padding: "30px 50px",
                textAlign: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              <InputNumber
                min={0}
                value={mapKeyLimit}
                style={{ width: 200 }}
                onChange={handleChangeMapLimitKey}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 10 }}
                loading={isProcessingMapKeyLimit}
                onClick={onSaveMapKeyLimit}
              >
                Cập nhật
              </Button>
            </>
          )}
        </Col>
      </Row>
      <br />
      <br />
      <Divider orientation="left">Google Map Keys</Divider>
      <MapKeys />
    </>
  );
}
