import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./components/Actions";
import KeyDetailModal from "./components/DetailModal";
import * as mapServiceActions from "./slice";

export default function MapKeys() {
  const dispatch = useDispatch();
  const { keys, isLoading, isProcessing, isCompleted } = useSelector(
    (state) => state.mapService
  );
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingKey, setSelectingKey] = useState(null);

  useEffect(() => {
    dispatch(mapServiceActions.fetchKeys());
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingKey(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (account = null) => {
    setSelectingKey(account);
    setOpenDetailModal(true);
  };

  const onSubmit = (key) => {
    if (key._id) {
      dispatch(mapServiceActions.updateKey(key));
    } else {
      dispatch(mapServiceActions.createKey(key));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingKey(null);
    dispatch(mapServiceActions.setIsProcessing(false));
  };

  const onReset = (key) => {
    Modal.confirm({
      title: `Xác nhận reset lượt request key`,
      content: `Bạn có chắc chắn muốn reset lượt request key này về = 0?`,
      onOk: () =>
        dispatch(mapServiceActions.updateKey({ ...key, reset: true })),
      centered: true,
    });
  };

  const onDelete = (key) => {
    Modal.confirm({
      title: `Xác nhận xoá key`,
      content: `Bạn có chắc chắn muốn xoá key này?`,
      onOk: () => dispatch(mapServiceActions.deleteKey(key._id)),
      centered: true,
    });
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "30%",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      width: "20%",
    },
    {
      title: "Lượt request",
      dataIndex: "requestCount",
      width: "10%",
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      width: "10%",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "10%",
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onReset={onReset}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <div>
      <Row justify="end" align="middle">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => onOpenDetailModal()}
        >
          Thêm mới
        </Button>
      </Row>
      <br />
      <Table
        dataSource={keys}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
      {openDetailModal && (
        <KeyDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          account={selectingKey}
        />
      )}
    </div>
  );
}
