import { Form, Input, Modal, Typography } from "antd";
import { useState } from "react";

export default function BlockUserModal(props) {
  const { open, isProcessing, onSubmit, onCancel, user } = props;
  const [blockedReason, setBlockedReason] = useState("");
  const isUserBlocked = user.blocked;

  const onChangeBlockedReason = (e) => {
    setBlockedReason(e.target.value);
  };

  return (
    <Modal
      title={`Xác nhận ${isUserBlocked ? "bỏ chặn" : "chặn"} người dùng`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(user, blockedReason)}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Typography.Paragraph>
        Bạn có chắc chắn muốn {isUserBlocked ? "bỏ chặn" : "chặn"} người dùng
        này?
      </Typography.Paragraph>
      {!isUserBlocked && (
        <Form layout="vertical">
          <Form.Item label="Lý do chặn">
            <Input.TextArea
              onChange={onChangeBlockedReason}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
