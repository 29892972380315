import { Form, Input, InputNumber, Modal, Select, Space } from "antd";
import { useState } from "react";

export default function EditWeelyPointModal({
  open,
  onSubmit,
  onClose,
  shipper,
  isProcessing,
}) {
  const [exp, setExp] = useState("-");
  const [value, setValue] = useState(0);
  const [reason, setReason] = useState("");

  return (
    <>
      <Modal
        title={`Sửa điểm hiệu suất tài xế ${shipper.name}`}
        okText="Lưu"
        cancelText="Huỷ"
        centered
        open={open}
        onOk={() => onSubmit(shipper, { exp, value, reason })}
        onCancel={onClose}
        confirmLoading={isProcessing}
      >
        <p>Điểm hiện tại: {shipper.weeklyPoint || 0}</p>
        <Form layout="vertical">
          <Form.Item label="Thay đổi điểm">
            <Space.Compact>
              <Select
                value={exp}
                options={[
                  { value: "+", label: "+" },
                  { value: "-", label: "-" },
                ]}
                onChange={(e) => setExp(e)}
                style={{ width: 60 }}
              />
              <InputNumber
                min={0}
                value={value}
                onChange={(e) => setValue(e)}
                style={{ width: 120 }}
              />
            </Space.Compact>
          </Form.Item>
          <Form.Item label="Lý do">
            <Input.TextArea
              onChange={(e) => setReason(e.target.value)}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
