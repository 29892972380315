import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./components/Actions";
import AdTypeDetailModal from "./components/DetailModal";
import * as adTypeActions from "./slice";

function AdType() {
  const dispatch = useDispatch();
  const { adTypes, pagination, isLoadTypeing, isProcessing, isCompleted } =
    useSelector((state) => state.adType);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingAdType, setSelectingAdType] = useState(null);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(adTypeActions.fetchAdTypes(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingAdType(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (adType = null) => {
    setSelectingAdType(adType);
    setOpenDetailModal(true);
  };

  const onDelete = (adType) => {
    Modal.confirm({
      title: `Xác nhận xoá loại hình quảng cáo`,
      content: `Bạn có chắc chắn muốn xoá loại hình quảng cáo này?`,
      onOk: () => dispatch(adTypeActions.deleteAdType(adType._id)),
      centered: true,
    });
  };

  const onSubmit = (adType) => {
    if (adType._id) {
      dispatch(adTypeActions.updateAdType(adType));
    } else {
      dispatch(adTypeActions.createAdType(adType));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingAdType(null);
    dispatch(adTypeActions.setIsProcessing(false));
  };

  const onChangeRestaurantSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = (value) => {
    setSearchName(value);
    dispatch(
      adTypeActions.fetchAdTypes({
        ...pagination,
        name: value,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      adTypeActions.fetchAdTypes({
        ...pagination,
        name: searchName,
      })
    );
  };
  const columns = [
    {
      title: "STT",
      align: "center",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
    },
    {
      title: "Ưu tiên",
      dataIndex: "priority",
      align: "center",
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onDelete={onDelete}
        />
      ),
    },
  ];
  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Loại hình quảng cáo
        </Typography.Title>
        <Space size="middle">
          <Input.Search
            placeholder="Tìm theo tên hoặc mô tả"
            onChange={onChangeRestaurantSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onOpenDetailModal()}
          >
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loadTypeing={isLoadTypeing}
        columns={columns}
        dataSource={adTypes}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <AdTypeDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          adType={selectingAdType}
        />
      )}
    </>
  );
}

export default AdType;
