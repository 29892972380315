import { createSlice } from "@reduxjs/toolkit";
import messageApi from "./api";
import { toast } from "react-toastify";

const initialState = {
  messages: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    addMessage: (state, action) => {
      state.isCompleted = true;
      state.messages.unshift(action.payload);
    },
    editMessage: (state, action) => {
      state.isCompleted = true;
      state.messages = state.messages.map((message) =>
        message._id === action.payload._id ? action.payload : message
      );
    },
    removeMessage: (state, action) => {
      state.isCompleted = true;
      state.messages = state.messages.filter(
        (message) => message._id !== action.payload
      );
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setMessages,
  setPagination,
  addMessage,
  editMessage,
  removeMessage,
} = messageSlice.actions;

export const fetchMessages = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await messageApi.getAll(params);
      dispatch(setMessages(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const createMessage = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await messageApi.create(formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addMessage(data));
        toast.success("Thêm tin nhắn mới thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateMessage = (message, formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await messageApi.update(message._id, formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editMessage(data));
        toast.success("Cập nhật tin nhắn thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const deleteMessage = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await messageApi.delete(id);
      if (data.success) {
        dispatch(removeMessage(id));
        toast.success("Xóa tin nhắn thành công!");
      } else {
        toast.error("Lỗi hệ thống, xóa tin nhắn thất bại!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default messageSlice.reducer;
