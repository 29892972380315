import axiosClient from "utils/api/axios-client";

const regionApi = {
  getAll: (params) => {
    const url = "/api/v1/regions";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/regions/${id}`;
    return axiosClient.get(url);
  },
  create: (region) => {
    const url = `/api/v1/regions/`;
    return axiosClient.post(url, region);
  },
  update: (region) => {
    const url = `/api/v1/regions/${region._id}`;
    return axiosClient.put(url, region);
  },
  delete: (id) => {
    const url = `/api/v1/regions/${id}`;
    return axiosClient.delete(url);
  },
};

export default regionApi;
