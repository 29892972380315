import { Button, Form, Input, Modal, Switch, Tag } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import restaurantApi from "../api";

export default function SetupEventModal({
  open,
  onSubmit,
  onCancel,
  isProcessing,
  restaurant,
}) {
  const [eventInfo, setEventInfo] = useState({
    name: restaurant.event?.name || "",
    isAvailable: restaurant.event?.isAvailable || false,
    dish: restaurant.event?.dish || "",
    minOrderValue: restaurant.event?.minOrderValue || 0,
    isLimit: restaurant.event?.isLimit || false,
    limitAlert:
      restaurant.event?.limitAlert ||
      "Hôm nay bạn đã săn được deal 0đ rồi. Quay lại vào ngày mai nhé.",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState(restaurant.event?.users || []);

  useEffect(() => {
    const fetchEventUsers = async () => {
      setIsLoading(true);
      try {
        const data = await restaurantApi.getEventUsers(restaurant._id);
        if (data.error) {
          toast.error(data.error);
        } else {
          setUsers(data);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setIsLoading(false);
    };

    if (open && restaurant.event?.isAvailable) fetchEventUsers();
  }, [open]);

  const onUpdateEventInfo = (property, value) => {
    setEventInfo((c) => ({ ...c, [property]: value }));
  };

  const onSubmitValidate = () => {
    if (eventInfo.isAvailable) {
      if (!eventInfo.name) {
        toast.error("Vui lòng nhập tên sự kiện");
        return;
      }
      if (!eventInfo.dish) {
        toast.error("Vui lòng nhập ID món chaỵ sự kiện");
        return;
      }
      if (eventInfo.minOrderValue < 0) {
        toast.error("Vui lòng nhập giá trị đơn hàng tối thiểu >= 0");
        return;
      }

      if (eventInfo.isLimit && !eventInfo.limitAlert) {
        toast.error(
          "Vui lòng nhập nội dung thông báo khi người dùng đạt giới hạn"
        );
        return;
      }
    }
    onSubmit(restaurant, eventInfo);
  };

  const onResetEventUsers = async () => {
    Modal.confirm({
      title: `Xác nhận reset danh sách`,
      content: `Bạn có chắc chắn muốn reset danh sách khách đã tham gia sự kiện?`,
      onOk: async () => {
        setIsLoading(true);
        try {
          const data = await restaurantApi.resetEventUsers(restaurant._id);
          if (data.error) {
            toast.error(data.error);
          } else {
            setUsers([]);
            toast.success("Reset danh sách khách đã tham gia thành công");
          }
        } catch (error) {
          toast.error(error.message);
        }
        setIsLoading(false);
      },
      centered: true,
    });
  };

  return (
    <>
      <Modal
        title="Thiết lập sự kiện cho quán"
        okText="Lưu"
        cancelText="Huỷ"
        centered
        open={open}
        onOk={onSubmitValidate}
        onCancel={onCancel}
        confirmLoading={isProcessing}
        width={680}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item label="Chạy sự kiện">
            <Switch
              defaultChecked
              value={eventInfo.isAvailable}
              onChange={(value) => onUpdateEventInfo("isAvailable", value)}
              checked={eventInfo.isAvailable}
            />
          </Form.Item>
          {eventInfo.isAvailable && (
            <>
              <Form.Item label="Tên sự kiện">
                <Input
                  placeholder="Deal 0đ"
                  value={eventInfo.name}
                  onChange={(e) => onUpdateEventInfo("name", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="ID món">
                <Input
                  placeholder="ID món chạy sự kiện"
                  value={eventInfo.dish}
                  onChange={(e) => onUpdateEventInfo("dish", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Giá trị đơn hàng">
                <Input
                  type="number"
                  placeholder="Giá trị đơn hàng tối thiểu"
                  value={eventInfo.minOrderValue}
                  onChange={(e) =>
                    onUpdateEventInfo("minOrderValue", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item label="Giới hạn sử dụng">
                <Switch
                  defaultChecked
                  value={eventInfo.isLimit}
                  onChange={(value) => onUpdateEventInfo("isLimit", value)}
                  checked={eventInfo.isLimit}
                />
              </Form.Item>
              <Form.Item label="Thông báo khi giới hạn">
                <Input
                  placeholder="Nội dung thông báo khi người dùng đạt giới hạn"
                  value={eventInfo.limitAlert}
                  onChange={(e) =>
                    onUpdateEventInfo("limitAlert", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item label="Khách đã tham gia">
                <div style={{ paddingTop: 4 }}>
                  <b style={{ marginRight: 6 }}>[{users.length || 0}]</b>
                  {users.length > 0 ? (
                    <Button
                      danger
                      size="small"
                      type="text"
                      onClick={onResetEventUsers}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Reset danh sách
                    </Button>
                  ) : null}
                </div>
                <div style={{ marginTop: 10 }}>
                  {users.map((user, index) => (
                    <Tag key={index} color="processing">
                      {user}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}
