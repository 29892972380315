import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  Upload,
} from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";

const Text = Typography.Text;

function RegionDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onSubmit, onCancel, region } = props;
  const [coordinates, setCoordinates] = useState([]);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    if (region?.coordinates) {
      setCoordinates(region.coordinates);
    } else {
      setCoordinates([]);
    }
  }, [region]);

  useEffect(() => {
    const getBanks = async () => {
      const data = await commonApi.getBanks();
      if (!data || data.error) {
        toast.error(data.error.msg);
      } else {
        setBanks(data);
      }
    };
    if (open) {
      getBanks();
    }
  }, [open]);

  const initialValues = region || {
    code: "",
    name: "",
    phone: "",
    telegramId: "",
    banking: {
      bankId: "",
      accountNumber: "",
      accountName: "",
    },
    active: true,
    isDefault: false,
  };

  const onValuesChange = (changedValues) => {
    if (changedValues.code) {
      form.setFieldValue("code", changedValues.code.toLowerCase());
    }
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        if (!coordinates?.length) {
          toast.error("Dữ liệu GeoJSON không đúng định dạng!");
          return;
        }
        onSubmit({ ...initialValues, ...values, coordinates });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const beforeUpload = async (file) => {
    console.log(file.type);
    // const validType = file.type === "application/geo+json";
    // if (!validType) {
    //   toast.error("Chỉ chấp nhận file .geojson!");
    // } else {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = JSON.parse(event.target.result);
      const _coordinates = data?.features[0]?.geometry?.coordinates[0];
      if (!_coordinates.length) {
        toast.error("File không chứa dữ liệu hợp lệ!");
      } else {
        setCoordinates(_coordinates);
      }
    };
    // on error
    reader.onerror = (error) => {
      toast.error("Đã có lỗi xảy ra khi đọc file!");
    };
    reader.readAsText(file);
    // }
    return false;
  };

  return (
    <Modal
      title="Phân cụm"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 18 } }}
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="code"
          label="Mã phân cụm"
          disabled={region}
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input disabled={region} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Tên phân cụm"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="SĐT liên hệ"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="telegram" label="Telegram ID">
          <Input />
        </Form.Item>
        <Form.Item
          name={["banking", "bankId"]}
          label="Ngân hàng"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            options={banks.map((bank) => ({
              value: bank.id,
              label: bank.name,
            }))}
          ></Select>
        </Form.Item>
        <Form.Item
          name={["banking", "accountNumber"]}
          label="Số tài khoản"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["banking", "accountName"]}
          label="Chủ tài khoản"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="GeoJSON file">
          {coordinates?.length > 0 && (
            <div>
              <Text
                style={{
                  maxWidth: "100%",
                  marginBottom: "-4px",
                }}
                ellipsis
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`http://geojson.io/#data=data:application/json,${encodeURIComponent(
                    JSON.stringify({
                      type: "FeatureCollection",
                      features: [
                        {
                          type: "Feature",
                          properties: {
                            stroke: "#f00",
                            "stroke-width": 2,
                            "stroke-opacity": 1,
                            fill: "#ffd22f",
                            "fill-opacity": 0.4,
                          },
                          geometry: {
                            coordinates: [coordinates],
                            type: "Polygon",
                          },
                          id: 0,
                        },
                      ],
                    })
                  )}`}
                >
                  <EyeOutlined /> Xem trên bản đồ
                </a>
              </Text>
              <br />
              <br />
            </div>
          )}
          <Upload
            required
            maxCount={1}
            accept={"application/geo+json"}
            name="file"
            beforeUpload={beforeUpload}
            showUploadList={{
              showDownloadIcon: true,
              showRemoveIcon: true,
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Row>
          <Col xs={0} sm={6}></Col>
          <Col xs={24} sm={18}>
            <Space size="large">
              <Form.Item name="active" valuePropName="checked" noStyle>
                <Switch checkedChildren="Active" unCheckedChildren="Active" />
              </Form.Item>
              <Form.Item name="isDefault" valuePropName="checked" noStyle>
                <Switch
                  checkedChildren="Mặc định"
                  unCheckedChildren="Mặc định"
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

RegionDetailModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  region: PropTypes.object,
};

RegionDetailModal.defaultProps = {
  isProcessing: false,
  open: false,
  region: null,
};

export default RegionDetailModal;
