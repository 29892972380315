import axiosClient from "utils/api/axios-client";

const dashboardApi = {
  getDashboardStats: (from, to) => {
    const url = `/api/v1/dashboard-stats?from=${from}&to=${to}`;
    return axiosClient.get(url);
  },
};

export default dashboardApi;
