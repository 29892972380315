import { createSlice } from "@reduxjs/toolkit";
import accountApi from "./api";
import { toast } from "react-toastify";

const initialState = {
  accounts: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const accountSlice = createSlice({
  name: "superAccount",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    addAccount: (state, action) => {
      state.isCompleted = true;
      state.accounts.unshift(action.payload);
    },
    editAccount: (state, action) => {
      state.isCompleted = true;
      state.accounts = state.accounts.map((account) =>
        account._id === action.payload._id ? action.payload : account
      );
    },
    removeAccount: (state, action) => {
      state.isCompleted = true;
      state.accounts = state.accounts.filter(
        (user) => user._id !== action.payload._id
      );
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setAccounts,
  setPagination,
  addAccount,
  editAccount,
  removeAccount,
} = accountSlice.actions;

export const fetchAccounts = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await accountApi.getAll(params);
      dispatch(setAccounts(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const createAccount = (account) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await accountApi.create(account);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addAccount(data));
        toast.success("Thêm tài khoản mới thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const resetPasswordAccount = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await accountApi.resetPassword(id);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        toast.success("Reset mật khẩu tài khoản thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateAccount = (account) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await accountApi.update(account);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editAccount(data));
        toast.success("Cập nhật tài khoản thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const deleteAccount = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await accountApi.delete(id);
      dispatch(removeAccount(data));
      toast.success("Xóa tài khoản thành công!");
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default accountSlice.reducer;
