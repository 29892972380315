import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import NotificationDetailModal from "./components/DetailModal";
import * as notificationActions from "./slice";

function Notification() {
  const dispatch = useDispatch();
  const { notifications, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.notification);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingNotification, setSelectingNotification] = useState(null);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(notificationActions.fetchNotifications(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingNotification(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (notification = null) => {
    setSelectingNotification(notification);
    setOpenDetailModal(true);
  };

  const onDelete = (notification) => {
    Modal.confirm({
      title: `Xác nhận xoá thông báo`,
      content: `Bạn có chắc chắn muốn xoá thông báo này?`,
      onOk: () =>
        dispatch(notificationActions.deleteNotification(notification._id)),
      centered: true,
    });
  };

  const onSubmit = (notification, formData) => {
    if (notification._id) {
      dispatch(notificationActions.updateNotification(notification, formData));
    } else {
      dispatch(notificationActions.createNotification(formData));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingNotification(null);
    dispatch(notificationActions.setIsProcessing(false));
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = () => {
    dispatch(
      notificationActions.fetchNotifications({
        ...pagination,
        name: searchName,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      notificationActions.fetchNotifications({
        ...pagination,
        name: searchName,
      })
    );
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      width: "10%",
      render: (image) =>
        image ? (
          <Image
            alt="Image of food notification"
            src={getPhotoUrl(image?.path || null)}
            width={100}
            height={50}
          />
        ) : null,
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Nội dung",
      dataIndex: "body",
      render: (body) => (
        <div style={{ whiteSpace: "pre-line" }}>
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "Xem thêm",
            }}
          >
            {body}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: "App",
      dataIndex: "receiverModel",
      align: "center",
      width: "8%",
    },
    {
      title: "Đối tượng",
      dataIndex: "docModel",
      align: "center",
      width: "8%",
    },
    {
      title: "Người nhận",
      dataIndex: "receiverType",
      align: "center",
      width: "8%",
      render: (receiverType) =>
        receiverType === "all" ? "Tất cả" : "Chỉ định",
    },
    {
      title: "Thời gian gửi",
      dataIndex: "sendTime",
      width: "12%",
      render: (startDate) => moment(startDate).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "12%",
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Thông báo
        </Typography.Title>
        <Space size="middle">
          <Input.Search
            placeholder="Tìm theo tiêu đề"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onOpenDetailModal()}
          >
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={notifications}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <NotificationDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          notification={selectingNotification}
        />
      )}
    </>
  );
}

export default Notification;
