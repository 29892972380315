import { PlusOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Modal, Select, Upload } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoThumbnailUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

const { TextArea } = Input;
const { Option } = Select;

const disabledDate = (current) => current && current.diff(moment(), "days") < 0;

function NotificationDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onSubmit, onCancel, notification } = props;
  const [imageUrl, setImageUrl] = useState(
    getPhotoThumbnailUrl(notification?.image?.path || null)
  );
  const [image, setImage] = useState(null);
  const [receiverType, setReceiverType] = useState(
    notification?.receiverType || "all"
  );
  const [docModel, setDocModel] = useState(notification?.docModel || "None");

  const initialValues = notification
    ? {
        ...notification,
        receiver: notification.receiver?.join(", ") || "",
        sendTime: dayjs(notification.sendTime),
      }
    : {
        title: "",
        body: "",
        receiverModel: "User",
        receiverType: "all",
        receiver: "",
        sendTime: "",
        docModel: "None",
        doc: "",
        url: "",
        deleted: false,
      };
  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.receiverType) {
      setReceiverType(allValues.receiverType);
    } else if (changedValues.docModel) {
      setDocModel(allValues.docModel);
    }
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        const notification = { ...initialValues, ...values };
        Object.entries({
          data: JSON.stringify(notification),
          image,
        }).forEach(([key, value]) => {
          formData.append(key, value);
        });
        onSubmit(notification, formData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const beforeUpload = async (file) => {
    const validType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!validType) {
      toast.error("Chỉ chấp nhận file ảnh!");
    } else {
      const preview = await getBase64(file);
      setImageUrl(preview);
      setImage(file);
    }
    return false;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      title="Thông báo"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
      width={800}
    >
      <Form
        {...formItemLayoutHorizontal}
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="title"
          label="Tiêu đề"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item
          name="body"
          label="Nội dung"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder="Nội dung thông báo"
          />
        </Form.Item>
        <Form.Item
          name="sendTime"
          label="Thời gian gửi"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <DatePicker
            showTime
            style={{ width: "100%" }}
            disabledDate={disabledDate}
            // format="YYYY-MM-DD HH:mm:ss"
            placeholder={"Thời gian gửi thông báo"}
          />
        </Form.Item>

        <Form.Item name="receiverModel" label="App thông báo">
          <Select>
            <Option value={"User"}>Người dùng</Option>
            <Option value={"Merchant"}>Chủ quán</Option>
            <Option value={"Shipper"}>Shipper</Option>
          </Select>
        </Form.Item>

        <Form.Item name="receiverType" label="Người nhận">
          <Select>
            <Option value={"all"}>Tất cả</Option>
            <Option value={"specific"}>Tùy chọn</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="receiver"
          label="DS người nhận"
          rules={[
            {
              required: receiverType !== "all",
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <TextArea
            disabled={receiverType === "all"}
            autoSize={{ minRows: 2, maxRows: 5 }}
            placeholder="Danh sách ID người nhận cách nhau dấu ,"
          />
        </Form.Item>

        <Form.Item name="docModel" label="Đối tượng">
          <Select>
            <Option value={"None"}>Không</Option>
            <Option value={"Restaurant"}>Quán</Option>
            <Option value={"Campaign"}>Chiến dịch</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="doc"
          label="ID Đối tượng"
          rules={[
            {
              required: docModel !== "None",
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input placeholder="ID Đối tượng" disabled={docModel === "None"} />
        </Form.Item>
        <Form.Item name="url" label="URL">
          <Input placeholder="URL" />
        </Form.Item>
        <Form.Item label="Hình ảnh">
          <Upload
            accept={"image/*"}
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="image" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

NotificationDetailModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  notification: PropTypes.object,
};

NotificationDetailModal.defaultProps = {
  isProcessing: false,
  open: false,
  notification: null,
};

export default NotificationDetailModal;
