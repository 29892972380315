import axiosClient from "utils/api/axios-client";

const api = {
  getBanking: () => {
    const url = "/api/v1/super-config/banking";
    return axiosClient.get(url);
  },
  updateBanking: (data) => {
    const url = "/api/v1/super-config/banking";
    return axiosClient.put(url, data);
  },
};

export default api;
