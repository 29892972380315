import axiosClient from "utils/api/axios-client";

const restaurantApi = {
  getAll: (params) => {
    const url = "/api/v1/restaurants";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/restaurants/${id}`;
    return axiosClient.get(url);
  },
  create: (restaurant) => {
    const url = `/api/v1/restaurants/`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, restaurant, config);
  },
  updateIsOpen: (id, isOpen) => {
    const url = `/api/v1/restaurants/${id}/open`;
    return axiosClient.put(url, { isOpen });
  },
  setAutoOpen: (id, time) => {
    const url = `/api/v1/restaurants/${id}/auto-open`;
    return axiosClient.put(url, { time });
  },
  updateIsPromo: (id, isPromo) => {
    const url = `/api/v1/restaurants/${id}/promo`;
    return axiosClient.put(url, { isPromo });
  },
  updateActive: (id, active, sendNoti) => {
    const url = `/api/v1/restaurants/${id}/active`;
    return axiosClient.put(url, { active, sendNoti });
  },
  updateInfo: (id, restaurant) => {
    const url = `/api/v1/restaurants/${id}/info`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.put(url, restaurant, config);
  },
  updateAccount: (id, restaurant) => {
    const url = `/api/v1/restaurants/${id}/account`;
    return axiosClient.put(url, restaurant);
  },
  updateBanking: (id, restaurant) => {
    const url = `/api/v1/restaurants/${id}/banking`;
    return axiosClient.put(url, restaurant);
  },
  resetPassword: (id) => {
    const url = `/api/v1/restaurants/${id}/reset-password`;
    return axiosClient.patch(url);
  },
  delete: (id) => {
    const url = `/api/v1/restaurants/${id}`;
    return axiosClient.delete(url);
  },
  getGeoAddress: (address) => {
    const url = `/api/v1/geo/${address}`;
    return axiosClient.get(url);
  },
  getMenus: (idRestaurant) => {
    const url = `/api/v1/restaurants/${idRestaurant}/menus`;
    return axiosClient.get(url);
  },
  createMenu: (menu) => {
    const url = `/api/v1/restaurants/menus`;
    return axiosClient.post(url, menu);
  },
  updateMenu: (id, menu) => {
    const url = `/api/v1/restaurants/menus/${id}`;
    return axiosClient.put(url, menu);
  },
  updateMenuPriority: (menuList) => {
    const url = `/api/v1/restaurants/menus/priority`;
    return axiosClient.put(url, menuList);
  },
  deleteMenu: (id) => {
    const url = `/api/v1/restaurants/menus/${id}`;
    return axiosClient.delete(url);
  },
  getDishes: (id) => {
    const url = `/api/v1/restaurants/${id}/dishes`;
    return axiosClient.get(url);
  },
  createDish: (dish) => {
    const url = `/api/v1/restaurants/dishes`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, dish, config);
  },
  updateCharge: (id, chargeInfo) => {
    const url = `/api/v1/restaurants/${id}/charge`;
    return axiosClient.patch(url, { ...chargeInfo });
  },
  setupEvent: (id, eventInfo) => {
    const url = `/api/v1/restaurants/${id}/event`;
    return axiosClient.patch(url, { ...eventInfo });
  },
  updateDish: (id, dish) => {
    const url = `/api/v1/restaurants/dishes/${id}`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.put(url, dish, config);
  },
  updateDishStatus: (id, status) => {
    const url = `/api/v1/restaurants/dishes/${id}/status`;
    return axiosClient.put(url, { status });
  },
  updateDishIsHot: (id, isHot) => {
    const url = `/api/v1/restaurants/dishes/${id}/isHot`;
    return axiosClient.put(url, { isHot });
  },
  updateDishIsAvailable: (id, isAvailable) => {
    const url = `/api/v1/restaurants/dishes/${id}/isAvailable`;
    return axiosClient.put(url, { isAvailable });
  },
  autoAvailableDish: (id, time) => {
    const url = `/api/v1/restaurants/dishes/${id}/auto-available`;
    return axiosClient.put(url, { time });
  },
  updateDishIsHidden: (id, isHidden) => {
    const url = `/api/v1/restaurants/dishes/${id}/isHidden`;
    return axiosClient.put(url, { isHidden });
  },
  updateDiscount: (id, data) => {
    const url = `/api/v1/restaurants/${id}/discount`;
    return axiosClient.put(url, data);
  },
  updateSellTime: (id, data) => {
    const url = `/api/v1/restaurants/${id}/sell-time`;
    return axiosClient.put(url, data);
  },
  deleteDish: (id) => {
    const url = `/api/v1/restaurants/dishes/${id}`;
    return axiosClient.delete(url);
  },
  getDishOptions: (id) => {
    const url = `/api/v1/restaurants/${id}/dish-options`;
    return axiosClient.get(url);
  },
  createDishOption: (dishOption) => {
    const url = `/api/v1/restaurants/dish-options`;
    return axiosClient.post(url, dishOption);
  },
  updateDishOption: (id, dishOption) => {
    const url = `/api/v1/restaurants/dish-options/${id}`;
    return axiosClient.put(url, dishOption);
  },
  deleteDishOption: (id) => {
    const url = `/api/v1/restaurants/dish-options/${id}`;
    return axiosClient.delete(url);
  },
  getStats: (id, from, to) => {
    const url = `/api/v1/restaurants/${id}/stats?from=${from}&to=${to}`;
    return axiosClient.get(url);
  },
  verifyUpdateInfo: (id) => {
    const url = `/api/v1/restaurants/${id}/verify-update-info`;
    return axiosClient.put(url);
  },
  rejectUpdateInfo: (id, reason) => {
    const url = `/api/v1/restaurants/${id}/reject-update-info`;
    return axiosClient.put(url, { reason });
  },
  getEventUsers: (id) => {
    const url = `/api/v1/restaurants/${id}/event-users`;
    return axiosClient.get(url);
  },
  resetEventUsers: (id) => {
    const url = `/api/v1/restaurants/${id}/reset-event-users`;
    return axiosClient.put(url);
  },
};

export default restaurantApi;
