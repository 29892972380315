import { GiftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl, priceToCurrency } from "utils/common";
import Actions from "./components/Actions";
import RestaurantDetailModal from "./components/DetailModal";
import RestaurantMenuModal from "./components/MenuModal";
import RejectUpdateInfoModal from "./components/RejectUpdateInfoModal";
import SetAutoOpenModal from "./components/SetAutoOpenModal";
import StatsModal from "./components/StatsModal";
import UpdateChargeModal from "./components/UpdateChargeModal";
import UpdateInfoModal from "./components/UpdateInfoModal";
import ActiveUnactiveModal from "./components/ActiveUnactiveModal";
import * as restaurantActions from "./slice";
import { toast } from "react-toastify";
import SetupEventModal from "./components/SetupEventModal";

const { Paragraph, Text } = Typography;

function Restaurant() {
  const {
    restaurants,
    totalUpdate,
    pagination,
    isLoading,
    isProcessing,
    isCompleted,
    isCompletedProcessingUpdateInfo,
  } = useSelector((state) => state.restaurant);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openUpdateChargeModal, setOpenUpdateChargeModal] = useState(false);
  const [openSetAutoOpenModal, setOpenSetAutoOpenModal] = useState(false);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [openStatsModal, setOpenStatsModal] = useState(false);
  const [openUpdateInfoModal, setOpenUpdateInfoModal] = useState(false);
  const [openRejectUpdateInfoModal, setOpenRejectUpdateInfoModal] =
    useState(false);
  const [openActiveUnactiveModal, setOpenActiveUnactiveModal] = useState(false);
  const [openSetupEventModal, setOpenSetupEventModal] = useState(false);
  const [selectingRestaurant, setSelectingRestaurant] = useState(null);
  const [selectingRestaurantId, setSelectingRestaurantId] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchIsPromo, setSearchIsPromo] = useState("");
  const [searchIsClose, setSearchIsClose] = useState("");
  const [searchIsOpen, setSearchIsOpen] = useState("");
  const [searchIsNew, setSearchIsNew] = useState("");
  const [searchIsHot, setSearchIsHot] = useState("");
  const [searchIsPartner, setSearchIsPartner] = useState("");
  const [searchIsActive, setSearchIsActive] = useState(true);
  const [searchIsRequestUpdate, setSearchIsRequestUpdate] = useState(false);
  const [searchIsTags, setSearchIsTags] = useState([]);
  const [sortBy, setSortBy] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      restaurantActions.fetchRestaurants({
        ...pagination,
        isActive: searchIsActive,
      })
    );
  }, []);

  useEffect(() => {
    if (isCompletedProcessingUpdateInfo) {
      onCancelActiveUnactive();
      onCloseUpdateInfoModal();
      onCloseRejectUpdateInfo();
      dispatch(
        restaurantActions.fetchRestaurants({
          ...pagination,
          name: searchName,
          isPromo: searchIsPromo,
          isClose: searchIsClose,
          isOpen: searchIsOpen,
          isNew: searchIsNew,
          isHot: searchIsHot,
          isPartner: searchIsPartner,
          isActive: searchIsActive,
          isRequestUpdate: searchIsRequestUpdate,
          isTags: searchIsTags,
          sortBy: sortBy,
        })
      );
    }
  }, [isCompletedProcessingUpdateInfo]);

  useEffect(() => {
    if (isCompleted) {
      onCloseSetupEventModal();
      onCloseUpdateChargeModal();
    }
  }, [isCompleted]);

  const onTableChange = async (pagination) => {
    dispatch(
      restaurantActions.fetchRestaurants({
        ...pagination,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isRequestUpdate: searchIsRequestUpdate,
        isTags: searchIsTags,
        sortBy: sortBy,
      })
    );
  };

  const onResetPassword = (restaurant) => {
    if (restaurant._id) {
      dispatch(restaurantActions.resetPasswordRestaurant(restaurant._id));
    }
  };

  const onSubmit = (restaurant, formData) => {
    if (!restaurant._id) {
      dispatch(restaurantActions.createRestaurant(formData));
    }
  };

  const onCloseDetailModal = () => {
    setOpenDetailModal(false);
    setSelectingRestaurant(null);
    dispatch(restaurantActions.setIsProcessing(false));
  };

  const onCloseMenuModal = () => {
    setOpenMenuModal(false);
    setSelectingRestaurant(null);
    dispatch(restaurantActions.clearDetailRestaurantState());
  };

  const handleSearchRequestUpdate = () => {
    setSearchIsRequestUpdate(!searchIsRequestUpdate);
    dispatch(
      restaurantActions.fetchRestaurants({
        isRequestUpdate: !searchIsRequestUpdate,
        isActive: searchIsActive,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsPromo = (isPromo) => {
    setSearchIsPromo(isPromo);
    dispatch(
      restaurantActions.fetchRestaurants({
        isPromo: isPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        name: searchName,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsClose = (isClose) => {
    setSearchIsClose(isClose);
    dispatch(
      restaurantActions.fetchRestaurants({
        isClose: isClose,
        isPromo: searchIsPromo,
        isOpen: searchIsOpen,
        name: searchName,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsOpen = (isOpen) => {
    setSearchIsOpen(isOpen);
    dispatch(
      restaurantActions.fetchRestaurants({
        isOpen: isOpen,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        name: searchName,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsNew = (isNew) => {
    setSearchIsNew(isNew);
    dispatch(
      restaurantActions.fetchRestaurants({
        isNew: isNew,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsHot = (isHot) => {
    setSearchIsHot(isHot);
    dispatch(
      restaurantActions.fetchRestaurants({
        isHot: isHot,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsPartner = (isPartner) => {
    setSearchIsPartner(isPartner);
    dispatch(
      restaurantActions.fetchRestaurants({
        isPartner: isPartner,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isActive: searchIsActive,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsActive = (isActive) => {
    setSearchIsActive(isActive);
    dispatch(
      restaurantActions.fetchRestaurants({
        isActive: isActive,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isTags: searchIsTags,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSearchIsTags = (isTags) => {
    setSearchIsTags(isTags);
    dispatch(
      restaurantActions.fetchRestaurants({
        isTags: isTags,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        sortBy: sortBy,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleSelectSort = (sortBy) => {
    setSortBy(sortBy);
    dispatch(
      restaurantActions.fetchRestaurants({
        sortBy: sortBy,
        name: searchName,
        isPromo: searchIsPromo,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        isTags: searchIsTags,
        ...pagination,
        current: 1,
      })
    );
  };

  const handleToggleOpen = (id, isOpen) => {
    Modal.confirm({
      title: `Xác nhận ${isOpen ? "bật" : "tắt"} nhận đơn?`,
      content: `Bạn có chắc muốn ${
        isOpen ? "bật" : "tắt"
      } nhận đơn cho quán này?`,
      onOk: () => onSetIsOpen(id, isOpen),
      centered: true,
    });
  };

  const handleTogglePromo = (id, isPromo) => {
    Modal.confirm({
      title: `Xác nhận quán ${isPromo ? "có" : "không có"} voucher?`,
      content: `Bạn có chắc muốn xác nhận quán này ${
        isPromo ? "có" : "không có"
      } voucher?`,
      onOk: () => onSetIsPromo(id, isPromo),
      centered: true,
    });
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = (searchValue) => {
    dispatch(
      restaurantActions.fetchRestaurants({
        ...pagination,
        name: searchValue,
        isClose: searchIsClose,
        isOpen: searchIsOpen,
        isNew: searchIsNew,
        isHot: searchIsHot,
        isPartner: searchIsPartner,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const onOpenDetailModal = (restaurant = null) => {
    setSelectingRestaurant(restaurant);
    setOpenDetailModal(true);
  };

  const onOpenMenuModal = (restaurant) => {
    setSelectingRestaurant(restaurant);
    setOpenMenuModal(true);
  };

  const onOpenUpdateChargeModal = (restaurant) => {
    setSelectingRestaurant(restaurant);
    setOpenUpdateChargeModal(true);
  };

  const onOpenStatsModal = (restaurant) => {
    setSelectingRestaurant(restaurant);
    setOpenStatsModal(true);
  };

  const onSubmitNewCharge = (restaurant, chargeInfo) => {
    dispatch(restaurantActions.updateRestaurantCharge(restaurant, chargeInfo));
  };

  const onOpenSetupEventModal = (restaurant) => {
    setSelectingRestaurant(restaurant);
    setOpenSetupEventModal(true);
  };

  const onCloseSetupEventModal = () => {
    setOpenSetupEventModal(false);
    setSelectingRestaurant(null);
  };

  const onSetupEvent = (restaurant, event) => {
    dispatch(restaurantActions.setupEvent(restaurant, event));
  };

  const onCloseUpdateChargeModal = () => {
    setSelectingRestaurant(null);
    setOpenUpdateChargeModal(false);
  };

  const onCloseSetAutoOpenModal = () => {
    setSelectingRestaurantId(null);
    setOpenSetAutoOpenModal(false);
  };

  const onCloseStatsModal = () => {
    setOpenStatsModal(false);
    setSelectingRestaurant(null);
    dispatch(restaurantActions.setIsProcessing(false));
  };

  const onSetIsOpen = (_id, isOpen) => {
    dispatch(restaurantActions.updateIsOpenRestaurant(_id, isOpen));
    if (!isOpen) {
      setSelectingRestaurantId(_id);
      setOpenSetAutoOpenModal(true);
    }
  };

  const onSetIsPromo = (_id, isPromo) => {
    dispatch(restaurantActions.updateIsPromoRestaurant(_id, isPromo));
  };

  const onOpenUpdateInfoModal = (restaurant) => {
    setSelectingRestaurant(restaurant);
    setOpenUpdateInfoModal(true);
  };

  const onCloseUpdateInfoModal = () => {
    setSelectingRestaurant(null);
    setOpenUpdateInfoModal(false);
  };

  const onActiveUnactive = (restaurant) => {
    console.log(restaurant);
    setSelectingRestaurant(restaurant);
    setOpenActiveUnactiveModal(true);
  };

  const onConfirmActiveUnactive = (restaurant, active, sendNoti) => {
    dispatch(
      restaurantActions.updateActiveRestaurant(restaurant._id, active, sendNoti)
    );
  };

  const onCancelActiveUnactive = () => {
    setOpenActiveUnactiveModal(false);
    setSelectingRestaurant(null);
  };

  const onVerifyUpdateInfo = () => {
    Modal.confirm({
      title: `Xác nhận thay đổi thông tin quán`,
      content: `Thông tin quán sẽ được thay đổi theo thông tin chủ quán đã cập nhật. Bạn chắc chắn chưa?`,
      onOk: () => {
        if (selectingRestaurant?._id) {
          dispatch(restaurantActions.verifyUpdateInfo(selectingRestaurant._id));
        }
      },
      centered: true,
    });
  };

  const onRejectUpdateInfo = () => {
    setOpenRejectUpdateInfoModal(true);
  };

  const onCloseRejectUpdateInfo = () => {
    setOpenRejectUpdateInfoModal(false);
  };

  const onSubmitRejectUpdateInfo = (reason) => {
    if (!reason) {
      toast.error("Vui lòng nhập lý do từ chối");
      return;
    }
    if (selectingRestaurant?._id) {
      dispatch(
        restaurantActions.rejectUpdateInfo(selectingRestaurant._id, reason)
      );
    }
    setOpenRejectUpdateInfoModal(false);
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Paragraph>
      ),
    },
    {
      title: "Hình ảnh",
      width: "10%",
      dataIndex: "avatar",
      render: (avatar) =>
        avatar ? (
          <Image
            alt="Avatar of restaurant"
            src={getPhotoUrl(avatar?.path || null)}
            width="80px"
            height="80px"
          />
        ) : null,
    },
    {
      title: "Tên quán",
      width: "24%",
      // dataIndex: "name",
      render: (record) => (
        <>
          <Paragraph
            strong
            copyable={{
              tooltips: ["Sao chép link", "Đã sao chép!"],
              text: `https://link.gokaapp.vn/${record.code}`,
            }}
          >
            {record.name}
          </Paragraph>
          {record.event?.isAvailable ? (
            <Tag
              icon={<GiftOutlined />}
              color="#ff004b"
              style={{ marginTop: "6px" }}
            >
              {record.event?.name}
            </Tag>
          ) : null}
          {record.isPartner ? (
            <Tag
              color="blue"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Đối tác
            </Tag>
          ) : null}
          {record.isNew ? (
            <Tag
              color="green"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Quán mới
            </Tag>
          ) : null}
          {record.isHot ? (
            <Tag
              color="orange"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Nổi bật
            </Tag>
          ) : null}
          {record.updateInfo && (
            <Text
              mark
              style={{ display: "block", marginTop: "6px", cursor: "pointer" }}
              onClick={() => onOpenUpdateInfoModal(record)}
            >
              Đã yêu cầu cập nhật thông tin
            </Text>
          )}
          {record.tags?.map((tag) => (
            <Tag
              key={tag._id}
              style={{
                display: "inline-block",
                marginTop: "4px",
                color: tag.color,
                backgroundColor: tag.bgColor,
              }}
            >
              {tag.text}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      width: "20%",
      render: (address) => (
        <Paragraph ellipsis={{ rows: 2, tooltip: address }}>
          {address}
        </Paragraph>
      ),
    },
    {
      title: "Tổng đơn",
      width: "10%",
      render: (record) => record.orderCount,
    },
    {
      title: "Số dư ví",
      width: "8%",
      render: (record) => priceToCurrency(record.merchant?.balance ?? 0),
    },
    {
      title: "Chiết khấu",
      width: "10%",
      align: "center",
      render: (record) =>
        record.chargeMode === "default" ? (
          <Tag color="geekblue">Mặc định</Tag>
        ) : (
          <Tag color="orange">{record.charge}%</Tag>
        ),
    },
    {
      title: "Có voucher",
      align: "center",
      width: "10%",
      render: (record) => (
        <Switch
          size="default"
          onChange={(checked) => handleTogglePromo(record._id, checked)}
          checked={record.isPromo}
        />
      ),
    },
    {
      title: "Đang mở",
      // dataIndex: "isOpen",
      align: "center",
      width: "10%",
      render: (record) => (
        <Switch
          size="default"
          onChange={(checked) => handleToggleOpen(record._id, checked)}
          checked={record.isOpen}
        />
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      width: "10%",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "15%",
      render: (record) => (
        <Actions
          record={record}
          onEditRestaurant={onOpenDetailModal}
          onEditMenu={onOpenMenuModal}
          onOpenUpdateChargeModal={onOpenUpdateChargeModal}
          onOpenStatsModal={onOpenStatsModal}
          onActive={onActiveUnactive}
          onSetupEvent={onOpenSetupEventModal}
        />
      ),
    },
  ];
  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Nhà hàng
        </Typography.Title>

        <Space size="middle">
          <Select
            allowClear
            showSearch
            style={{ width: 160 }}
            placeholder="Sắp xếp theo"
            onChange={handleSelectSort}
          >
            <Select.Option key="default" value="">
              Mặc định
            </Select.Option>
            <Select.Option key="balance_desc" value="balance_desc">
              Số dư giảm dần
            </Select.Option>
            <Select.Option key="balance_asc" value="balance_asc">
              Số dư tăng dần
            </Select.Option>
          </Select>
          <Input.Search
            placeholder="Tìm theo tên, code hoặc sđt ..."
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
            style={{ width: "280px" }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onOpenDetailModal()}
          >
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Row justify="end" align="middle">
        <Space size="middle">
          <Switch
            checkedChildren="Quán đang đóng"
            unCheckedChildren="Quán đang đóng"
            onChange={handleSearchIsClose}
          />
          <Switch
            checkedChildren="Quán đang mở"
            unCheckedChildren="Quán đang mở"
            onChange={handleSearchIsOpen}
          />
          <Switch
            checkedChildren="Quán mới"
            unCheckedChildren="Quán mới"
            onChange={handleSearchIsNew}
          />
          <Switch
            checkedChildren="Quán nổi bật"
            unCheckedChildren="Quán nổi bật"
            onChange={handleSearchIsHot}
          />
          <Switch
            checkedChildren="Quán đối tác"
            unCheckedChildren="Quán đối tác"
            onChange={handleSearchIsPartner}
          />
          <Switch
            checkedChildren="Có voucher"
            unCheckedChildren="Có voucher"
            onChange={handleSearchIsPromo}
          />
          <Switch
            checkedChildren="Có tag"
            unCheckedChildren="Có tag"
            onChange={handleSearchIsTags}
          />
          <Switch
            defaultChecked
            checkedChildren="Đang kích hoạt"
            unCheckedChildren="Hủy kích hoạt"
            onChange={handleSearchIsActive}
          />
        </Space>
      </Row>
      {totalUpdate > 0 && (
        <Row justify="end" align="middle">
          <Text
            mark
            strong
            style={{ fontSize: "18px", marginTop: "16px", cursor: "pointer" }}
            onClick={handleSearchRequestUpdate}
          >
            <Alert
              message={`Có ${totalUpdate} quán yêu cầu thay đổi thông tin`}
              type="warning"
              showIcon
            />
            {/* 🚨 Có {totalUpdate} quán yêu cầu thay đổi thông tin */}
          </Text>
        </Row>
      )}
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={restaurants}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <RestaurantDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onResetPassword={onResetPassword}
          onCancel={onCloseDetailModal}
          restaurant={selectingRestaurant}
        />
      )}
      {openMenuModal && (
        <RestaurantMenuModal
          open={openMenuModal}
          isProcessing={isProcessing}
          restaurant={selectingRestaurant}
          onCancel={onCloseMenuModal}
        />
      )}
      {openUpdateChargeModal && (
        <UpdateChargeModal
          open={openUpdateChargeModal}
          isProcessing={isProcessing}
          restaurant={selectingRestaurant}
          onSubmit={onSubmitNewCharge}
          onCancel={onCloseUpdateChargeModal}
        />
      )}
      {openSetAutoOpenModal && (
        <SetAutoOpenModal
          open={openSetAutoOpenModal}
          restaurant={selectingRestaurantId}
          onCancel={onCloseSetAutoOpenModal}
        />
      )}
      {openStatsModal && (
        <StatsModal
          open={openStatsModal}
          onClose={onCloseStatsModal}
          restaurant={selectingRestaurant}
        />
      )}
      {openUpdateInfoModal && (
        <UpdateInfoModal
          open={openUpdateInfoModal}
          onClose={onCloseUpdateInfoModal}
          onVerify={onVerifyUpdateInfo}
          onReject={onRejectUpdateInfo}
          restaurant={selectingRestaurant}
        />
      )}
      {openRejectUpdateInfoModal && (
        <RejectUpdateInfoModal
          open={openRejectUpdateInfoModal}
          onClose={onCloseRejectUpdateInfo}
          onSubmit={onSubmitRejectUpdateInfo}
        />
      )}
      {openActiveUnactiveModal && (
        <ActiveUnactiveModal
          open={openActiveUnactiveModal}
          isProcessing={isProcessing}
          onSubmit={onConfirmActiveUnactive}
          onCancel={onCancelActiveUnactive}
          restaurant={selectingRestaurant}
        />
      )}
      {openSetupEventModal && (
        <SetupEventModal
          open={openSetupEventModal}
          isProcessing={isProcessing}
          restaurant={selectingRestaurant}
          onSubmit={onSetupEvent}
          onCancel={onCloseSetupEventModal}
        />
      )}
    </>
  );
}

export default Restaurant;
