import { IssuesCloseOutlined, SwapRightOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { priceToCurrency } from "utils/common";
import { MAPBOX_TOKEN } from "utils/constants";
import { useSocket } from "utils/hooks/useSocket";
import GOKA_SOUND from "../../../assets/audio/goka.mp3";
import Actions from "./components/Actions";
import AssignModal from "./components/AsssignModal";
import ConfirmCancelModal from "./components/ConfirmCancelModal";
import OrderCostModal from "./components/CostDrawer";
import OrderDetailDrawer from "./components/DetailDrawer";
import RejectModal from "./components/RejectModal";
import TransferModal from "./components/TransferModal";
import * as orderActions from "./slice";
import ChatModal from "./components/ChatModal";
import EditHistoryModal from "./components/EditHistoryModal";

const notiSound = new Audio(GOKA_SOUND);

const PAYMENT_METHODS = {
  cod: "Tiền mặt",
  qrcode: "VietQR",
  "goka-wallet": "Ví Goka",
};

const DELIVERY_STATUS = {
  finding: { state: "Đang tìm tài xế", color: "volcano" },
  "confirm-customer": { state: "Chờ xác nhận", color: "gold" },
  "going-to-restaurant": { state: "Đang đến nhà hàng", color: "magenta" },
  "arrived-restaurant": { state: "Đã đến nhà hàng", color: "cyan" },
  "picked-up": { state: "Đã lấy hàng", color: "green" },
};

function OrderLive() {
  const dispatch = useDispatch();
  const { orders, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.newOrder);
  const { userInfo } = useSelector((state) => state.auth);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [openCostDrawer, setOpenCostDrawer] = useState(false);
  const [openRejectDrawer, setOpenRejectDrawer] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [selectingOrder, setSelectingOrder] = useState(null);
  const [directionImage, setDirectionImage] = useState("");
  const [showDirectionImage, setVisibleDirectionImage] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [openEditHistoryModal, setOpenEditHistoryModal] = useState(false);

  const [socket] = useSocket("/admin");
  useEffect(() => {
    if (!socket) return;
    const currentRegion =
      userInfo.role === "super_admin"
        ? localStorage.getItem("region")
        : userInfo.region;
    if (currentRegion) {
      socket.emit("client::join-region", currentRegion);
    }
    socket.on("server::update-new-orders", (message) => {
      toast.success(message);
      notiSound.play();
      dispatch(orderActions.fetchOrders(pagination));
      setOpenAssignModal(false);
    });
    return () => {
      socket.off("server::hello");
      socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    dispatch(orderActions.fetchOrders({ ...pagination, current: 1 }));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenCancelModal(false);
      setSelectingOrder(null);
    }
  }, [isCompleted]);

  const onVisibleDirectionImage = (record) => {
    setDirectionImage(
      `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-a+000(${
        record.routes.restaurant.long
      },${record.routes.restaurant.lat}),pin-l-b+f00(${
        record.routes.customer.long
      },${record.routes.customer.lat}),path-5+5c00c1-1(${encodeURIComponent(
        record.routes.polyline
      )})/auto/800x560?access_token=${MAPBOX_TOKEN}`
    );
    setVisibleDirectionImage(true);
  };

  const onVisibleDirectionImageChange = (value) => {
    console.log(value);
    setVisibleDirectionImage(value);
    setDirectionImage(null);
  };

  const onOpenDetailModal = (order) => {
    setSelectingOrder(order);
    setOpenDetailDrawer(true);
  };

  const onOpenCostModal = (order) => {
    setSelectingOrder(order);
    setOpenCostDrawer(true);
  };

  const onOpenRejectModal = (order) => {
    setSelectingOrder(order);
    setOpenRejectDrawer(true);
  };

  const onOpenAssignOrder = (order) => {
    setSelectingOrder(order);
    setOpenAssignModal(true);
  };

  const onOpenTransferOrder = (order) => {
    setSelectingOrder(order);
    setOpenTransferModal(true);
  };

  const onOpenChatModal = (order) => {
    setSelectingOrder(order);
    setOpenChatModal(true);
  };

  const onOpenEditHistoryModal = (order) => {
    setSelectingOrder(order);
    setOpenEditHistoryModal(true);
  };

  const onCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseCostDrawer = () => {
    setOpenCostDrawer(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseRejectDrawer = () => {
    setOpenRejectDrawer(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseTransferModal = () => {
    setOpenTransferModal(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseCancelModal = () => {
    setOpenCancelModal(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseChatModal = () => {
    setOpenChatModal(false);
    setSelectingOrder(null);
  };

  const onOpenCancelOrderModal = (order) => {
    setSelectingOrder(order);
    setOpenCancelModal(true);
  };

  const onCloseEditHistoryModal = () => {
    setOpenEditHistoryModal(false);
    setSelectingOrder(null);
  };

  const onTableChange = async (pagination) => {
    dispatch(
      orderActions.fetchOrders({
        ...pagination,
      })
    );
  };

  const onCancelOrder = (order, reason) => {
    dispatch(orderActions.cancelOrder(order._id, reason));
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Mã đơn",
      // dataIndex: "code",
      width: "8%",
      render: (record) => (
        <Typography.Text strong>
          #{record.code}
          {record.paymentMethod !== "cod" ? (
            <>
              <br />
              <Tag color="#f50">{PAYMENT_METHODS[record.paymentMethod]}</Tag>
            </>
          ) : null}
        </Typography.Text>
      ),
    },
    {
      title: "Quán",
      // dataIndex: ["routes", "restaurant"],
      width: "16%",
      render: (record) => (
        <>
          <Typography.Text strong>
            {record.routes.restaurant?.name}
          </Typography.Text>
          <br />
          <a
            href={`https://www.google.com/maps?q=${record.routes.restaurant.lat},${record.routes.restaurant.long}`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title={record.routes.restaurant?.address}>
              <Typography.Text className="whitespace-nowrap">
                {record.routes.restaurant?.address?.slice(0, 25) +
                  (record.routes.restaurant?.address?.length > 25 ? "..." : "")}
              </Typography.Text>
            </Tooltip>
          </a>
          <Button
            size="small"
            type="link"
            onClick={() => onVisibleDirectionImage(record)}
          >
            <SwapRightOutlined />
            Xem đường đi
          </Button>
          {record.rejectCount ? (
            <>
              <br />
              <Typography.Text type="warning">{`(${record.rejectCount} tài xế bỏ qua)`}</Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Khách hàng",
      width: "16%",
      render: (record) => (
        <>
          <Typography.Text strong>
            {record.routes.customer?.name}
          </Typography.Text>
          <br />
          <Typography.Text>{record.routes.customer?.phone}</Typography.Text>
          <br />
          <a
            href={`https://www.google.com/maps?q=${record.routes.customer.lat},${record.routes.customer.long}`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title={record.routes.customer?.address}>
              <Typography.Text>
                {record.routes.customer?.address?.slice(0, 25) +
                  (record.routes.customer?.address?.length > 25 ? "..." : "")}
              </Typography.Text>
            </Tooltip>
          </a>
          <Button
            size="small"
            type="link"
            onClick={() => onVisibleDirectionImage(record)}
          >
            <SwapRightOutlined />
            Xem đường đi
          </Button>
          {record.chatCount ? (
            <>
              <br />
              <Typography.Text type="warning">{`(${record.chatCount} tin nhắn)`}</Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    // {
    //   title: "Tài xế",
    //   dataIndex: "shipper",
    //   width: "12%",
    //   render: (shipper) =>
    //     shipper && (
    //       <>
    //         <Typography.Text strong>{shipper.name}</Typography.Text><br/>
    //         <Typography.Text>
    //           {shipper.phone}
    //         </Typography.Text>
    //       </>
    //     ),
    // },
    {
      title: "Trạng thái",
      render: (record) =>
        record.status === "wait-pay" ? (
          <Tag color={"purple"}>
            Chờ thanh toán - {priceToCurrency(record.total)}
          </Tag>
        ) : (
          <Tag color={DELIVERY_STATUS[record.deliveryStatus].color}>
            {DELIVERY_STATUS[record.deliveryStatus].state}
          </Tag>
        ),
    },
    // {
    //   title: "Số món",
    //   dataIndex: "dishCount",
    // },
    {
      title: "Tổng tiền",
      width: "200px",
      render: (record) => (
        <>
          <Typography.Text>
            Tiền món: {priceToCurrency(record.dishFeeActual)}
          </Typography.Text>
          <br />
          <Typography.Text>
            Tiền ship: {priceToCurrency(record.deliveryFeeActual)} (
            {record.distance}km)
          </Typography.Text>
          <br />
          <Typography.Text>
            Giảm giá: {priceToCurrency(record.discount)}
          </Typography.Text>
          <br />
          <Typography.Text>
            App nhận: {priceToCurrency(record.appReceiveActual)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            Tổng tiền: {priceToCurrency(record.total)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            Điểm nhận: {record.shipperPoint || 0} ★
          </Typography.Text>
          {record.editedCount ? (
            <>
              <br />
              <Typography.Text strong type="danger">
                <IssuesCloseOutlined /> {`Đã sửa: ${record.editedCount} lần`}
              </Typography.Text>
            </>
          ) : null}
        </>
      ),
      // render: (totalActual) => priceToCurrency(totalActual),
    },
    // {
    //   title: "Khoảng cách",
    //   dataIndex: "distance",
    //   render: (distance) => `${distance}km`,
    // },
    {
      title: "Thời gian",
      width: "180px",
      render: (record) => (
        <>
          <Typography.Text>
            Đặt đơn: {moment(record.orderedAt).format("HH:mm")}
          </Typography.Text>
          <br />
          {record.paidAt ? (
            <>
              {" "}
              <Typography.Text>
                Thanh toán: {moment(record.paidAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          <Typography.Text>
            Nhận đơn:{" "}
            {record.assignedAt
              ? moment(record.assignedAt).format("HH:mm")
              : "-"}
          </Typography.Text>
          <br />
          {record.confirmedAt ? (
            <>
              {" "}
              <Typography.Text>
                Xác nhận: {moment(record.confirmedAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          {record.arrivedAt ? (
            <>
              {" "}
              <Typography.Text>
                Đến quán: {moment(record.arrivedAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          {record.pickupAt ? (
            <>
              {" "}
              <Typography.Text>
                Lấy đơn: {moment(record.pickupAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          {record.shipper ? (
            <>
              {" "}
              <Typography.Text strong>
                Tài xế: {record.shipper.name}
              </Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "12%",
      render: (record) => (
        <Actions
          record={record}
          onViewDetail={onOpenDetailModal}
          onViewCost={onOpenCostModal}
          onViewReject={onOpenRejectModal}
          onAssignOrder={onOpenAssignOrder}
          onTransferOrder={onOpenTransferOrder}
          onCancel={onOpenCancelOrderModal}
          onViewChat={onOpenChatModal}
          onViewEditHistory={onOpenEditHistoryModal}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Đơn hàng đang xử lý
        </Typography.Title>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={orders}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailDrawer && (
        <OrderDetailDrawer
          open={openDetailDrawer}
          onClose={onCloseDetailDrawer}
          order={selectingOrder}
          onViewCost={() => {
            setOpenDetailDrawer(false);
            setOpenCostDrawer(true);
          }}
        />
      )}
      {openCostDrawer && (
        <OrderCostModal
          open={openCostDrawer}
          onClose={onCloseCostDrawer}
          order={selectingOrder}
        />
      )}
      {openRejectDrawer && (
        <RejectModal
          open={openRejectDrawer}
          onClose={onCloseRejectDrawer}
          order={selectingOrder}
        />
      )}
      {openCancelModal && (
        <ConfirmCancelModal
          open={openCancelModal}
          onClose={onCloseCancelModal}
          order={selectingOrder}
          onSubmit={onCancelOrder}
          loading={isProcessing}
        />
      )}
      {openAssignModal && (
        <AssignModal
          open={openAssignModal}
          onClose={onCloseAssignModal}
          order={selectingOrder}
          loading={isProcessing}
        />
      )}
      {openTransferModal && (
        <TransferModal
          open={openTransferModal}
          onClose={onCloseTransferModal}
          order={selectingOrder}
          loading={isProcessing}
        />
      )}
      {openChatModal && (
        <ChatModal
          open={openChatModal}
          onClose={onCloseChatModal}
          order={selectingOrder}
        />
      )}
      {openEditHistoryModal && (
        <EditHistoryModal
          open={openEditHistoryModal}
          onClose={onCloseEditHistoryModal}
          order={selectingOrder}
        />
      )}
      <Image
        width={200}
        style={{
          display: "none",
        }}
        preview={{
          visible: showDirectionImage,
          src: directionImage,
          destroyOnClose: true,
          onVisibleChange: (value) => onVisibleDirectionImageChange(value),
        }}
      />
    </>
  );
}

export default OrderLive;
