import {
  Divider,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./components/Actions";
import ReviewDetailModal from "./components/DetailModal";
import HideReviewModal from "./components/HideReviewModal";
import * as reviewActions from "./slice";
import { StarOutlined } from "@ant-design/icons";
import moment from "moment";

function Review() {
  const dispatch = useDispatch();
  const { reviews, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.shipperReview);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openHideReviewModal, setOpenHideReviewModal] = useState(false);
  const [selectingReview, setSelectingReview] = useState(null);
  const [searchStar, setSearchStar] = useState("");
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(reviewActions.fetchReviews(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setOpenHideReviewModal(false);
      setSelectingReview(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (review = null) => {
    setSelectingReview(review);
    setOpenDetailModal(true);
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingReview(null);
    dispatch(reviewActions.setIsProcessing(false));
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = () => {
    dispatch(
      reviewActions.fetchReviews({
        ...pagination,
        star: searchStar,
        name: searchName,
        current: 1,
      })
    );
  };

  const onSelectStarSearch = (star) => {
    setSearchStar(star);
    dispatch(
      reviewActions.fetchReviews({
        ...pagination,
        star: star,
        name: searchName,
        current: 1,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      reviewActions.fetchReviews({
        ...pagination,
        star: searchStar,
        name: searchName,
      })
    );
  };

  const onHide = (review) => {
    setSelectingReview(review);
    setOpenHideReviewModal(true);
  };

  const onConfirmHide = (review, hiddenReason) => {
    dispatch(reviewActions.updateReviewHiddenStatus(review, hiddenReason));
  };

  const onCancelHide = () => {
    setOpenHideReviewModal(false);
    setSelectingReview(null);
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Tác giả",
      dataIndex: "author",
      width: "14%",
    },
    {
      title: "Rate",
      dataIndex: "star",
      width: "5%",
      render: (star) => {
        return (
          <>
            <StarOutlined /> {star}
          </>
        );
      },
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      // width: "20%",
      render: (content) => (
        <div style={{ whiteSpace: "pre-line" }}>
          <Typography.Paragraph
            ellipsis={{
              rows: 4,
              expandable: true,
              symbol: "Xem thêm",
            }}
          >
            {content}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: "Tài xế",
      dataIndex: ["shipper", "name"],
      width: "15%",
    },
    {
      title: "Trạng thái",
      // dataIndex: "isHidden",
      align: "center",
      width: "10%",
      render: (record) =>
        record.isHidden ? (
          <Tooltip title={`${record.hiddenReason}`}>
            <Tag color="#f44336">Ẩn</Tag>
          </Tooltip>
        ) : (
          <Tag color="#4caf50">Hiện</Tag>
        ),
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      width: "10%",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "10%",
      render: (record) => (
        <Actions
          record={record}
          onViewDetail={onOpenDetailModal}
          onHide={onHide}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Đánh giá tài xế
        </Typography.Title>
        <Space size="middle">
          <Select
            allowClear
            showSearch
            style={{ width: 200 }}
            placeholder="Tìm theo rate"
            onChange={onSelectStarSearch}
          >
            <Select.Option key="all" value="">
              Tất cả
            </Select.Option>
            <Select.Option key={1} value={5}>
              5 sao
            </Select.Option>
            <Select.Option key={1} value={4}>
              4 sao
            </Select.Option>
            <Select.Option key={1} value={3}>
              3 sao
            </Select.Option>
            <Select.Option key={1} value={2}>
              2 sao
            </Select.Option>
            <Select.Option key={1} value={1}>
              1 sao
            </Select.Option>
          </Select>
          <Input.Search
            placeholder="Tìm theo ID tài xế"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={reviews}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <ReviewDetailModal
          open={openDetailModal}
          onCancel={onCancel}
          review={selectingReview}
        />
      )}
      {openHideReviewModal && (
        <HideReviewModal
          open={openHideReviewModal}
          isProcessing={isProcessing}
          onSubmit={onConfirmHide}
          onCancel={onCancelHide}
          review={selectingReview}
        />
      )}
    </>
  );
}

export default Review;
