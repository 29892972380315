import { Avatar, Button, Dropdown, Layout, Space } from "antd";
import {
  DownOutlined,
  EnvironmentOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "features/auth/slice";
import { fetchRegions } from "features/region/slice";
import ChangePasswordModal from "./components/ChangePasswordModal";

export default function DashboardHeader() {
  const dispatch = useDispatch();

  const { regions } = useSelector((state) => state.region);
  const { userInfo, isProcessing } = useSelector((state) => state.auth);
  const [region, setRegion] = useState("");
  const [openChangePassword, setOpenChangePassword] = useState(false);

  useEffect(() => {
    if (userInfo?.role === "super_admin" && regions.length === 0) {
      dispatch(fetchRegions());
    }
  }, []);

  useEffect(() => {
    if (userInfo?.role === "super_admin" && regions.length > 0) {
      const selectedRegion = localStorage.getItem("region");
      if (selectedRegion) {
        const region = regions.find((region) => region._id === selectedRegion);
        if (region) {
          setRegion(region.name);
          return;
        }
      }
      const defaultRegion = regions.find((region) => region.isDefault);
      if (defaultRegion) {
        setRegion(defaultRegion.name);
      }
      localStorage.setItem("region", defaultRegion._id);
    }
  }, [regions]);

  const KEY_ACTIONS = {
    SIGN_OUT: "sign-out",
    CHANGE_PASSWORD: "change-password",
  };

  const onLogout = () => {
    dispatch(authActions.logout());
  };

  const onChangePassword = () => {
    setOpenChangePassword(true);
  };

  const onCancelChangePassword = () => {
    setOpenChangePassword(false);
  };

  const onSaveNewPassword = ({ oldPassword, newPassword }) => {
    dispatch(authActions.changePassword(oldPassword, newPassword));
  };

  const onClick = ({ key }) => {
    switch (key) {
      case KEY_ACTIONS.SIGN_OUT:
        onLogout();
        break;
      case KEY_ACTIONS.CHANGE_PASSWORD:
        onChangePassword();
        break;
      default:
        break;
    }
  };

  const onClickRegion = ({ key }) => {
    localStorage.setItem("region", key);
    const region = regions.find((region) => region._id === key);
    setRegion(region.name);
    location.reload();
  };

  const items = [
    {
      key: KEY_ACTIONS.CHANGE_PASSWORD,
      label: "Đổi mật khẩu",
      icon: <LockOutlined />,
    },
    {
      key: KEY_ACTIONS.SIGN_OUT,
      label: "Đăng xuất",
      icon: <LogoutOutlined />,
    },
  ];

  const regionItems = regions.map((region) => ({
    key: region._id,
    label: region.name,
    icon: <EnvironmentOutlined />,
  }));

  return (
    <>
      <Layout.Header className="!sticky top-0 w-full !bg-white flex justify-between items-center z-10">
        {region ? (
          userInfo?.role === "super_admin" ? (
            <Dropdown
              menu={{ items: regionItems, onClick: onClickRegion }}
              icon={<DownOutlined />}
            >
              <Button type="primary">
                <Space>
                  <EnvironmentOutlined />
                  {region}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          ) : (
            <Button type="primary">
              <Space>
                <EnvironmentOutlined />
                {region}
              </Space>
            </Button>
          )
        ) : (
          <div></div>
        )}
        <div style={{ flexShrink: 0, cursor: "pointer" }}>
          <Dropdown menu={{ items, onClick: onClick }}>
            <Space>
              <div className="flex items-center gap-x-2 cur sor-pointer">
                <span>
                  Xin chào, <span className="font-bold">{userInfo?.name}</span>
                </span>
                <Avatar className="bg-green-500" icon={<UserOutlined />} />
              </div>
            </Space>
          </Dropdown>
        </div>
      </Layout.Header>
      {openChangePassword && (
        <ChangePasswordModal
          open={openChangePassword}
          isProcessing={isProcessing}
          onCancel={onCancelChangePassword}
          onSubmit={onSaveNewPassword}
        />
      )}
    </>
  );
}
