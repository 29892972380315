import axiosClient from "utils/api/axios-client";

const messageApi = {
  getAll: (params) => {
    const url = "/api/v1/messages";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/messages/${id}`;
    return axiosClient.get(url);
  },
  create: (message) => {
    const url = `/api/v1/messages/`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, message, config);
  },
  update: (id, message) => {
    const url = `/api/v1/messages/${id}`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.put(url, message, config);
  },
  delete: (id) => {
    const url = `/api/v1/messages/${id}`;
    return axiosClient.delete(url);
  },
};

export default messageApi;
