import { Form, Input, Modal, Rate, Space, Tag } from "antd";

function ReviewDetailModal(props) {
  const [form] = Form.useForm();
  const { open, onCancel, review } = props;

  return (
    <Modal
      title="Đánh giá shipper"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      okButtonProps={{ className: "hidden" }}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form} initialValues={review}>
        <Form.Item name={["order", "code"]} label="Đơn hàng">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="author" label="Tác giả">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="star" label="Đánh giá">
          <Rate disabled />
        </Form.Item>
        <Form.Item name="content" label="Nội dung">
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} readOnly />
        </Form.Item>
        <Form.Item name={["shipper", "name"]} label="Shipper">
          <Input readOnly />
        </Form.Item>
        <Space direction="vertical">
          <label>Danh sách món ăn</label>
          <Space wrap={true}>
            {review.dishes.map((dish, index) => (
              <Tag key={index}>{dish}</Tag>
            ))}
          </Space>
        </Space>
      </Form>
    </Modal>
  );
}

export default ReviewDetailModal;
