import { configureStore } from "@reduxjs/toolkit";
import auth from "features/auth/slice";
import account from "features/account/slice";
import category from "features/category/slice";
import shipper from "features/shipper/slice";
import restaurant from "features/restaurant/slice";
import voucher from "features/voucher/slice";
import campaign from "features/campaign/slice";
import collection from "features/collection/slice";
import ad from "features/ad/list/slice";
import adType from "features/ad/type/slice";
import bannerUser from "features/banner/app-user/slice";
import bannerMerchant from "features/banner/app-merchant/slice";
import notification from "features/notification/slice";
import message from "features/message/slice";
import user from "features/user/slice";
import order from "features/order/history/slice";
import newOrder from "features/order/new/slice";
import restaurantReview from "features/review/restaurant/slice";
import shipperReview from "features/review/shipper/slice";
import contact from "features/contact/slice";
import config from "features/setup/slice";
import walletRequest from "features/wallet-request/slice";
import mapService from "features/super-setup/map-service/slice";
import region from "features/region/slice";
import superAccount from "features/super-account/slice";
import restaurantLabel from "features/restaurant-label/slice";
import superWalletRequest from "features/super-wallet-request/slice";
import walletTransaction from "features/wallet/slice";

export const store = configureStore({
  reducer: {
    auth,
    account,
    category,
    shipper,
    restaurant,
    voucher,
    campaign,
    collection,
    ad,
    adType,
    banner: bannerUser,
    bannerMerchant,
    notification,
    restaurantReview,
    shipperReview,
    user,
    order,
    newOrder,
    message,
    contact,
    config,
    walletRequest,
    mapService,
    region,
    superAccount,
    restaurantLabel,
    superWalletRequest,
    walletTransaction,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
