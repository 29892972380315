import { createSlice } from "@reduxjs/toolkit";
import collectionApi from "./api";
import { toast } from "react-toastify";

const initialState = {
  collections: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setCollections: (state, action) => {
      state.collections = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    addCollection: (state, action) => {
      state.isCompleted = true;
      state.collections.unshift(action.payload);
    },
    editCollection: (state, action) => {
      state.isCompleted = true;
      state.collections = state.collections.map((collection) =>
        collection._id === action.payload._id ? action.payload : collection
      );
    },
    removeCollection: (state, action) => {
      state.isCompleted = true;
      state.collections = state.collections.filter(
        (collection) => collection._id !== action.payload
      );
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setCollections,
  setPagination,
  addCollection,
  editCollection,
  removeCollection,
} = collectionSlice.actions;

export const fetchCollections = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await collectionApi.getAll(params);
      dispatch(setCollections(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const createCollection = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await collectionApi.create(formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addCollection(data));
        toast.success("Thêm chiến dịch đồ ăn mới thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const updateCollection = (collection, formData) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await collectionApi.update(collection._id, formData);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editCollection(data));
        toast.success("Cập nhật chiến dịch đồ ăn thành công!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const deleteCollection = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await collectionApi.delete(id);
      if (data.success) {
        dispatch(removeCollection(id));
        toast.success("Xóa chiến dịch đồ ăn thành công!");
      } else {
        toast.error("Lỗi hệ thống, xóa chiến dịch đồ ăn thất bại!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default collectionSlice.reducer;
