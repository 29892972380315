import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({ record, onEdit, onDelete }) {
  const KEY_ACTIONS = {
    EDIT_BANNER: "edit-banner",
    DELETE_BANNER: "delete-banner",
  };

  const onClick = ({ key }) => {
    switch (key) {
      case KEY_ACTIONS.EDIT_BANNER:
        onEdit(record);
        break;
      case KEY_ACTIONS.DELETE_BANNER:
        onDelete(record);
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      key: KEY_ACTIONS.EDIT_BANNER,
      label: "Sửa",
      icon: <EditOutlined />,
    },
    {
      key: KEY_ACTIONS.DELETE_BANNER,
      danger: true,
      label: "Xóa",
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <Dropdown menu={{ items: actions, onClick }}>
      <Button>
        <Space>
          Thao tác
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
