import { Form, Input, Modal } from "antd";
import { useState } from "react";

export default function RejectVerifyModal(props) {
  const { open, isProcessing, onSubmit, onClose } = props;
  const [rejectReason, setRejectReason] = useState("");

  const onChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
  };

  return (
    <Modal
      title={`Xác nhận từ chối duyệt voucher`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(rejectReason)}
      onCancel={onClose}
      confirmLoading={isProcessing}
    >
      <Form layout="vertical">
        <Form.Item label="Lý do từ chối">
          <Input.TextArea
            onChange={onChangeRejectReason}
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
