import { Form, Input, Typography } from "antd";
const { Paragraph } = Typography;

export default function TabAccount({ user }) {
  return (
    <>
      <Form.Item name={["banking", "bankName"]} label="Ngân hàng">
        <Input readOnly />
      </Form.Item>
      <Form.Item name={["banking", "accountNumber"]} label="Số tài khoản">
        <Input readOnly />
      </Form.Item>
      <Form.Item name={["banking", "accountName"]} label="Chủ tài khoản">
        <Input readOnly />
      </Form.Item>
    </>
  );
}
