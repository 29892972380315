import axiosClient from "utils/api/axios-client";

export default {
  getWalletBalance: () => {
    const url = "/api/v1/wallet/balance";
    return axiosClient.get(url);
  },
  getWalletTransactions: (params) => {
    const url = "/api/v1/wallet/transactions";
    return axiosClient.get(url, { params });
  },
  createWalletWithdrawRequest: (request) => {
    const url = "/api/v1/wallet/withdraw";
    return axiosClient.post(url, request);
  },
};
