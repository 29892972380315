import { Form, Input, Modal, Space, Tag } from "antd";
import { useState } from "react";
import { classNames } from "utils/common";

export default function UpdateChargeModal({
  open,
  onSubmit,
  onCancel,
  isProcessing,
  restaurant,
}) {
  const [chargeInfo, setChargeInfo] = useState({
    chargeMode: restaurant?.chargeMode || 'default',
    charge: restaurant?.charge || -1,
  });

  const CHARGE_MODE_VALUE = {
    DEFAULT: "default",
    CUSTOM: "custom",
  };

  const CHARGE_MODES = [
    {
      label: "Mặc định",
      value: CHARGE_MODE_VALUE.DEFAULT,
    },
    {
      label: "Tùy chỉnh",
      value: CHARGE_MODE_VALUE.CUSTOM,
    },
  ];

  const onUpdateChargeInfo = (property, value) => {
    setChargeInfo((c) => ({ ...c, [property]: value }));
  };

  return (
    <>
      <Modal
        title="Sửa chiết khấu"
        okText="Lưu"
        cancelText="Huỷ"
        centered
        open={open}
        onOk={() => onSubmit(restaurant, chargeInfo)}
        onCancel={onCancel}
        confirmLoading={isProcessing}
      >
        <Form>
          <Form.Item label="Loại chiết khấu">
            <Space size="small">
              {CHARGE_MODES.map((mode, idx) => (
                <Tag
                  key={idx}
                  className={classNames(
                    "select-none cursor-pointer",
                    chargeInfo.chargeMode === mode.value &&
                      "bg-success text-white"
                  )}
                  onClick={() => onUpdateChargeInfo("chargeMode", mode.value)}
                >
                  {mode.label}
                </Tag>
              ))}
            </Space>
          </Form.Item>
          {chargeInfo.chargeMode === CHARGE_MODE_VALUE.CUSTOM && (
            <Form.Item label="Tỷ lệ chiết khấu">
              <Input
                type="number"
                suffix="%"
                onChange={(e) => onUpdateChargeInfo("charge", e.target.value)}
                value={chargeInfo.charge}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}
