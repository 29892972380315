import {
  DashboardOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HourglassOutlined,
  LockOutlined,
  StockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { ACCOUNT_ROLES } from "utils/constants";

export default function Actions({
  record,
  onEdit,
  onDelete,
  onBlock,
  onOpenPerformanceModal,
  onOpenTrackingModal,
  onOpenEditWeeklyPointModal,
}) {
  const { userInfo } = useSelector((state) => state.auth);

  const KEY_ACTIONS = {
    EDIT: "edit",
    BLOCK: "block",
    VIEW_PERFORMANCE: "view_performance",
    VIEW_TRACKING: "view_tracking",
    EDIT_WEEKLY_POINT: "edit_weekly_point",
    DELETE: "delete",
  };

  const onClick = ({ key }) => {
    switch (key) {
      case KEY_ACTIONS.EDIT:
        onEdit(record);
        break;
      case KEY_ACTIONS.BLOCK:
        onBlock(record);
        break;
      case KEY_ACTIONS.VIEW_PERFORMANCE:
        onOpenPerformanceModal(record);
        break;
      case KEY_ACTIONS.VIEW_TRACKING:
        onOpenTrackingModal(record);
        break;
      case KEY_ACTIONS.EDIT_WEEKLY_POINT:
        onOpenEditWeeklyPointModal(record);
        break;
      case KEY_ACTIONS.DELETE:
        onDelete(record);
        break;
      default:
        break;
    }
  };

  const actions = {
    super_admin: [
      {
        key: KEY_ACTIONS.EDIT,
        label: "Sửa",
        icon: <EditOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_PERFORMANCE,
        label: "Xem hiệu suất",
        icon: <DashboardOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_TRACKING,
        label: "Lịch sử Bật/Tắt",
        icon: <HourglassOutlined />,
      },
      {
        key: KEY_ACTIONS.EDIT_WEEKLY_POINT,
        label: "Sửa điểm hiệu suất",
        icon: <StockOutlined />,
      },
      {
        key: KEY_ACTIONS.BLOCK,
        label: `${record.blocked ? "Bỏ khóa" : "Khóa"}`,
        icon: record.blocked ? <UnlockOutlined /> : <LockOutlined />,
        danger: !record.blocked,
      },
      {
        key: KEY_ACTIONS.DELETE,
        disabled: ![ACCOUNT_ROLES.SUPER_ADMIN, ACCOUNT_ROLES.ADMIN].includes(
          userInfo.role
        ),
        danger: true,
        label: "Xóa",
        icon: <DeleteOutlined />,
      },
    ],
    customer_service: [
      {
        key: KEY_ACTIONS.EDIT,
        label: "Sửa",
        icon: <EditOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_PERFORMANCE,
        label: "Xem hiệu suất",
        icon: <DashboardOutlined />,
      },
      {
        key: KEY_ACTIONS.BLOCK,
        label: `${record.blocked ? "Bỏ khóa" : "Khóa"}`,
        icon: record.blocked ? <UnlockOutlined /> : <LockOutlined />,
        danger: !record.blocked,
      },
    ],
  };

  actions.admin = actions.super_admin;

  return (
    <Dropdown menu={{ items: actions[userInfo.role] || [], onClick }}>
      <Button>
        <Space>
          Thao tác
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
