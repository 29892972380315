import {
  PlusOutlined,
  RedoOutlined,
  StarOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPhotoUrl, priceToCurrency } from "utils/common";
import { ACCOUNT_ROLES } from "utils/constants";
import * as shipperActions from "../slice";
import Actions from "./components/Actions";
import BlockModal from "./components/BlockModal";
import ShipperDetailModal from "./components/DetailModal";
import EditWeeklyPointModal from "./components/EditWeeklyPointModal";
import PerformanceModal from "./components/PerformanceModal";
import TrackingModal from "./components/TrackingModal";

function Shipper() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { shippers, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.shipper);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingShipper, setSelectingShipper] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [openPerformanceModal, setOpenPerformanceModal] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openEditWeeklyPointModal, setOpenEditWeeklyPointModal] =
    useState(false);

  useEffect(() => {
    dispatch(shipperActions.fetchShippers(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setOpenBlockModal(false);
      setOpenEditWeeklyPointModal(false);
      setSelectingShipper(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (shipper = null) => {
    setSelectingShipper(shipper);
    setOpenDetailModal(true);
  };

  const onResetWeekyPoint = () => {
    Modal.confirm({
      title: "Xác nhận reset điểm hiệu suất",
      content:
        "Bạn có chắc chắn muốn reset điểm hiệu suất của tất cả tài xế về 0?",
      onOk: () => dispatch(shipperActions.resetWeekyPointShipper()),
      centered: true,
    });
  };

  const onOpenPerformanceModal = (shipper = null) => {
    setSelectingShipper(shipper);
    setOpenPerformanceModal(true);
  };

  const onClosePerformanceModal = () => {
    setOpenPerformanceModal(false);
    setSelectingShipper(null);
    dispatch(shipperActions.setIsProcessing(false));
  };

  const onOpenTrackingModal = (shipper = null) => {
    setSelectingShipper(shipper);
    setOpenTrackingModal(true);
  };

  const onCloseTrackingModal = () => {
    setOpenTrackingModal(false);
    setSelectingShipper(null);
    dispatch(shipperActions.setIsProcessing(false));
  };

  const onDelete = (shipper) => {
    Modal.confirm({
      title: `Xác nhận xoá tài xế`,
      content: `Bạn có chắc chắn muốn xoá tài xế này?`,
      onOk: () => dispatch(shipperActions.deleteShipper(shipper._id)),
      centered: true,
    });
  };

  const onSubmit = (shipper, formData) => {
    if (shipper._id) {
      dispatch(shipperActions.updateShipper(shipper, formData));
    } else {
      dispatch(shipperActions.createShipper(formData));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingShipper(null);
    dispatch(shipperActions.setIsProcessing(false));
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = () => {
    dispatch(
      shipperActions.fetchShippers({
        ...pagination,
        name: searchName,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      shipperActions.fetchShippers({
        ...pagination,
        name: searchName,
      })
    );
  };

  const onSetGetFreePick = (_id, getFreePick) => {
    dispatch(shipperActions.updateGetPreePickShipper(_id, getFreePick));
  };

  const handleToggleFreePick = (id, getFreePick) => {
    Modal.confirm({
      title: `Xác nhận ${getFreePick ? "bật" : "tắt"} nhận đơn Freepick?`,
      content: `Bạn có chắc muốn ${
        getFreePick ? "bật" : "tắt"
      } chế độ nhận đơn Freepick cho tài xế này?`,
      onOk: () => onSetGetFreePick(id, getFreePick),
      centered: true,
    });
  };

  const onBlock = (shipper) => {
    setSelectingShipper(shipper);
    setOpenBlockModal(true);
  };

  const onConfirmBlock = (shipper, blockData) => {
    dispatch(shipperActions.blockShipper(shipper, blockData));
    // setOpenBlockModal(false);
    // setSelectingShipper(null);
  };

  const onCancelBlock = () => {
    setOpenBlockModal(false);
    setSelectingShipper(null);
  };

  const onOpenEditWeeklyPointModal = (shipper) => {
    setSelectingShipper(shipper);
    setOpenEditWeeklyPointModal(true);
  };

  const onCloseEditWeeklyPointModal = () => {
    setOpenEditWeeklyPointModal(false);
    setSelectingShipper(null);
  };

  const onSubmitEditWeeklyPoint = (shipper, data) => {
    if (!data.reason) return toast.error("Vui lòng nhập lý do thay đổi điểm");

    if (!data.value) return toast.error("Vui lòng nhập điểm thay đổi > 0");

    if (shipper._id) {
      dispatch(shipperActions.editWeeklyPointShipper(shipper, data));
    }
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Tên tài xế",
      render: (text, record) => (
        <div className="flex items-center gap-x-4">
          <Badge dot={true} status={record.isReady ? "success" : "default"}>
            {record.avatar ? (
              <Image
                alt="Avatar of shipper"
                src={getPhotoUrl(record.avatar?.path || null)}
                width="50px"
                height="50px"
                rootClassName="rounded-full overflow-hidden"
              />
            ) : (
              <Avatar
                className="select-none"
                alt="Avatar of user"
                size={50}
                style={{ backgroundColor: "#FFD22F" }}
              >
                {record.name[0].toUpperCase()}
              </Avatar>
            )}
          </Badge>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography.Text strong>{record.name}</Typography.Text>
            <Typography.Text>{record.phone}</Typography.Text>
            {record.blocked ? (
              <Tooltip title={`Lý do: ${record.blockedReason}`} color="red">
                <Tag icon={<StopOutlined />} color="error">
                  Bị khóa đến{" "}
                  {moment(record.blockedTo).format("HH:mm DD/MM/YY")}
                </Tag>
              </Tooltip>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Đánh giá",
      dataIndex: "rating",
      align: "center",
      render: (data) => (
        <>
          <StarOutlined /> {data}
        </>
      ),
    },
    {
      title: "Độ ưu tiên",
      dataIndex: "priority",
      align: "center",
    },
    {
      title: "Điểm hiệu suất",
      dataIndex: "weeklyPoint",
      align: "center",
      render: (data) => data ?? 0,
    },
    // {
    //   title: "Chỉ số",
    //   dataIndex: "score",
    // },
    {
      title: "Đơn hôm nay",
      dataIndex: "orderCountToday",
      align: "center",
    },
    {
      title: "Số dư ví",
      dataIndex: "balance",
      render: (data) => priceToCurrency(data),
    },
    // {
    //   title: "Nhận đơn",
    //   dataIndex: "isReady",
    //   align: "center",
    //   render: (isReady) =>
    //     isReady ? (
    //       <Tag color="#4caf50">Sẵn sàng</Tag>
    //     ) : (
    //       <Tag color="#f44336">Không nhận</Tag>
    //     ),
    // },
    {
      title: "FreePick",
      align: "center",
      width: "10%",
      render: (record) => (
        <Switch
          size="default"
          onChange={(checked) => handleToggleFreePick(record._id, checked)}
          checked={record.getFreePick}
        />
      ),
    },
    {
      title: "Nhận đơn",
      // dataIndex: "isOpen",
      align: "center",
      width: "10%",
      render: (record) => <Switch size="default" checked={record.isReady} />,
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onDelete={onDelete}
          onBlock={onBlock}
          onOpenPerformanceModal={onOpenPerformanceModal}
          onOpenTrackingModal={onOpenTrackingModal}
          onOpenEditWeeklyPointModal={onOpenEditWeeklyPointModal}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Tài xế
        </Typography.Title>
        <Space size="middle">
          <Input.Search
            placeholder="Tìm theo tên"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
          {(userInfo.role === ACCOUNT_ROLES.SUPER_ADMIN ||
            userInfo.role === ACCOUNT_ROLES.ADMIN) && (
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => onOpenDetailModal()}
              >
                Thêm mới
              </Button>
              <Button
                type="primary"
                danger
                icon={<RedoOutlined />}
                loading={isProcessing}
                onClick={() => onResetWeekyPoint()}
              >
                Reset điểm hiệu suất
              </Button>
            </>
          )}
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={shippers}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <ShipperDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          shipper={selectingShipper}
        />
      )}
      {openPerformanceModal && (
        <PerformanceModal
          open={openPerformanceModal}
          onClose={onClosePerformanceModal}
          shipper={selectingShipper}
        />
      )}

      {openBlockModal && (
        <BlockModal
          open={openBlockModal}
          isProcessing={isProcessing}
          onSubmit={onConfirmBlock}
          onCancel={onCancelBlock}
          shipper={selectingShipper}
        />
      )}

      {openEditWeeklyPointModal && (
        <EditWeeklyPointModal
          open={openEditWeeklyPointModal}
          onSubmit={onSubmitEditWeeklyPoint}
          onClose={onCloseEditWeeklyPointModal}
          shipper={selectingShipper}
          isProcessing={isProcessing}
        />
      )}

      {openTrackingModal && (
        <TrackingModal
          open={openTrackingModal}
          onClose={onCloseTrackingModal}
          shipper={selectingShipper}
        />
      )}
    </>
  );
}

export default Shipper;
