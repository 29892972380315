import {
  IssuesCloseOutlined,
  SearchOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import useViewDetail from "hooks/useViewDetail";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import { DELIVERY_STATUS, MAPBOX_TOKEN } from "utils/constants";
import Actions from "./components/Actions";
import ChatModal from "./components/ChatModal";
import ConfirmCancelModal from "./components/ConfirmCancelModal";
import OrderCostModal from "./components/CostDrawer";
import OrderDetailDrawer from "./components/DetailDrawer";
import OrderEditDrawer from "./components/EditDrawer";
import FastEditModal from "./components/FastEditModal";
import RejectModal from "./components/RejectModal";
import EditHistoryModal from "./components/EditHistoryModal";
import * as orderActions from "./slice";

const PAYMENT_METHODS = {
  cod: "Tiền mặt",
  qrcode: "VietQR",
  "goka-wallet": "Ví Goka",
};

function Order() {
  const dispatch = useDispatch();
  const { orders, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.newOrder);
  const [searchValue, setSearchValue] = useState({
    code: "",
    deliveryStatus: "",
    customer: "",
    paymentMethod: "",
  });
  const [searchState, setSearchState] = useState({
    code: "",
    deliveryStatus: "",
    customer: "",
    paymentMethod: "",
  });
  const [directionImage, setDirectionImage] = useState("");
  const [showDirectionImage, setVisibleDirectionImage] = useState(false);

  const onDisableOrderProcessing = () => {
    dispatch(orderActions.setIsProcessing(false));
  };

  const detailDrawer = useViewDetail(onDisableOrderProcessing);
  const costModal = useViewDetail(onDisableOrderProcessing);
  const rejectModal = useViewDetail(onDisableOrderProcessing);
  const cancelModal = useViewDetail(onDisableOrderProcessing);
  const editDrawer = useViewDetail(onDisableOrderProcessing);
  const fastEditModal = useViewDetail(onDisableOrderProcessing);
  const chatModal = useViewDetail(onDisableOrderProcessing);
  const editHistoryModal = useViewDetail(onDisableOrderProcessing);

  useEffect(() => {
    dispatch(orderActions.fetchOrders(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      cancelModal.onClose();
    }
  }, [isCompleted]);

  const onVisibleDirectionImage = (record) => {
    setDirectionImage(
      `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-a+000(${
        record.routes.restaurant.long
      },${record.routes.restaurant.lat}),pin-l-b+f00(${
        record.routes.customer.long
      },${record.routes.customer.lat}),path-5+5c00c1-1(${encodeURIComponent(
        record.routes.polyline
      )})/auto/800x560?access_token=${MAPBOX_TOKEN}`
    );
    setVisibleDirectionImage(true);
  };

  const onVisibleDirectionImageChange = (value) => {
    setVisibleDirectionImage(value);
    setDirectionImage(null);
  };

  const onChangeSearchValue = (property, value, refresh) => {
    setSearchValue((prev) => ({ ...prev, [property]: value }));
    if (refresh) {
      setSearchState((prev) => ({ ...prev, [property]: value }));
      dispatch(
        orderActions.fetchOrders({
          ...pagination,
          ...searchState,
          [property]: value,
        })
      );
    }
  };

  const onSearch = () => {
    setSearchState(searchValue);
    dispatch(
      orderActions.fetchOrders({
        ...pagination,
        ...searchValue,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      orderActions.fetchOrders({
        ...pagination,
        ...searchState,
      })
    );
  };

  const onCancelOrder = (order, reason) => {
    dispatch(orderActions.cancelOrder(order._id, reason));
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (_, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Mã đơn",
      // dataIndex: "code",
      width: "8%",
      render: (record) => (
        <Typography.Text strong>
          #{record.code}
          {record.paymentMethod !== "cod" ? (
            <>
              <br />
              <Tag color="#f50">{PAYMENT_METHODS[record.paymentMethod]}</Tag>
            </>
          ) : null}
        </Typography.Text>
      ),
    },
    {
      title: "Quán",
      width: "16%",
      render: (record) => (
        <>
          <Typography.Text strong>
            {record.routes.restaurant?.name}
          </Typography.Text>
          <br />
          <a
            href={`https://www.google.com/maps?q=${record.routes.restaurant.lat},${record.routes.restaurant.long}`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title={record.routes.restaurant?.address}>
              <Typography.Text className="whitespace-nowrap">
                {record.routes.restaurant?.address?.slice(0, 25) +
                  (record.routes.restaurant?.address?.length > 25 ? "..." : "")}
              </Typography.Text>
            </Tooltip>
          </a>
          <Button
            size="small"
            type="link"
            onClick={() => onVisibleDirectionImage(record)}
          >
            <SwapRightOutlined />
            Xem đường đi
          </Button>
          {record.rejectCount ? (
            <>
              <br />
              <Typography.Text type="warning">{`(${record.rejectCount} tài xế bỏ qua)`}</Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Khách hàng",
      // dataIndex: ["routes", "customer"],
      width: "16%",
      render: (record) => (
        <>
          <Typography.Text strong>
            {record.routes.customer?.name}
          </Typography.Text>
          <br />
          <Typography.Text>{record.routes.customer?.phone}</Typography.Text>
          <br />
          <a
            href={`https://www.google.com/maps?q=${record.routes.customer.lat},${record.routes.customer.long}`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title={record.routes.customer?.address}>
              <Typography.Text>
                {record.routes.customer?.address?.slice(0, 25) +
                  (record.routes.customer?.address?.length > 25 ? "..." : "")}
              </Typography.Text>
            </Tooltip>
          </a>
          <Button
            size="small"
            type="link"
            onClick={() => onVisibleDirectionImage(record)}
          >
            <SwapRightOutlined />
            Xem đường đi
          </Button>
          {record.chatCount ? (
            <>
              <br />
              <Typography.Text type="warning">{`(${record.chatCount} tin nhắn)`}</Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Trạng thái",
      render: (record) =>
        record.status === "wait-pay" ? (
          <Tag color={"purple"}>
            Chờ thanh toán - {priceToCurrency(record.total)}
          </Tag>
        ) : (
          <Tag color={DELIVERY_STATUS[record.deliveryStatus].color}>
            {DELIVERY_STATUS[record.deliveryStatus].state}
          </Tag>
        ),
    },
    {
      title: "Tổng tiền",
      width: "200px",
      render: (record) => (
        <>
          <Typography.Text>
            Tiền món: {priceToCurrency(record.dishFeeActual)}
          </Typography.Text>
          <br />
          <Typography.Text>
            Tiền ship: {priceToCurrency(record.deliveryFeeActual)} (
            {record.distance}km)
          </Typography.Text>
          <br />
          <Typography.Text>
            Giảm giá: {priceToCurrency(record.discount)}
          </Typography.Text>
          <br />
          <Typography.Text>
            App nhận: {priceToCurrency(record.appReceiveActual)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            Tổng tiền: {priceToCurrency(record.total)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            Điểm nhận: {record.shipperPoint || 0} ★
          </Typography.Text>
          {record.editedCount ? (
            <>
              <br />
              <Typography.Text strong type="danger">
                <IssuesCloseOutlined /> {`Đã sửa: ${record.editedCount} lần`}
              </Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Thời gian",
      width: "180px",
      render: (record) => (
        <>
          <Typography.Text>
            Đặt đơn: {moment(record.orderedAt).format("HH:mm")}
          </Typography.Text>
          <br />
          {record.paidAt ? (
            <>
              {" "}
              <Typography.Text>
                Thanh toán: {moment(record.paidAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          <Typography.Text>
            Nhận đơn:{" "}
            {record.assignedAt
              ? moment(record.assignedAt).format("HH:mm")
              : "-"}
          </Typography.Text>
          <br />
          {record.confirmedAt ? (
            <>
              {" "}
              <Typography.Text>
                Xác nhận: {moment(record.confirmedAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          {record.arrivedAt ? (
            <>
              {" "}
              <Typography.Text>
                Đến quán: {moment(record.arrivedAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          {record.pickupAt ? (
            <>
              {" "}
              <Typography.Text>
                Lấy đơn: {moment(record.pickupAt).format("HH:mm")}
              </Typography.Text>
              <br />
            </>
          ) : null}
          {record.shipper ? (
            <>
              {" "}
              <Typography.Text strong>
                Tài xế: {record.shipper.name}
              </Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "12%",
      render: (record) => (
        <Actions
          record={record}
          onViewDetail={detailDrawer.onOpen}
          onViewCost={costModal.onOpen}
          onViewReject={rejectModal.onOpen}
          onCancel={cancelModal.onOpen}
          onEdit={editDrawer.onOpen}
          onFastEdit={fastEditModal.onOpen}
          onViewChat={chatModal.onOpen}
          onViewEditHistory={editHistoryModal.onOpen}
        />
      ),
    },
  ];

  const orderStatusOptions = [
    {
      label: "Tất cả trạng thái",
      value: "",
    },
    {
      label: "Chờ thanh toán",
      value: "wait-pay",
    },
    {
      label: "Đang tìm tài xế",
      value: "finding",
    },
    {
      label: "Chờ xác nhận",
      value: "confirm-customer",
    },
    {
      label: "Tài xế đang đến quán",
      value: "going-to-restaurant",
    },
    {
      label: "Tài xế đã đến quán",
      value: "arrived-restaurant",
    },
    {
      label: "Tài xế đã lấy hàng",
      value: "picked-up",
    },
  ];

  const paymentMethodOptions = [
    {
      label: "Tất cả phương thức",
      value: "",
    },
    {
      label: "Tiền mặt",
      value: "cod",
    },
    {
      label: "VietQR",
      value: "qrcode",
    },
    {
      label: "Ví Goka",
      value: "goka-wallet",
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Đơn hàng đang xử lý
        </Typography.Title>
        <Space size="middle">
          <Select
            options={orderStatusOptions}
            onChange={(value) =>
              onChangeSearchValue("deliveryStatus", value, true)
            }
            value={searchValue.deliveryStatus}
            allowClear
            placeholder="Trạng thái đơn hàng"
            className="w-60"
          />
          <Select
            options={paymentMethodOptions}
            onChange={(value) =>
              onChangeSearchValue("paymentMethod", value, true)
            }
            value={searchValue.paymentMethod}
            allowClear
            placeholder="Phương thức thanh toán"
            style={{ width: "180px" }}
          />
          <Input
            placeholder="Tìm theo mã đơn"
            onChange={(e) => onChangeSearchValue("code", e.target.value)}
            allowClear
            value={searchValue.code}
          />
          <Input
            placeholder="Tìm theo SĐT khách hàng"
            onChange={(e) => onChangeSearchValue("customer", e.target.value)}
            allowClear
            value={searchValue.customer}
          />
          <Button onClick={onSearch} icon={<SearchOutlined />} type="primary">
            Tìm kiếm
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={orders}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {detailDrawer.open && (
        <OrderDetailDrawer
          open={detailDrawer.open}
          onClose={detailDrawer.onClose}
          order={detailDrawer.detail}
          onViewCost={() => {
            costModal.onOpen(detailDrawer.detail);
            detailDrawer.onClose();
          }}
        />
      )}
      {costModal.open && (
        <OrderCostModal
          open={costModal.open}
          onClose={costModal.onClose}
          order={costModal.detail}
        />
      )}
      {rejectModal.open && (
        <RejectModal
          open={rejectModal.open}
          onClose={rejectModal.onClose}
          order={rejectModal.detail}
        />
      )}
      {cancelModal.open && (
        <ConfirmCancelModal
          open={cancelModal.open}
          onClose={cancelModal.onClose}
          order={cancelModal.detail}
          onSubmit={onCancelOrder}
          loading={isProcessing}
        />
      )}
      {editDrawer.open && (
        <OrderEditDrawer
          open={editDrawer.open}
          onClose={editDrawer.onClose}
          order={editDrawer.detail}
        />
      )}
      {fastEditModal.open && (
        <FastEditModal
          open={fastEditModal.open}
          onClose={fastEditModal.onClose}
          order={fastEditModal.detail}
          onRefresh={onSearch}
        />
      )}
      {chatModal.open && (
        <ChatModal
          open={chatModal.open}
          onClose={chatModal.onClose}
          order={chatModal.detail}
        />
      )}
      {editHistoryModal.open && (
        <EditHistoryModal
          open={editHistoryModal.open}
          onClose={editHistoryModal.onClose}
          order={editHistoryModal.detail}
        />
      )}
      <Image
        width={200}
        style={{
          display: "none",
        }}
        preview={{
          visible: showDirectionImage,
          src: directionImage,
          destroyOnClose: true,
          onVisibleChange: (value) => onVisibleDirectionImageChange(value),
        }}
      />
    </>
  );
}

export default Order;
