import { Modal, Tabs } from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as restaurantActions from "../../slice";
import TabDishOption from "./TabDishOption";
import TabMenu from "./TabMenu";

function RestaurantMenuModal(props) {
  const { open, isProcessing, restaurant, onCancel } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(restaurantActions.getMenus(restaurant._id));
      dispatch(restaurantActions.getDishes(restaurant._id));
      dispatch(restaurantActions.getDishOptions(restaurant._id));
    }
  }, [open]);

  const items = [
    {
      key: "menu",
      label: "Danh mục",
      forceRender: true,
      disabled: isProcessing,
      children: <TabMenu restaurant={restaurant} onClose={onCancel} />,
    },
    {
      key: "optional",
      label: "Nhóm tùy chọn",
      forceRender: true,
      disabled: isProcessing,
      children: <TabDishOption restaurant={restaurant} />,
    },
  ];

  return (
    <>
      <Modal
        title="Menu Quán ăn"
        cancelText="Đóng"
        centered
        open={open}
        okButtonProps={{ className: "hidden" }}
        onCancel={onCancel}
        confirmLoading={isProcessing}
        width="640px"
      >
        <Tabs defaultActiveKey="menu" items={items} />
      </Modal>
    </>
  );
}

RestaurantMenuModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  restaurant: PropTypes.object,
};

RestaurantMenuModal.defaultProps = {
  isProcessing: false,
  open: false,
  restaurant: null,
};

export default RestaurantMenuModal;
