import { createSlice } from "@reduxjs/toolkit";
import userApi from "./api";
import { toast } from "react-toastify";

const showTotal = (total) => `Tổng: [${total}]`;

const initialState = {
  users: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = { ...action.payload };
    },
    editUser: (state, action) => {
      state.isCompleted = true;
      state.users = state.users.map((user) =>
        user._id === action.payload._id ? action.payload : user
      );
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setUsers,
  setPagination,
  editUser,
} = userSlice.actions;

export const fetchUsers = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await userApi.getAll(params);
      dispatch(setUsers(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const updateUser = (user) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await userApi.update(user);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editUser(data));
        toast.success("Cập nhật người dùng thành công!");
        dispatch(setIsCompleted(true));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const blockUser = (user, blockedReason) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await userApi.block(user._id, blockedReason);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editUser({ ...user, ...data.data }));
        toast.success("Cập nhật trạng thái chặn người dùng thành công!");
        dispatch(setIsCompleted(true));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export const activateUser = (user) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await userApi.activate(user._id);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(editUser({ ...user, ...data.data }));
        toast.success("Cập nhật trạng thái tài khoản thành công!");
        dispatch(setIsCompleted(true));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default userSlice.reducer;
