import { Button, DatePicker, Empty, Modal, Space, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import shipperApi from "../../api";

const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: "Hôm nay",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Hôm qua",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Tuần này",
    value: [dayjs().startOf("week"), dayjs()],
  },
  {
    label: "Tuần trước",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "Tháng này",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Tháng trước",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Tất cả",
    value: [dayjs("01/07/2023", "DD/MM/YYYY"), dayjs()],
  },
];

export default function TrackingModal({ open, onClose, shipper }) {
  const [range, setRange] = useState([dayjs(), dayjs()]);
  const [logs, setLogs] = useState(null);

  useEffect(() => {
    const getShipperTracking = async () => {
      const data = await shipperApi.getTracking(
        shipper._id,
        range[0].format("YYYY-MM-DD"),
        range[1].format("YYYY-MM-DD")
      );
      if (!data || data.error) {
        toast.error(data.error);
        setLogs(null);
      } else setLogs(data);
    };
    if (shipper) getShipperTracking();
  }, [shipper, range]);

  const onRangeChange = (dates) => {
    setRange(dates);
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        title="Lịch sử Bật/Tắt nhận đơn"
        footer={[
          <Button type="primary" key="back" onClick={onClose}>
            OK
          </Button>,
        ]}
      >
        <RangePicker
          presets={rangePresets}
          value={range}
          onChange={onRangeChange}
        />
        <br />
        <br />
        {logs?.length ? (
          <div style={{ maxHeight: 400, overflow: "auto" }}>
            {logs.map((log) => (
              <div
                key={log._id}
                style={{ display: "block", marginBottom: "0" }}
              >
                <Space>
                  <Tag color={log.status === "ON" ? "green" : "red"}>
                    {log.status === "ON" ? "Bật" : "Tắt"}
                  </Tag>{" "}
                  <h4>{dayjs(log.createdAt).format("DD/MM/YYYY HH:mm:ss")}</h4>
                </Space>
              </div>
            ))}
          </div>
        ) : (
          <Empty
            description={`Không có dữ liệu log ${shipper.name} trong thời gian này!`}
          />
        )}
      </Modal>
    </>
  );
}
