import { Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import shipperApi from "../../../shipper/api";
import orderApi from "../api";

export default function AssignModal({ open, onClose, order }) {
  const [shippers, setShippers] = useState([]);
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOnlineShippers = async () => {
      const data = await shipperApi.getOnlines();
      const _shippers = data.map((e) => ({
        value: e._id,
        label: `${e.name} (${e.processingCount || 0}) `,
      }));
      setShippers(_shippers);
    };
    getOnlineShippers();
  }, [order]);

  const onChange = (value) => {
    setSelectedShipper(value);
  };

  const onSubmit = async () => {
    if (order && selectedShipper) {
      try {
        setLoading(true);
        const data = await orderApi.assignShipper(order._id, selectedShipper);
        if (data.error) {
          toast.error(data.error.msg);
        } else {
          onClose();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Chưa chọn tài xế");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        onOk={onSubmit}
        confirmLoading={loading}
        title="Gán đơn cho tài xế"
      >
        <Select
          showSearch
          placeholder="Chọn 1 tài xế để gán đơn"
          optionFilterProp="children"
          onChange={onChange}
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={shippers}
        />
      </Modal>
    </>
  );
}
