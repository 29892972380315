import contactApi from "./api";
import { createSlice } from "@reduxjs/toolkit";

const initialContact = {
  contacts: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

const contact = createSlice({
  name: "contacts",
  initialState: initialContact,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

const { reducer, actions } = contact;

export const { setIsLoading, setContacts, setPagination } = actions;

export const fetchContacts = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await contactApi.getAll(params);
      dispatch(setContacts(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export default reducer;
