import qs from "query-string";
import axios from "axios";
import { toast } from "react-toastify";
import { API_PATH } from "utils/constants";

const axiosClient = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: API_PATH,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "brackets" }),
  },
});

axiosClient.interceptors.request.use(
  async (config) => {
    //handle token here
    const region = localStorage.getItem("region");
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    if (region) {
      config.headers["Region"] = region;
    }
    return config;
  },
  (error) => Promise.reject(error)
  // return config;
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status ?? 500;
      const message = error.response.data?.error?.message || false;
      switch (status) {
        case 401:
          window.localStorage.removeItem("token");
          window.location.href = "/dang-nhap";
          break;
        case 404:
          toast.error("Lỗi: " + message || "URL không tồn tại !");
          break;
        case 403:
          toast.error("Lỗi: " + message || "Bạn không có quyền!");
          break;
        case 429:
          toast.error(
            "Lỗi: Bạn đang thao tác quá nhanh! Cố tình spam sẽ bị chặn!"
          );
          break;
        case 500:
          toast.error("Lỗi: " + message || "Lỗi server !");
          break;
        default:
          toast.error("Lỗi: " + message || "Có lỗi xảy ra!");
      }
    } else {
      toast.error("Lỗi: " + error.message || "Có lỗi xảy ra!");
    }
    throw error;
  }
);

export default axiosClient;
