import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Form, Input, Modal, Select, Tabs, Typography, Upload } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import { classNames, getBase64, getPhotoThumbnailUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";
const { Paragraph } = Typography;
function ShipperDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onSubmit, onCancel, shipper } = props;
  const [imageUrl, setImageUrl] = useState(
    getPhotoThumbnailUrl(shipper?.avatar?.path || null)
  );
  const [image, setImage] = useState(null);
  const [generatePasswordLoading, setGeneratePasswordLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const getBanks = async () => {
      const data = await commonApi.getBanks();
      if (!data || data.error) {
        toast.error(data.error.msg);
      } else {
        setBanks(data);
      }
    };
    if (open) {
      getBanks();
    }
  }, [open]);

  const initialValues = shipper
    ? {
        ...shipper,
      }
    : {
        name: "",
        phone: "",
        email: "",
        gender: true,
        mode: "full-time",
        vehicle: "",
        numberPlate: "",
        active: true,
        password: "",
        priority: 100,
        telegramId: "",
        banking: {
          bankName: "",
          accountNumber: "",
          accountName: "",
        },
      };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        const shipper = { ...initialValues, ...values };
        Object.entries({
          data: JSON.stringify(shipper),
          avatar: image,
        }).forEach(([key, value]) => {
          formData.append(key, value);
        });
        onSubmit(shipper, formData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onGeneratePassword = async () => {
    try {
      setGeneratePasswordLoading(true);
      const data = await commonApi.generatePassword();
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        toast.success("Tạo mật khẩu thành công");
        form.setFieldValue("password", data.password);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setGeneratePasswordLoading(false);
    }
  };

  const beforeUpload = async (file) => {
    const validType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!validType) {
      toast.error("Chỉ chấp nhận file ảnh!");
    } else {
      const preview = await getBase64(file);
      setImageUrl(preview);
      setImage(file);
    }
    return false;
  };

  const onValueChange = (changedValues) => {
    if (changedValues["banking"]?.["accountName"]) {
      form.setFieldValue(
        ["banking", "accountName"],
        changedValues["banking"]["accountName"].toUpperCase()
      );
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      title="Tài xế"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
      width="40%"
    >
      <Form
        {...formItemLayoutHorizontal}
        form={form}
        initialValues={initialValues}
        onValuesChange={onValueChange}
      >
        <Tabs defaultActiveKey="info">
          <Tabs.TabPane tab="Thông tin" key="info">
            <Form.Item
              name="name"
              label="Tên"
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label="SĐT"
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Giới tính"
              rules={[{ required: true }]}
            >
              <Select placeholder="--Giới tính--">
                <Select.Option value={true}>Nam</Select.Option>
                <Select.Option value={false}>Nữ</Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item name="mode" label="Chế độ">
          <Radio.Group>
            <Radio value="full-time">Full-time</Radio>
            <Radio value="part-time">Part-time</Radio>
          </Radio.Group>
        </Form.Item> */}
            <Form.Item
              name="vehicle"
              label="Loại xe"
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="numberPlate"
              label="Biển số xe"
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Ảnh đại diện">
              <Upload
                accept={"image/*"}
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="image" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Tài khoản" key="account">
            <Form.Item name="active" label="Trạng thái">
              <Select>
                <Select.Option value={true}>Active</Select.Option>
                <Select.Option value={false}>In-active</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="password" label="Mật khẩu">
              <Input
                // disabled={Boolean(shipper)}
                type={showPassword ? "text" : "password"}
                addonBefore={
                  <div
                    className={
                      "select-none cursor-pointer flex items-center gap-x-2"
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </div>
                }
                addonAfter={
                  generatePasswordLoading ? (
                    <LoadingOutlined spin />
                  ) : (
                    <div
                      className={classNames(
                        "select-none",
                        shipper ? "cursor-pointer" : "cursor-pointer"
                      )}
                      onClick={onGeneratePassword}
                    >
                      Tạo mật khẩu
                    </div>
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="priority"
              label="Độ ưu tiên"
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc",
                },
              ]}
            >
              <Input type="number" min={1} max={100} />
            </Form.Item>
            <Form.Item name="telegramId" label="Telegram ID">
              <Input />
            </Form.Item>
            <Form.Item name="withdrawCode" label="Mã nạp tiền">
              <Input readOnly />
            </Form.Item>
            {shipper ? (
              <Form.Item name="appToken" label="FCM Token">
                {shipper.appTokens?.map((token) => (
                  <Paragraph copyable key={token}>
                    {token}
                  </Paragraph>
                )) || null}
              </Form.Item>
            ) : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Tài khoản ngân hàng" key="bank">
            <Form.Item name={["banking", "bankId"]} label="Ngân hàng">
              <Select
                showSearch
                optionFilterProp="label"
                options={banks.map((bank) => ({
                  value: bank.id,
                  label: bank.name,
                }))}
              ></Select>
            </Form.Item>
            <Form.Item name={["banking", "accountNumber"]} label="Số tài khoản">
              <Input />
            </Form.Item>
            <Form.Item name={["banking", "accountName"]} label="Chủ tài khoản">
              <Input />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}

ShipperDetailModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  shipper: PropTypes.object,
};

ShipperDetailModal.defaultProps = {
  isProcessing: false,
  open: false,
  shipper: null,
};

export default ShipperDetailModal;
