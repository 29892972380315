import { Button, Divider } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "../slice";

export default function HotKeywords() {
  const dispatch = useDispatch();
  const { config, isLoading } = useSelector((state) => state.config);
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    const hotKeywords = config.hotKeywords || [];
    setKeywords(hotKeywords.join(", "));
  }, [config]);

  const onChangeKeywords = (e) => {
    setKeywords(e.target.value);
  };

  const onSubmit = () => {
    const kws = keywords
      .split(",")
      .map((e) => e.trim())
      .filter((e) => !!e);
    dispatch(updateConfig({ hotKeywords: kws }));
  };

  return (
    <>
      <Divider orientation="left">Từ khóa tìm kiếm nổi bật</Divider>
      <TextArea
        placeholder="Điền các keyword cách nhau bởi dấu ,"
        autoSize={{
          minRows: 4,
          maxRows: 10,
        }}
        value={keywords}
        onChange={onChangeKeywords}
        style={{ maxWidth: "680px" }}
      />
      <br />
      <br />
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        onClick={onSubmit}
      >
        Cập nhật
      </Button>
    </>
  );
}
