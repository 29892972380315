import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./components/Actions";
import RegionDetailModal from "./components/DetailModal";
import * as regionActions from "./slice";
import { priceToCurrency } from "utils/common";

function Region() {
  const dispatch = useDispatch();
  const { regions, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.region);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingRegion, setSelectingRegion] = useState(null);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(regionActions.fetchRegions(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingRegion(null);
    }
  }, [isCompleted]);

  const onOpenDetailModal = (region = null) => {
    setSelectingRegion(region);
    setOpenDetailModal(true);
  };

  const onDelete = (region) => {
    Modal.confirm({
      title: `Xác nhận xoá phân cụm`,
      content: `Bạn có chắc chắn muốn xoá phân cụm này?`,
      onOk: () => dispatch(regionActions.deleteRegion(region._id)),
      centered: true,
    });
  };

  const onSubmit = (region) => {
    if (region._id) {
      dispatch(regionActions.updateRegion(region));
    } else {
      dispatch(regionActions.createRegion(region));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingRegion(null);
    dispatch(regionActions.setIsProcessing(false));
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSearch = () => {
    dispatch(
      regionActions.fetchRegions({
        ...pagination,
        name: searchName,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      regionActions.fetchRegions({
        ...pagination,
        name: searchName,
      })
    );
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "8%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Mã phân cụm",
      dataIndex: "code",
      width: "10%",
    },
    {
      title: "Tên phân cụm",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "GeoJSON",
      dataIndex: "coordinates",
      width: "12%",
      render: (data) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={`http://geojson.io/#data=data:application/json,${encodeURIComponent(
            JSON.stringify({
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {
                    stroke: "#f00",
                    "stroke-width": 2,
                    "stroke-opacity": 1,
                    fill: "#ffd22f",
                    "fill-opacity": 0.4,
                  },
                  geometry: {
                    coordinates: [data],
                    type: "Polygon",
                  },
                  id: 0,
                },
              ],
            })
          )}`}
        >
          <EyeOutlined /> Xem
        </a>
      ),
    },
    {
      title: "SĐT liên hệ",
      dataIndex: "phone",
      width: "12%",
    },
    {
      title: "Số dư ví",
      dataIndex: "balance",
      width: "10%",
      render: (data) => priceToCurrency(data),
    },
    {
      title: "Mặc định",
      dataIndex: "isDefault",
      align: "center",
      width: 200,
      render: (isDefault) =>
        isDefault ? (
          <span className="text-xl">
            <CheckOutlined />
          </span>
        ) : (
          <span className="text-slate-300 text-xl">
            <CloseOutlined />
          </span>
        ),
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      width: 200,
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      width: "15%",
      render: (record) => (
        <Actions
          record={record}
          onEdit={onOpenDetailModal}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>Phân cụm</Typography.Title>
        <Space size="middle">
          <Input.Search
            placeholder="Tìm theo tên"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            enterButton
            allowClear
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onOpenDetailModal()}
          >
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={regions}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailModal && (
        <RegionDetailModal
          open={openDetailModal}
          isProcessing={isProcessing}
          onSubmit={onSubmit}
          onCancel={onCancel}
          region={selectingRegion}
        />
      )}
    </>
  );
}

export default Region;
