import { Form, Input, Modal, Select } from "antd";
import { formItemLayoutHorizontal } from "utils/constants";

const { Option } = Select;

function KeyDetailModal({ open, isProcessing, onSubmit, onCancel, account }) {
  const [form] = Form.useForm();

  const initialValues = account || {
    key: "",
    note: "",
    active: true,
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => onSubmit({ ...initialValues, ...values }))
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Modal
      title="Google Map Key"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
      // width={720}
    >
      <Form
        {...formItemLayoutHorizontal}
        form={form}
        initialValues={initialValues}
      >
        <Form.Item
          name="key"
          label="Key"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="note" label="Ghi chú">
          <Input />
        </Form.Item>
        <Form.Item name="active" label="Trạng thái">
          <Select>
            <Option value={true}>Active</Option>
            <Option value={false}>In-active</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default KeyDetailModal;
