import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as restaurantActions from "../../slice";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";

export default function TabBank({ form, restaurant, onCancel }) {
  const dispatch = useDispatch();
  const { isProcessing } = useSelector((state) => state.restaurant);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const getBanks = async () => {
      const data = await commonApi.getBanks();
      if (!data || data.error) {
        toast.error(data.error.msg);
      } else {
        setBanks(data);
      }
    };
    if (open) {
      getBanks();
    }
  }, [open]);

  const onSubmit = () => {
    const bankingData = form.getFieldValue("banking");
    dispatch(
      restaurantActions.updateRestaurantBanking(restaurant, bankingData)
    );
  };

  return (
    <>
      <Form.Item name={["banking", "bankId"]} label="Ngân hàng">
        <Select
          showSearch
          optionFilterProp="label"
          options={banks.map((bank) => ({
            value: bank.id,
            label: bank.name,
          }))}
        ></Select>
      </Form.Item>
      <Form.Item name={["banking", "accountNumber"]} label="Số tài khoản">
        <Input />
      </Form.Item>
      <Form.Item name={["banking", "accountName"]} label="Chủ tài khoản">
        <Input />
      </Form.Item>
      {restaurant && (
        <>
          <Button
            className="float-right"
            type="primary"
            onClick={onSubmit}
            loading={isProcessing}
          >
            Lưu thay đổi tài khoản ngân hàng
          </Button>
          <Button
            className="float-right mr-4"
            onClick={onCancel}
            disabled={isProcessing}
          >
            Hủy
          </Button>
        </>
      )}
    </>
  );
}
