import {
  CloseOutlined,
  CommentOutlined,
  DiffOutlined,
  DollarOutlined,
  DownOutlined,
  EyeOutlined,
  RocketOutlined,
  SwapOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { ACCOUNT_ROLES } from "utils/constants";

export default function Actions({
  record,
  onViewDetail,
  onViewCost,
  onViewReject,
  onViewEditHistory,
  onCancel,
  onAssignOrder,
  onTransferOrder,
  onViewChat,
}) {
  const { userInfo } = useSelector((state) => state.auth);

  const KEY_ACTIONS = {
    VIEW_ORDER: "view_order",
    VIEW_COST: "view_cost",
    VIEW_REJECT: "view_reject",
    VIEW_EDIT_HISTORY: "view_edit_history",
    ASSIGN_ORDER: "assign_order",
    TRANSFER_ORDER: "transfer_order",
    CANCEL: "cancel",
    VIEW_CHAT: "view_chat",
  };

  const onClick = ({ key }) => {
    switch (key) {
      case KEY_ACTIONS.VIEW_ORDER:
        onViewDetail(record);
        break;
      case KEY_ACTIONS.VIEW_COST:
        onViewCost(record);
        break;
      case KEY_ACTIONS.VIEW_REJECT:
        onViewReject(record);
        break;
      case KEY_ACTIONS.ASSIGN_ORDER:
        onAssignOrder(record);
        break;
      case KEY_ACTIONS.TRANSFER_ORDER:
        onTransferOrder(record);
        break;
      case KEY_ACTIONS.CANCEL:
        onCancel(record);
        break;
      case KEY_ACTIONS.VIEW_CHAT:
        onViewChat(record);
        break;
      case KEY_ACTIONS.VIEW_EDIT_HISTORY:
        onViewEditHistory(record);
        break;
      default:
        break;
    }
  };

  const actions = {
    super_admin: [
      {
        key: KEY_ACTIONS.VIEW_ORDER,
        label: "Xem chi tiết đơn",
        icon: <EyeOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_COST,
        label: "Xem chi tiết phí",
        icon: <DollarOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_CHAT,
        label: "Xem lịch sử chat",
        icon: <CommentOutlined />,
      },
      {
        key: KEY_ACTIONS.VIEW_REJECT,
        label: "DS tài xế bỏ qua",
        icon: <UserDeleteOutlined />,
      },
      {
        key: KEY_ACTIONS.ASSIGN_ORDER,
        label: "Gán đơn cho tài xế",
        icon: <RocketOutlined />,
        disabled:
          record.deliveryStatus !== "finding" || record.status === "wait-pay",
      },
      {
        key: KEY_ACTIONS.TRANSFER_ORDER,
        label: "Chuyển cho tài xế khác",
        icon: <SwapOutlined />,
        disabled: record.status === "finding" || record.status === "wait-pay",
      },
      {
        key: KEY_ACTIONS.CANCEL,
        disabled: ![ACCOUNT_ROLES.SUPER_ADMIN, ACCOUNT_ROLES.ADMIN].includes(
          userInfo.role
        ),
        danger: true,
        label: "Hủy đơn",
        icon: <CloseOutlined />,
      },
    ],
  };

  if (record.editedCount) {
    actions.super_admin.splice(3, 0, {
      key: KEY_ACTIONS.VIEW_EDIT_HISTORY,
      label: "Xem lịch sử sửa",
      icon: <DiffOutlined />,
    });
  }

  actions.admin = actions.super_admin;

  return (
    <Dropdown menu={{ items: actions["super_admin"] || [], onClick }}>
      <Button>
        <Space>
          Thao tác
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
