import { PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { priceToCurrency } from "utils/common";
import Actions from "./components/Actions";
import VoucherDetailModal from "./components/DetailModal";
import RejectVerifyModal from "./components/RejectVerifyModal";
import * as voucherActions from "./slice";

const { Text } = Typography;

function Voucher() {
  const dispatch = useDispatch();
  const {
    vouchers,
    totalVerify,
    pagination,
    isLoading,
    isProcessing,
    isCompleted,
  } = useSelector((state) => state.voucher);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectingVoucher, setSelectingVoucher] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchOwner, setSearchOwner] = useState("");
  const [searchIsPersonal, setSearchIsPersonal] = useState("");
  const [searchIsSecret, setSearchIsSecret] = useState("");
  const [searchIsMerchantCreated, setSearchIsMerchantCreated] = useState("");
  const [searchIsCanMerchantJoin, setSearchIsCanMerchantJoin] = useState("");
  const [searchIsActive, setSearchIsActive] = useState(true);
  const [searchIsRequestVerify, setSearchIsRequestVerify] = useState(false);
  const [randomModalKey, setRandomModalKey] = useState(new Date().getTime());
  const [openRejectVerifyModal, setOpenRejectVerifyModal] = useState(false);

  useEffect(() => {
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
        current: 1,
      })
    );
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenDetailModal(false);
      setSelectingVoucher(null);
      setOpenRejectVerifyModal(false);
      dispatch(
        voucherActions.fetchVouchers({
          ...pagination,
          name: searchName,
          owner: searchOwner,
          isPersonal: searchIsPersonal,
          isSecret: searchIsSecret,
          isMerchantCreated: searchIsMerchantCreated,
          canMerchantJoin: searchIsCanMerchantJoin,
          isRequestVerify: searchIsRequestVerify,
          isActive: searchIsActive,
          current: 1,
        })
      );
    }
  }, [isCompleted]);

  const onCreateItem = () => {
    setSelectingVoucher(null);
    setRandomModalKey(new Date().getTime());
    setOpenDetailModal(true);
  };

  const onEdit = (voucher) => {
    setSelectingVoucher(voucher);
    setOpenDetailModal(true);
  };

  const onDelete = (voucher) => {
    Modal.confirm({
      title: `Xác nhận xoá voucher`,
      content: `Bạn có chắc chắn muốn xoá voucher này?`,
      onOk: () => dispatch(voucherActions.deleteVoucher(voucher._id)),
      centered: true,
    });
  };

  const onSubmit = (voucher) => {
    if (voucher._id) {
      dispatch(voucherActions.updateVoucher(voucher));
    } else {
      dispatch(voucherActions.createVoucher(voucher));
    }
  };

  const onCancel = () => {
    setOpenDetailModal(false);
    setSelectingVoucher(null);
    dispatch(voucherActions.setIsProcessing(false));
  };

  const onVerify = () => {
    Modal.confirm({
      title: `Xác nhận duyệt voucher`,
      content: `Bạn có chắc chắn muốn duyệt voucher này?`,
      onOk: () => {
        if (selectingVoucher?._id) {
          dispatch(voucherActions.verifyVoucher(selectingVoucher._id));
        }
      },
      centered: true,
    });
  };

  const onReject = () => {
    setOpenRejectVerifyModal(true);
  };

  const onCloseRejectVerify = () => {
    setOpenRejectVerifyModal(false);
  };

  const onSubmitRejectVerify = (reason) => {
    if (!reason) {
      toast.error("Vui lòng nhập lý do từ chối");
      return;
    }
    if (selectingVoucher?._id) {
      dispatch(voucherActions.rejectVoucher(selectingVoucher._id, reason));
    }
  };

  const onChangeNameSearch = (e) => {
    setSearchName(e.target.value);
  };

  const onSelectOwnerSearch = (owner) => {
    setSearchOwner(owner);
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        owner: owner,
        name: searchName,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const onSearch = (kw) => {
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        name: kw,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
      })
    );
  };

  const handleSearchRequestVerify = () => {
    setSearchIsRequestVerify(!searchIsRequestVerify);
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        isRequestVerify: !searchIsRequestVerify,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const handleSeachIsPersonal = (isPersonal) => {
    setSearchIsPersonal(isPersonal);
    dispatch(
      voucherActions.fetchVouchers({
        isPersonal,
        ...pagination,
        name: searchName,
        owner: searchOwner,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
      })
    );
  };

  const handleSeachIsSecret = (isSecret) => {
    setSearchIsSecret(isSecret);
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        isSecret,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const handleSeachIsMerchantCreated = (isMerchantCreated) => {
    setSearchIsMerchantCreated(isMerchantCreated);
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        isMerchantCreated,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const handleSearchCanMerchantJoin = (canMerchantJoin) => {
    setSearchIsCanMerchantJoin(canMerchantJoin);
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        canMerchantJoin,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        isRequestVerify: searchIsRequestVerify,
        isActive: searchIsActive,
        current: 1,
      })
    );
  };

  const handleSearchIsActive = (isActive) => {
    setSearchIsActive(isActive);
    dispatch(
      voucherActions.fetchVouchers({
        ...pagination,
        isActive,
        name: searchName,
        owner: searchOwner,
        isPersonal: searchIsPersonal,
        isSecret: searchIsSecret,
        isMerchantCreated: searchIsMerchantCreated,
        canMerchantJoin: searchIsCanMerchantJoin,
        isRequestVerify: searchIsRequestVerify,
        current: 1,
      })
    );
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Mã voucher",
      render: (record) => (
        <>
          {record.code}
          {record.isVerify === false && (
            <>
              <Text
                mark
                style={{
                  display: "block",
                  marginTop: "6px",
                  cursor: "pointer",
                }}
                onClick={() => onEdit(record)}
              >
                Đang chờ duyệt
              </Text>
              {record.rejectReason && (
                <Text
                  type="danger"
                  style={{
                    display: "block",
                    marginTop: "6px",
                  }}
                >
                  Đã từ chối: {record.rejectReason}
                </Text>
              )}
            </>
          )}
        </>
        // dataIndex: "code",
      ),
    },
    {
      title: "Tên voucher",
      // dataIndex: "name",
      render: (record) => (
        <>
          <span style={{ display: "block" }}>{record.name}</span>
          {record.isMerchantCreated ? (
            <Tag
              color="gold"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Quán tạo
            </Tag>
          ) : null}
          {record.canMerchantJoin ? (
            <Tag
              color="cyan"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Mã chiến dịch
            </Tag>
          ) : null}
          {record.isSecret ? (
            <Tag
              color="purple"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Mã bí mật
            </Tag>
          ) : null}
          {record.isPersonal ? (
            <Tag
              color="magenta"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Mã cá nhân
            </Tag>
          ) : null}
        </>
      ),
    },
    {
      title: "Chủ voucher",
      // dataIndex: "owner",
      render: (record) => {
        if (record.owner === "restaurant")
          return record.restaurant?.name || "Unknown";
        else return `GOKA`;
      },
    },
    {
      title: "Giá trị giảm",
      // dataIndex: "value",
      render: (record) =>
        record.discountValueType === "direct"
          ? priceToCurrency(record.value)
          : record.value + "%",
    },
    {
      title: "Số lượng",
      render: (record) => `${record.usedCount}/${record.count}`,
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      width: "7%",
      render: (startDate) => moment(startDate).format("DD/MM/YYYY"),
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      width: "7%",
      render: (endDate) => moment(endDate).format("DD/MM/YYYY"),
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
    {
      title: "Thao tác",
      align: "center",
      render: (record) => (
        <Actions record={record} onEdit={onEdit} onDelete={onDelete} />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Voucher khuyến mãi
        </Typography.Title>
        <Space size="middle">
          <Select
            allowClear
            showSearch
            style={{ width: 150 }}
            placeholder="Chủ voucher"
            onChange={onSelectOwnerSearch}
          >
            <Select.Option key="all" value="">
              Tất cả
            </Select.Option>
            <Select.Option key={"app"} value={"app"}>
              Voucher App
            </Select.Option>
            <Select.Option key={"restaurant"} value={"restaurant"}>
              Voucher Quán
            </Select.Option>
          </Select>
          <Input.Search
            placeholder="Tìm theo tên hoặc code hoặc ID quán"
            onChange={onChangeNameSearch}
            onSearch={onSearch}
            style={{ width: 330 }}
            enterButton
            allowClear
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={onCreateItem}>
            Thêm mới
          </Button>
        </Space>
      </Row>
      <Row justify="end" align="middle">
        <Space size="middle">
          <Switch
            checkedChildren="Mã cá nhân"
            unCheckedChildren="Mã cá nhân"
            onChange={handleSeachIsPersonal}
          />
          <Switch
            checkedChildren="Mã bí mật"
            unCheckedChildren="Mã bí mật"
            onChange={handleSeachIsSecret}
          />
          <Switch
            checkedChildren="Quán tạo"
            unCheckedChildren="Quán tạo"
            onChange={handleSeachIsMerchantCreated}
          />
          <Switch
            checkedChildren="Cho quán tham gia"
            unCheckedChildren="Cho quán tham gia"
            onChange={handleSearchCanMerchantJoin}
          />
          <Switch
            checked={searchIsRequestVerify}
            checkedChildren="Đang chờ duyệt"
            unCheckedChildren="Đang chờ duyệt"
            onChange={handleSearchRequestVerify}
          />
          <Switch
            defaultChecked
            checkedChildren="Đang kích hoạt"
            unCheckedChildren="Hủy kích hoạt"
            onChange={handleSearchIsActive}
          />
        </Space>
      </Row>
      {totalVerify > 0 && (
        <Row justify="end" align="middle">
          <Text
            mark
            strong
            style={{ fontSize: "18px", marginTop: "16px", cursor: "pointer" }}
            onClick={handleSearchRequestVerify}
          >
            <Alert
              message={`Có ${totalVerify} voucher khuyến mãi do quán tạo đang chờ duyệt`}
              type="warning"
              showIcon
            />
            {/* 🚨 Có {totalUpdate} quán yêu cầu thay đổi thông tin */}
          </Text>
        </Row>
      )}
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={vouchers}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      <VoucherDetailModal
        open={openDetailModal}
        isProcessing={isProcessing}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onVerify={onVerify}
        onReject={onReject}
        voucher={selectingVoucher}
        key={selectingVoucher ? selectingVoucher["_id"] : randomModalKey}
      />
      {openRejectVerifyModal && (
        <RejectVerifyModal
          open={openRejectVerifyModal}
          onClose={onCloseRejectVerify}
          onSubmit={onSubmitRejectVerify}
        />
      )}
    </>
  );
}

export default Voucher;
