import { Form, InputNumber, Modal } from "antd";
import { useState } from "react";
import { currencyToPrice, priceToCurrency } from "utils/common";

export default function WithdrawModal(props) {
  const { open, isProcessing, onSubmit, onCancel } = props;
  const [amount, setAmount] = useState(0);

  const onChangeAmount = (value) => {
    setAmount(value);
  };

  return (
    <Modal
      title={`Tạo lệnh rút ví`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(amount)}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Form layout="vertical">
        <Form.Item label="Số tiền muốn rút">
          <InputNumber
            className="w-full"
            value={amount}
            onChange={(value) => onChangeAmount(value)}
            formatter={(value) => priceToCurrency(value)}
            parser={(value) => currencyToPrice(value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
