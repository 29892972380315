import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, InputNumber, Space } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "../slice";

export default function ShipperRewards() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { config, isLoading } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    const rewards = {
      daily: config.rewards?.daily ?? [],
      weekly: config.rewards?.weekly ?? [],
    };
    form.setFieldsValue({ rewards });
  }, [config]);

  const onFinish = (config) => {
    console.log(config);
    dispatch(updateConfig({ ...config }));
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      form={form}
      autoComplete="off"
      onFinish={onFinish}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "48%" }}>
          <Divider orientation="left">Thưởng ngày</Divider>
          <Form.List name={["rewards", "daily"]}>
            {(fields, subOpt) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 60,
                }}
              >
                {fields.map((field) => (
                  <Space key={field.key}>
                    <Form.Item
                      name={[field.name, "point"]}
                      label="Số sao tích lũy"
                      rules={[
                        {
                          required: true,
                          message: "Trường này là bắt buộc",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Số sao tích lũy"
                        style={{ width: 200 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "bonus"]}
                      label="Mức thưởng"
                      rules={[
                        {
                          required: true,
                          message: "Trường này là bắt buộc",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Mức thưởng"
                        style={{ width: 300 }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ marginTop: 20 }}
                      onClick={() => {
                        subOpt.remove(field.name);
                      }}
                    />
                  </Space>
                ))}
                <Button
                  type="dashed"
                  onClick={() => subOpt.add()}
                  style={{ width: 510 }}
                >
                  + Thêm mức thưởng
                </Button>
              </div>
            )}
          </Form.List>
        </div>
        <div style={{ width: "48%" }}>
          <Divider orientation="left">Thưởng tuần</Divider>
          <Form.List name={["rewards", "weekly"]}>
            {(fields, subOpt) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 60,
                }}
              >
                {fields.map((field) => (
                  <Space key={field.key}>
                    <Form.Item
                      name={[field.name, "point"]}
                      label="Số sao tích lũy"
                      rules={[
                        {
                          required: true,
                          message: "Trường này là bắt buộc",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Số sao tích lũy"
                        style={{ width: 200 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "bonus"]}
                      label="Mức thưởng"
                      rules={[
                        {
                          required: true,
                          message: "Trường này là bắt buộc",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Mức thưởng"
                        style={{ width: 300 }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ marginTop: 20 }}
                      onClick={() => {
                        subOpt.remove(field.name);
                      }}
                    />
                  </Space>
                ))}
                <Button
                  type="dashed"
                  onClick={() => subOpt.add()}
                  style={{ width: 510 }}
                >
                  + Thêm mức thưởng
                </Button>
              </div>
            )}
          </Form.List>
        </div>
      </div>
      <br />
      <br />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ marginLeft: 60 }}
        >
          Cập nhật
        </Button>
      </Form.Item>
    </Form>
  );
}
