import axiosClient from "utils/api/axios-client";

const orderApi = {
  getAll: (params) => {
    const url = "/api/v1/new-orders";
    return axiosClient.get(url, {
      params,
    });
  },
  getDetail: (id) => {
    const url = `/api/v1/orders/${id}/detail`;
    return axiosClient.get(url);
  },
  getCost: (id) => {
    const url = `/api/v1/orders/${id}/cost`;
    return axiosClient.get(url);
  },
  getRejects: (id) => {
    const url = `/api/v1/orders/${id}/reject`;
    return axiosClient.get(url);
  },
  cancel: (id, reason) => {
    const url = `/api/v1/orders/${id}/cancel`;
    return axiosClient.delete(url, {
      data: {
        reason,
      },
    });
  },
  getEditDetail: (id) => {
    const url = `/api/v1/orders/${id}/edit-detail`;
    return axiosClient.get(url);
  },
  checkEdit: (id, data) => {
    const url = `/api/v1/orders/${id}/check-edit`;
    return axiosClient.post(url, data);
  },
  edit: (id, data) => {
    const url = `/api/v1/orders/${id}/edit`;
    return axiosClient.put(url, data);
  },
  fastEdit: (id, data) => {
    const url = `/api/v1/orders/${id}/fast-edit`;
    return axiosClient.put(url, data);
  },
  getMessages: (id) => {
    const url = `/api/v1/orders/${id}/chats`;
    return axiosClient.get(url);
  },
};

export default orderApi;
