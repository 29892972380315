import { Table, Tag, Typography } from "antd";
import moment from "moment";
import { priceToCurrency } from "utils/common";
import { WALLET_REQUEST_STATUS } from "utils/constants";

export default function Transactions(props) {
  const { transactions, pagination, isLoading, onTableChange } = props;
  const columns = [
    {
      title: "STT",
      align: "center",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Nội dung",
      dataIndex: "name",
      render: (data) => (
        <Typography.Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: "Xem thêm" }}
        >
          {data}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      render: (amount) => `${amount > 0 ? "+" : ""}${priceToCurrency(amount)}`,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: (status) => (
        <Tag color={WALLET_REQUEST_STATUS[status].color}>
          {WALLET_REQUEST_STATUS[status].label}
        </Tag>
      ),
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      align: "center",
      render: (createdAt) => moment(createdAt).fromNow(),
    },
  ];

  return (
    <>
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={transactions}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
    </>
  );
}
