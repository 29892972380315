import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
const URL = process.env.REACT_APP_SOCKET_URL;

export const useSocket = (namespace) => {
  const [socket, setSocket] = useState();
  const { user, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(URL + namespace, {
          path: "/socket.io/admin",
          forceNew: true,
        })
      );
    }

    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.auth = {
        user: isLoggedIn && user ? user : undefined,
      };
      socket.on("connect", () => {
        console.log("socket connected: ", socket.id);
      });
      socket.on("disconnect", () => {
        console.log("socket disconnect: ", socket.id);
      });
    }
  }, [socket]);

  return [socket];
};
