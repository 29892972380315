/* eslint-disable no-undef */
export const API_PATH = process.env.REACT_APP_API_URL;
export const CDN_PATH = process.env.REACT_APP_CDN;
// export const CDN_PATH = "http://103.74.119.236:7007";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const ACCOUNT_ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  CUSTOMER_SERVICE: "customer_service",
};

export const DISH_STATUSES = {
  AVAILABLE: "available",
  UNAVAILABLE: "unavailable",
  SOLD_OUT: "sold-out",
};

export const DISH_STATUS_LABEL = {
  [DISH_STATUSES.AVAILABLE]: "Có sẵn",
  [DISH_STATUSES.UNAVAILABLE]: "Không khả dụng",
  [DISH_STATUSES.SOLD_OUT]: "Hết hàng",
};

export const formItemLayoutHorizontal = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

export const BANKS = [
  { code: "ICB", bin: "970415", name: "VietinBank" },
  { code: "VCB", bin: "970436", name: "Vietcombank" },
  { code: "BIDV", bin: "970418", name: "BIDV" },
  { code: "VBA", bin: "970405", name: "Agribank" },
  { code: "OCB", bin: "970448", name: "OCB" },
  { code: "MB", bin: "970422", name: "MBBank" },
  { code: "TCB", bin: "970407", name: "Techcombank" },
  { code: "ACB", bin: "970416", name: "ACB" },
  { code: "VPB", bin: "970432", name: "VPBank" },
  { code: "TPB", bin: "970423", name: "TPBank" },
  { code: "STB", bin: "970403", name: "Sacombank" },
  { code: "HDB", bin: "970437", name: "HDBank" },
  { code: "VCCB", bin: "970454", name: "VietCapitalBank" },
  { code: "SCB", bin: "970429", name: "SCB" },
  { code: "VIB", bin: "970441", name: "VIB" },
  { code: "SHB", bin: "970443", name: "SHB" },
  { code: "EIB", bin: "970431", name: "Eximbank" },
  { code: "MSB", bin: "970426", name: "MSB" },
  { code: "CAKE", bin: "546034", name: "CAKE" },
  { code: "Ubank", bin: "546035", name: "Ubank" },
  { code: "TIMO", bin: "963388", name: "Timo" },
  { code: "SGICB", bin: "970400", name: "SaigonBank" },
  { code: "BAB", bin: "970409", name: "BacABank" },
  { code: "PVCB", bin: "970412", name: "PVcomBank" },
  { code: "Oceanbank", bin: "970414", name: "Oceanbank" },
  { code: "NCB", bin: "970419", name: "NCB" },
  { code: "SHBVN", bin: "970424", name: "ShinhanBank" },
  { code: "ABB", bin: "970425", name: "ABBANK" },
  { code: "VAB", bin: "970427", name: "VietABank" },
  { code: "NAB", bin: "970428", name: "NamABank" },
  { code: "PGB", bin: "970430", name: "PGBank" },
  { code: "VIETBANK", bin: "970433", name: "VietBank" },
  { code: "BVB", bin: "970438", name: "BaoVietBank" },
  { code: "SEAB", bin: "970440", name: "SeABank" },
  { code: "COOPBANK", bin: "970446", name: "COOPBANK" },
  { code: "LPB", bin: "970449", name: "LienVietPostBank" },
  { code: "KLB", bin: "970452", name: "KienLongBank" },
  { code: "KBank", bin: "668888", name: "KBank" },
  { code: "KBHN", bin: "970462", name: "KookminHN" },
  { code: "KEBHANAHCM", bin: "970466", name: "KEBHanaHCM" },
  { code: "KEBHANAHN", bin: "970467", name: "KEBHANAHN" },
  { code: "MAFC", bin: "977777", name: "MAFC" },
  { code: "CITIBANK", bin: "533948", name: "Citibank" },
  { code: "KBHCM", bin: "970463", name: "KookminHCM" },
  { code: "VBSP", bin: "999888", name: "VBSP" },
  { code: "WVN", bin: "970457", name: "Woori" },
  { code: "VRB", bin: "970421", name: "VRB" },
  { code: "UOB", bin: "970458", name: "UnitedOverseas" },
  { code: "SCVN", bin: "970410", name: "StandardChartered" },
  { code: "PBVN", bin: "970439", name: "PublicBank" },
  { code: "NHB HN", bin: "801011", name: "Nonghyup" },
  { code: "IVB", bin: "970434", name: "IndovinaBank" },
  { code: "IBK - HCM", bin: "970456", name: "IBKHCM" },
  { code: "IBK - HN", bin: "970455", name: "IBKHN" },
  { code: "HSBC", bin: "458761", name: "HSBC" },
  { code: "HLBVN", bin: "970442", name: "HongLeong" },
  { code: "GPB", bin: "970408", name: "GPBank" },
  { code: "DOB", bin: "970406", name: "DongABank" },
  { code: "DBS", bin: "796500", name: "DBSBank" },
  { code: "CIMB", bin: "422589", name: "CIMB" },
  { code: "CBB", bin: "970444", name: "CBBank" },
];

export const defaultGeolocation = {
  lat: 21.016621,
  long: 105.814932,
};

export const MAPBOX_TOKEN = `pk.eyJ1IjoiZGlhZGllbWxrIiwiYSI6ImNseDM4ZjBlbjAzMjAyanF0MzJ6emlybzMifQ.dUiK4lSn2BCVb7NOO-2QDg`;

export const MAP_STYLE = `mapbox://styles/hoaiphan/ck864r8qf05om1inqvmp3phk4`;

export const mapboxStyles = {
  Basic: "mapbox://styles/hoaiphan/ck864r8qf05om1inqvmp3phk4",
  Street: "mapbox://styles/hoaiphan/ck879yu1t0xnh1iqlc4pwgbcd",
  Decimal: "mapbox://styles/hoaiphan/ck865rxlz01421imkp9e4k7rm",
  Light: "mapbox://styles/mapbox/light-v10",
};

export const WALLET_REQUEST_STATUS = {
  processing: {
    value: "processing",
    label: "Đang xử lý",
    color: "gold",
  },
  success: {
    value: "success",
    label: "Thành công",
    color: "green",
  },
  fail: {
    value: "fail",
    label: "Thất bại",
    color: "red",
  },
};

export const DELIVERY_STATUS = {
  finding: { state: "Đang tìm tài xế", color: "volcano", value: "finding" },
  "confirm-customer": {
    state: "Chờ xác nhận",
    color: "gold",
    value: "confirm-customer",
  },
  "going-to-restaurant": {
    state: "Đang đến nhà hàng",
    color: "magenta",
    value: "going-to-restaurant",
  },
  "arrived-restaurant": {
    state: "Đã đến nhà hàng",
    color: "cyan",
    value: "arrived-restaurant",
  },
  "picked-up": { state: "Đã lấy hàng", color: "green", value: "picked-up" },
};
