import { ExportOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../api";
import { downloadCSV } from "utils/common";

const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: "Hôm nay",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Hôm qua",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Tuần này",
    value: [dayjs().startOf("week"), dayjs()],
  },
  {
    label: "Tuần trước",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "Tháng này",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Tháng trước",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Tất cả",
    value: [dayjs("01/07/2023", "DD/MM/YYYY"), dayjs()],
  },
];

export default function RestaurantRevenue() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchValue, setSearchValue] = useState({
    restaurant: "",
    range: [dayjs(), dayjs()],
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  const onChangeSearchValue = (property, value) => {
    setSearchValue((prev) => ({ ...prev, [property]: value }));
  };

  const onChangeSearchRange = (dates) => {
    setSearchValue((prev) => ({
      ...prev,
      range: dates,
      from: dates[0].format("YYYY-MM-DD"),
      to: dates[1].format("YYYY-MM-DD"),
    }));
  };

  const onExport = async () => {
    if (searchValue.restaurant === "")
      return toast.error("Vui lòng nhập ID quán");

    setIsProcessing(true);
    try {
      const { restaurant, from, to } = searchValue;
      const data = await api.getRestaurantRevenue({ restaurant, from, to });
      if (data.error) return toast.error(data.error.msg);
      const headers = [
        { key: "code", title: "Mã đơn" },
        { key: "createdAt", title: "Ngày đặt" },
        { key: "status", title: "Trạng thái" },
        { key: "customer", title: "Tên khách hàng" },
        { key: "phone", title: "Số điện thoại" },
        { key: "address", title: "Địa chỉ" },
        { key: "deliveryFee", title: "Phí giao hàng" },
        { key: "dishFee", title: "Tổng hóa đơn" },
        { key: "restaurantCharge", title: "Tổng chiết khấu" },
        { key: "restaurantReceive", title: "Tổng thu về" },
      ];
      const csvRows = [headers.map((e) => e.title).join(",")];
      for (const item of data.data) {
        const values = headers.map((e) => e.key).map((header) => item[header]);
        csvRows.push(values.join(","));
      }
      const csvData = csvRows.join("\n");
      downloadCSV(csvData, data.fileName || "report.csv");
    } catch (error) {
      console.log(error);
      toast.error("Có lỗi xảy ra");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          Báo cáo lịch sử bán hàng của quán
        </Typography.Title>
        <Space size="middle">
          <RangePicker
            presets={rangePresets}
            value={searchValue.range}
            onChange={onChangeSearchRange}
          />
          <Input
            placeholder="Nhập ID quán"
            onChange={(e) => onChangeSearchValue("restaurant", e.target.value)}
            allowClear
            style={{ width: 250 }}
            value={searchValue.restaurant}
          />
          <Button
            onClick={onExport}
            icon={<ExportOutlined />}
            type="primary"
            loading={isProcessing}
          >
            Export
          </Button>
        </Space>
      </Row>
    </>
  );
}
