import axiosClient from "./axios-client";

const commonApi = {
  generatePassword: () => {
    const url = `/api/v1/generate-password`;
    return axiosClient.get(url);
  },
  searchRestaurant: async (key, limit = 10) => {
    const url = `/api/v1/restaurants/suggest-search`;
    try {
      const res = await axiosClient.get(url, { params: { key, limit } });
      return res.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  searchUser: async (key, limit = 10) => {
    const url = `/api/v1/users/suggest-search`;
    try {
      const res = await axiosClient.get(url, { params: { key, limit } });
      return res.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  getPhotos: (params) => {
    const url = "/api/v1/upload-photos";
    return axiosClient.get(url, { params });
  },
  uploadPhotos: (data) => {
    const url = `/api/v1/upload-photos/`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, data, config);
  },
  getBanks: () => {
    const url = `/api/v1/banks`;
    return axiosClient.get(url);
  },
};

export default commonApi;
