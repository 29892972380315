import axiosClient from "utils/api/axios-client";

const eventApi = {
  getEventData: () => {
    const url = "/api/v1/event/";
    return axiosClient.get(url);
  },

  update: (dishId) => {
    const url = `/api/v1/event`;
    return axiosClient.put(url, { dishId });
  },

  stop: () => {
    const url = `/api/v1/event`;
    return axiosClient.delete(url);
  },
};

export default eventApi;
