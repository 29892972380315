import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "../slice";

export default function FeeCharge() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { config, isLoading } = useSelector((state) => state.config);
  const [isDeliveryExtraActive, setIsDeliveryExtraActive] = useState(false);
  const [isShipperPointExtraActive, setIsShipperPointExtraActive] =
    useState(false);
  const [deliveryExtraValueType, setDeliveryExtraValueType] =
    useState("percent");
  const [isMaintenanceModeEnable, setIsMaintenanceModeEnable] = useState(false);
  const [isNoteOrderShow, setIsNoteOrderShow] = useState(false);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(config);
    setIsDeliveryExtraActive(config.deliveryExtraActive);
    setIsShipperPointExtraActive(config.shipperPointExtraActive);
    setDeliveryExtraValueType(config.deliveryExtraValueType);
  }, [config]);

  const initialValues = {
    maintenanceMode: {
      enable: false,
      message: "Bạn không thể đặt đơn lúc này do hệ thống đang bảo trì",
    },
    noteOrder: {
      show: false,
      message: "Đơn hàng có thể giao chậm hơn dự kiến vì lý do thời tiết",
    },
    minDistanceDelivery: 2,
    deliveryExtraActive: false,
    deliveryExtraValueType: "percent",
    deliveryExtraValue: 0,
    deliveryExtraPercent: 0, //deprecated
    deliveryExtraReason: "",
    deliveryExtraPercentAtNight: 0,
    deliveryExtraReasonAtNight: "",
    shipperPointExtraActive: false,
    shipperPointExtraValue: 0,
    fees: {
      toDoor: 8000,
      minDelivery: 12000,
      service: 0,
      deliveryPerKm: 5000,
    },
    charge: {
      restaurant: 10,
      shipper: 5,
    },
    orderDivision: {
      sortShipperBy: "random",
      maxTimeFindingShipper: 600,
      maxTimeAccept: 60,
      maxShipperPerTime: 5,
      maxShipperOrderRunning: 2,
      timeChangeOrderToFreePick: 300,
      maxDistanceToReceiveOrder: 12,
    },
    paymentMethod: {
      tooltip: "Thanh toán bằng ví Goka, mã QR hoặc tiền mặt",
      codAvailable: true,
      codNote: "",
      gokaWalletAvailable: true,
      gokaWalletNote: "Thanh toán bằng ví Goka",
      qrCodeAvailable: true,
      qrCodeNote: "Thanh toán bằng mã QR",
      atmAvailable: false,
      atmNote: "Đang bảo trì",
    },
    transfer: {
      enable: false,
      maxAmount: 500000,
      maxCountPerDay: 3,
    },
  };

  const onValueChange = (changedValues, allValues) => {
    if (changedValues.deliveryExtraActive !== undefined) {
      setIsDeliveryExtraActive(allValues.deliveryExtraActive);
    } else if (changedValues.shipperPointExtraActive !== undefined) {
      setIsShipperPointExtraActive(allValues.shipperPointExtraActive);
    } else if (changedValues.deliveryExtraValueType !== undefined) {
      setDeliveryExtraValueType(allValues.deliveryExtraValueType);
    } else if (changedValues.maintenanceMode?.enable !== undefined) {
      setIsMaintenanceModeEnable(allValues.maintenanceMode.enable);
    } else if (changedValues.noteOrder?.show !== undefined) {
      setIsNoteOrderShow(allValues.noteOrder.show);
    }
  };

  const onFinish = (config) => {
    dispatch(updateConfig({ ...config }));
  };

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 1000 }}
      initialValues={initialValues}
      autoComplete="off"
      onValuesChange={onValueChange}
      onFinish={onFinish}
    >
      <Divider orientation="left">Bật/Tắt bảo trì hệ thống</Divider>

      <Form.Item
        label="Chế độ bảo trì"
        name={["maintenanceMode", "enable"]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Tin nhắn thông báo"
        name={["maintenanceMode", "message"]}
      >
        <Input disabled={!isMaintenanceModeEnable} />
      </Form.Item>
      <br />
      <Divider orientation="left">Thiết lập phí</Divider>
      <Form.Item
        label="Phí dịch vụ (đ)"
        name={["fees", "service"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Phí ship / km (đ)"
        name={["fees", "deliveryPerKm"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Phí ship tối thiểu (đ)"
        name={["fees", "minDelivery"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Khoảng cách tối thiểu (km)"
        name={"minDistanceDelivery"}
        rules={[
          {
            required: true,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Tăng phí ship"
        name={"deliveryExtraActive"}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Tăng phí ship theo"
        name={"deliveryExtraValueType"}
        rules={[
          {
            required: isDeliveryExtraActive,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <Select style={{ width: 200 }} disabled={!isDeliveryExtraActive}>
          <Select.Option value="percent">Phần trăm phí ship (%)</Select.Option>
          <Select.Option value="fixed">Số tiền cố định (đ)</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Giá trị tăng"
        name={"deliveryExtraValue"}
        rules={[
          {
            required: isDeliveryExtraActive,
            message: "Trường này là bắt buộc",
          },
          {
            type: "number",
            max: deliveryExtraValueType === "percent" ? 100 : 10000000000,
            message:
              deliveryExtraValueType === "percent"
                ? "% không được lớn hơn 100"
                : "",
          },
        ]}
      >
        <InputNumber
          disabled={!isDeliveryExtraActive}
          min={0}
          max={deliveryExtraValueType === "percent" ? 100 : 10000000000}
        />
      </Form.Item>
      {/* <Form.Item
        label="% Tăng phí ship"
        name={"deliveryExtraPercent"}
        rules={[
          {
            required: isDeliveryExtraActive,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <InputNumber disabled={!isDeliveryExtraActive} min={0} />
      </Form.Item> */}
      <Form.Item
        label="Lý do tăng"
        name={"deliveryExtraReason"}
        rules={[
          {
            required: isDeliveryExtraActive,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <Input disabled={!isDeliveryExtraActive} />
      </Form.Item>
      <Form.Item
        label="Tăng điểm cho tài xế"
        name={"shipperPointExtraActive"}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Số điểm tăng (★)"
        name={"shipperPointExtraValue"}
        rules={[
          {
            required: isShipperPointExtraActive,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <InputNumber disabled={!isShipperPointExtraActive} min={0} />
      </Form.Item>
      <Form.Item
        label="Lý do tăng phí ship ban đêm"
        name={"deliveryExtraReasonAtNight"}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tăng phí ship ban đêm (%)"
        name={"deliveryExtraPercentAtNight"}
        rules={[
          {
            required: true,
            message: "Trường này là bắt buộc",
          },
          {
            type: "number",
            max: 100,
            message: "% không được lớn hơn 100",
          },
        ]}
      >
        <InputNumber min={0} max={100} />
      </Form.Item>
      <Form.Item
        label="Phí giao tận cửa (đ)"
        name={["fees", "toDoor"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <br />
      <Divider orientation="left">Thiết lập chiết khấu</Divider>
      <Form.Item
        label="Chiết khấu quán"
        name={["charge", "restaurant"]}
        rules={[
          { required: true, message: "Trường này là bắt buộc" },
          {
            type: "number",
            max: 100,
            message: "Chiết khấu quán không được lớn hơn 100%",
          },
        ]}
      >
        <InputNumber
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
        />
      </Form.Item>
      <Form.Item
        label="Chiết khấu shipper"
        name={["charge", "shipper"]}
        rules={[
          { required: true, message: "Trường này là bắt buộc" },
          {
            type: "number",
            max: 100,
            message: "Chiết khấu shipper không được lớn hơn 100%",
          },
        ]}
      >
        <InputNumber
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
        />
      </Form.Item>
      <br />
      <Divider orientation="left">Thiết lập phương thức thanh toán</Divider>
      <Form.Item label="Ghi chú tooltip" name={["paymentMethod", "tooltip"]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Thanh toán tiền mặt"
        name={["paymentMethod", "codAvailable"]}
      >
        <Switch />
      </Form.Item>
      <Form.Item label="Mô tả tiền mặt" name={["paymentMethod", "codNote"]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Thanh toán ví Goka"
        name={["paymentMethod", "gokaWalletAvailable"]}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Mô tả ví Goka"
        name={["paymentMethod", "gokaWalletNote"]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Thanh toán QR code"
        name={["paymentMethod", "qrCodeAvailable"]}
      >
        <Switch />
      </Form.Item>
      <Form.Item label="Mô tả QR code" name={["paymentMethod", "qrCodeNote"]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Thanh toán ATM"
        name={["paymentMethod", "atmAvailable"]}
      >
        <Switch defaultChecked={false} disabled />
      </Form.Item>
      <Form.Item label="Mô tả ATM" name={["paymentMethod", "atmNote"]}>
        <Input disabled />
      </Form.Item>
      <br />
      <Divider orientation="left">Thiết lập phân phối đơn</Divider>
      <Form.Item
        label="Thứ tự ưu tiên tài xế"
        name={["orderDivision", "sortShipperBy"]}
      >
        <Select style={{ width: 180 }}>
          <Select.Option value="random">Ngẫu nhiên</Select.Option>
          <Select.Option value="distance">Khoảng cách</Select.Option>
          <Select.Option value="priority">Độ ưu tiên</Select.Option>
          <Select.Option value="weeklyPoint">Điểm hiệu suất</Select.Option>
          <Select.Option value="round-robin">Xoay vòng</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Thời gian tối đa tìm tài xế (s)"
        name={["orderDivision", "maxTimeFindingShipper"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Thời gian tối đa tài xế chấp nhận đơn (s)"
        name={["orderDivision", "maxTimeAccept"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Số lượng tài xế mỗi lần bắn đơn"
        name={["orderDivision", "maxShipperPerTime"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Số đơn tối đa mỗi tài xế chạy cùng lúc"
        name={["orderDivision", "maxShipperOrderRunning"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Thời gian chuyển đơn thành FREEPICK (s)"
        name={["orderDivision", "timeChangeOrderToFreePick"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Khoảng cách tối đa tài xế nhận đơn (km)"
        name={["orderDivision", "maxDistanceToReceiveOrder"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <br />
      <Divider orientation="left">Lưu ý khi đặt đơn</Divider>

      <Form.Item
        label="Hiển thị lưu ý"
        name={["noteOrder", "show"]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item label="Nội dung" name={["noteOrder", "message"]}>
        <Input disabled={!isNoteOrderShow} />
      </Form.Item>
      <br />
      <Divider orientation="left">Chi hộ</Divider>

      <Form.Item
        label="Bật chi hộ"
        name={["transfer", "enable"]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Số lần chi hộ tối đa /1 ngày"
        name={["transfer", "maxCountPerDay"]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Số tiền chi hộ tối đa /1 giao dịch"
        name={["transfer", "maxAmount"]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <br />
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Cập nhật
        </Button>
      </Form.Item>
    </Form>
  );
}
