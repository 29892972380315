import { Form, Input, Tag } from "antd";
import moment from "moment";

export default function TabBlock({ user }) {
  return (
    <>
      <Form.Item name="blocked" label="Trạng thái">
        {user.blocked ? (
          <Tag color="#f44336">Bị chặn</Tag>
        ) : (
          <Tag color="#4caf50">Bình thường</Tag>
        )}
      </Form.Item>
      <Form.Item name="blockedBy" label="Bị chặn bởi">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="blockedReason" label="Lý do chặn">
        <Input.TextArea readOnly rows={6} />
      </Form.Item>
      <Form.Item name="blockedAt" label="Thời gian chặn">
        <Tag>{moment(user.blockedAt).format("DD/MM/YYYY HH:mm")}</Tag>
      </Form.Item>
      <Form.Item name="blockedTo" label="Thời hạn mở khóa">
        <Tag>{moment(user.blockedTo).format("DD/MM/YYYY HH:mm")}</Tag>
      </Form.Item>
    </>
  );
}
