import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
  transactions: [],
  pagination: { current: 1, pageSize: 10 },
  isLoading: false,
  isProcessing: false,
  isCompleted: false,
};

export const walletTransactionSlice = createSlice({
  name: "walletTransaction",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessing: (state, action) => {
      if (action.payload === true) state.isCompleted = false;
      state.isProcessing = action.payload;
    },
    setIsCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    addTransaction: (state, action) => {
      state.isCompleted = true;
      state.transactions.unshift(action.payload);
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setIsCompleted,
  setIsProcessing,
  setTransactions,
  addTransaction,
  setPagination,
} = walletTransactionSlice.actions;

export const fetchTransactions = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const data = await api.getWalletTransactions(params);
      dispatch(setTransactions(data.data));
      dispatch(setPagination(data.meta));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createWithdrawRequest = (request) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const data = await api.createWalletWithdrawRequest(request);
      if (data.error) {
        toast.error(data.error.msg);
      } else {
        dispatch(addTransaction(data.transaction));
        toast.success(`Tạo lệnh rút tiền thành công!`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};
export default walletTransactionSlice.reducer;
