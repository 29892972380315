import { Form, Input, Modal, Typography } from "antd";
import { useState } from "react";

export default function HideReviewModal(props) {
  const { open, isProcessing, onSubmit, onCancel, review } = props;
  const [hiddenReason, setHiddenReason] = useState("");
  const isReviewHidden = review.isHidden;

  const onChangeHiddenReason = (e) => {
    setHiddenReason(e.target.value);
  };

  return (
    <Modal
      title={`Xác nhận ${isReviewHidden ? "hiện" : "ẩn"} đánh giá`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(review, hiddenReason)}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Typography.Paragraph>
        Bạn có chắc chắn muốn {isReviewHidden ? "hiện" : "ẩn"} đánh giá này?
      </Typography.Paragraph>
      {!isReviewHidden && (
        <Form layout="vertical">
          <Form.Item label="Lý do ẩn">
            <Input.TextArea
              onChange={onChangeHiddenReason}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
