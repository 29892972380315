import {
  IssuesCloseOutlined,
  SearchOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Image,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import { MAPBOX_TOKEN } from "utils/constants";
import Actions from "./components/Actions";
import ChatModal from "./components/ChatModal";
import ConfirmCancelModal from "./components/ConfirmCancelModal";
import OrderCostModal from "./components/CostDrawer";
import OrderDetailDrawer from "./components/DetailDrawer";
import RejectModal from "./components/RejectModal";
import EditHistoryModal from "./components/EditHistoryModal";
import * as orderActions from "./slice";

const { RangePicker } = DatePicker;

const STATUS = {
  "wait-pay": { state: "Chờ thanh toán", color: "purple" },
  ordered: { state: "Tìm tài xế", color: "cyan" },
  processing: { state: "Đang thưc hiện", color: "orange" },
  done: { state: "Hoàn thành", color: "green" },
  canceled: { state: "Đã Hủy", color: "red" },
};

const PAYMENT_METHODS = {
  cod: "Tiền mặt",
  qrcode: "VietQR",
  "goka-wallet": "Ví Goka",
};

const rangePresets = [
  {
    label: "Hôm nay",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Hôm qua",
    value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
  },
  {
    label: "Tuần này",
    value: [dayjs().startOf("week"), dayjs()],
  },
  {
    label: "Tuần trước",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "Tháng này",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Tháng trước",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Tất cả",
    value: [dayjs("01/07/2023", "DD/MM/YYYY"), dayjs()],
  },
];

function Order() {
  const dispatch = useDispatch();
  const { orders, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.order);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [openCostDrawer, setOpenCostDrawer] = useState(false);
  const [openRejectDrawer, setOpenRejectDrawer] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [selectingOrder, setSelectingOrder] = useState(null);
  const [searchValue, setSearchValue] = useState({
    code: "",
    status: "",
    customer: "",
    restaurant: "",
    shipper: "",
    paymentMethod: "",
    range: [dayjs(), dayjs()],
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });
  const [searchState, setSearchState] = useState({
    code: "",
    status: "",
    customer: "",
    restaurant: "",
    shipper: "",
    paymentMethod: "",
    range: [dayjs(), dayjs()],
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });
  const [directionImage, setDirectionImage] = useState("");
  const [showDirectionImage, setVisibleDirectionImage] = useState(false);
  const [openEditHistoryModal, setOpenEditHistoryModal] = useState(false);

  useEffect(() => {
    dispatch(orderActions.fetchOrders(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenCancelModal(false);
      setSelectingOrder(null);
    }
  }, [isCompleted]);

  const onVisibleDirectionImage = (record) => {
    setDirectionImage(
      `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-a+000(${
        record.routes.restaurant.long
      },${record.routes.restaurant.lat}),pin-l-b+f00(${
        record.routes.customer.long
      },${record.routes.customer.lat}),path-5+5c00c1-1(${encodeURIComponent(
        record.routes.polyline
      )})/auto/800x560?access_token=${MAPBOX_TOKEN}`
    );
    setVisibleDirectionImage(true);
  };

  const onVisibleDirectionImageChange = (value) => {
    setVisibleDirectionImage(value);
    setDirectionImage(null);
  };

  const onOpenDetailModal = (order) => {
    setSelectingOrder(order);
    setOpenDetailDrawer(true);
  };

  const onOpenCostModal = (order) => {
    setSelectingOrder(order);
    setOpenCostDrawer(true);
  };

  const onOpenRejectModal = (order) => {
    setSelectingOrder(order);
    setOpenRejectDrawer(true);
  };

  const onOpenChatModal = (order) => {
    setSelectingOrder(order);
    setOpenChatModal(true);
  };

  const onOpenEditHistoryModal = (order) => {
    setSelectingOrder(order);
    setOpenEditHistoryModal(true);
  };

  const onCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseCostDrawer = () => {
    setOpenCostDrawer(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseRejectDrawer = () => {
    setOpenRejectDrawer(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseCancelModal = () => {
    setOpenCancelModal(false);
    setSelectingOrder(null);
    dispatch(orderActions.setIsProcessing(false));
  };

  const onCloseChatModal = () => {
    setOpenChatModal(false);
    setSelectingOrder(null);
  };

  const onOpenCancelOrderModal = (order) => {
    setSelectingOrder(order);
    setOpenCancelModal(true);
  };

  const onCloseEditHistoryModal = () => {
    setOpenEditHistoryModal(false);
    setSelectingOrder(null);
  };

  const onChangeSearchValue = (property, value, refresh) => {
    setSearchValue((prev) => ({ ...prev, [property]: value }));
    if (refresh) {
      setSearchState((prev) => ({ ...prev, [property]: value }));
      dispatch(
        orderActions.fetchOrders({
          ...pagination,
          ...searchValue,
          [property]: value,
        })
      );
    }
  };

  const onChangeSearchRange = (dates) => {
    setSearchValue((prev) => ({
      ...prev,
      range: dates,
      from: dates[0].format("YYYY-MM-DD"),
      to: dates[1].format("YYYY-MM-DD"),
    }));
    setSearchState((prev) => ({
      ...prev,
      range: dates,
      from: dates[0].format("YYYY-MM-DD"),
      to: dates[1].format("YYYY-MM-DD"),
    }));
    dispatch(
      orderActions.fetchOrders({
        ...pagination,
        ...searchValue,
        from: dates[0].format("YYYY-MM-DD"),
        to: dates[1].format("YYYY-MM-DD"),
      })
    );
  };

  const onSearch = () => {
    setSearchState(searchValue);
    dispatch(
      orderActions.fetchOrders({
        ...pagination,
        ...searchValue,
      })
    );
  };

  const onTableChange = async (pagination) => {
    dispatch(
      orderActions.fetchOrders({
        ...pagination,
        ...searchState,
      })
    );
  };

  const onCancelOrder = (order, reason) => {
    dispatch(orderActions.cancelOrder(order._id, reason));
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <Typography.Paragraph
          copyable={{
            text: record._id,
            tooltips: ["Sao chép ID", "Đã sao chép!"],
          }}
        >
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Mã đơn",
      // dataIndex: "code",
      width: "8%",
      render: (record) => (
        <Typography.Text strong>
          #{record.code}
          {record.paymentMethod !== "cod" ? (
            <>
              <br />
              <Tag color="#f50">{PAYMENT_METHODS[record.paymentMethod]}</Tag>
            </>
          ) : null}
        </Typography.Text>
      ),
    },
    {
      title: "Quán",
      // dataIndex: ["routes", "restaurant"],
      width: "16%",
      render: (record) => (
        <>
          <Typography.Text strong>
            {record.routes.restaurant?.name}
          </Typography.Text>
          <br />

          <a
            href={`https://www.google.com/maps?q=${record.routes.restaurant.lat},${record.routes.restaurant.long}`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title={record.routes.restaurant?.address}>
              <Typography.Text className="whitespace-nowrap">
                {record.routes.restaurant?.address?.slice(0, 25) +
                  (record.routes.restaurant?.address?.length > 25 ? "..." : "")}
              </Typography.Text>
            </Tooltip>
          </a>
          <Button
            size="small"
            type="link"
            onClick={() => onVisibleDirectionImage(record)}
          >
            <SwapRightOutlined />
            Xem đường đi
          </Button>
          {record.rejectCount ? (
            <>
              <br />
              <Typography.Text type="warning">{`(${record.rejectCount} tài xế bỏ qua)`}</Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: "Khách hàng",
      width: "16%",
      render: (record) => (
        <>
          <Typography.Text strong>
            {record.routes.customer?.name}
          </Typography.Text>
          <br />
          <Typography.Text>{record.routes.customer?.phone}</Typography.Text>
          <br />
          <a
            href={`https://www.google.com/maps?q=${record.routes.customer.lat},${record.routes.customer.long}`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip title={record.routes.customer?.address}>
              <Typography.Text>
                {record.routes.customer?.address?.slice(0, 25) +
                  (record.routes.customer?.address?.length > 25 ? "..." : "")}
              </Typography.Text>
            </Tooltip>
          </a>
          <Button
            size="small"
            type="link"
            onClick={() => onVisibleDirectionImage(record)}
          >
            <SwapRightOutlined />
            Xem đường đi
          </Button>
          {record.chatCount ? (
            <>
              <br />
              <Typography.Text type="warning">{`(${record.chatCount} tin nhắn)`}</Typography.Text>
            </>
          ) : null}
        </>
      ),
    },
    // {
    //   title: "Tài xế",
    //   dataIndex: "shipper",
    //   width: "12%",
    //   render: (shipper) =>
    //     shipper && (
    //       <>
    //         <Typography.Text strong>{shipper.name}</Typography.Text><br/>
    //         <Typography.Text>
    //           {shipper.phone}
    //         </Typography.Text>
    //       </>
    //     ),
    // },
    {
      title: "Trạng thái",
      dataIndex: "status",
      width: "150px",
      render: (status) => (
        <Tag color={STATUS[status].color}>{STATUS[status].state}</Tag>
      ),
    },
    // {
    //   title: "Số món",
    //   dataIndex: "dishCount",
    // },
    {
      title: "Tổng tiền",
      width: "200px",
      render: (record) => (
        <>
          <Typography.Text>
            Tiền món: {priceToCurrency(record.dishFeeActual)}
          </Typography.Text>
          <br />
          <Typography.Text>
            Tiền ship: {priceToCurrency(record.deliveryFeeActual)} (
            {record.distance}km)
          </Typography.Text>
          <br />
          <Typography.Text>
            Giảm giá: {priceToCurrency(record.discount)}
          </Typography.Text>
          <br />
          <Typography.Text>
            App nhận: {priceToCurrency(record.appReceiveActual)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            Tổng tiền: {priceToCurrency(record.total)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            Điểm nhận: {record.shipperPoint || 0} ★
          </Typography.Text>
          {record.editedCount ? (
            <>
              <br />
              <Typography.Text strong type="danger">
                <IssuesCloseOutlined /> {`Đã sửa: ${record.editedCount} lần`}
              </Typography.Text>
            </>
          ) : null}
        </>
      ),
      // render: (totalActual) => priceToCurrency(totalActual),
    },
    // {
    //   title: "Khoảng cách",
    //   dataIndex: "distance",
    //   render: (distance) => `${distance}km`,
    // },
    {
      title: "Thời gian",
      width: "180px",
      render: (record) => {
        let canceledReason = "";
        if (record.canceledAt) {
          if (record.canceledBy === "merchant") {
            canceledReason = `[Quán] ${record.canceledReason}`;
          } else if (record.canceledBy === "shipper") {
            canceledReason = `[Tài xế] ${record.canceledReason}`;
          } else if (record.canceledBy === "admin") {
            canceledReason = `[Hệ thống] ${record.canceledReason}`;
          } else if (record.canceledBy === "user") {
            canceledReason = `[Khách] ${record.canceledReason}`;
          }
        }
        return (
          <>
            <Typography.Text>
              Đặt đơn: {moment(record.orderedAt).format("HH:mm")}
            </Typography.Text>
            <br />
            {record.paidAt ? (
              <>
                {" "}
                <Typography.Text>
                  Thanh toán: {moment(record.paidAt).format("HH:mm")}
                </Typography.Text>
                <br />
              </>
            ) : null}
            <Typography.Text>
              Nhận đơn:{" "}
              {record.assignedAt
                ? moment(record.assignedAt).format("HH:mm")
                : "-"}
            </Typography.Text>
            <br />
            {record.confirmedAt ? (
              <>
                {" "}
                <Typography.Text>
                  Xác nhận: {moment(record.confirmedAt).format("HH:mm")}
                </Typography.Text>
                <br />
              </>
            ) : null}
            {record.arrivedAt ? (
              <>
                {" "}
                <Typography.Text>
                  Đến quán: {moment(record.arrivedAt).format("HH:mm")}
                </Typography.Text>
                <br />
              </>
            ) : null}
            {record.pickupAt ? (
              <>
                {" "}
                <Typography.Text>
                  Lấy đơn: {moment(record.pickupAt).format("HH:mm")}
                </Typography.Text>
                <br />
              </>
            ) : null}
            {record.deliveredAt ? (
              <>
                {" "}
                <Typography.Text>
                  Đã giao: {moment(record.deliveredAt).format("HH:mm")}
                </Typography.Text>
                <br />
              </>
            ) : null}
            {record.canceledAt ? (
              <>
                {" "}
                <Typography.Text>
                  Đã hủy: {moment(record.canceledAt).format("HH:mm")}
                </Typography.Text>
                <br />
              </>
            ) : null}
            {record.canceledReason ? (
              <>
                {" "}
                <Typography.Text>Hủy bởi: {canceledReason}</Typography.Text>
                <br />
              </>
            ) : null}
            {record.shipper ? (
              <>
                {" "}
                <Typography.Text strong>
                  Tài xế: {record.shipper.name}
                </Typography.Text>
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Thao tác",
      align: "center",
      width: "12%",
      render: (record) => (
        <Actions
          record={record}
          onViewDetail={onOpenDetailModal}
          onViewCost={onOpenCostModal}
          onViewReject={onOpenRejectModal}
          onCancel={onOpenCancelOrderModal}
          onViewChat={onOpenChatModal}
          onViewEditHistory={onOpenEditHistoryModal}
        />
      ),
    },
  ];

  const orderStatusOptions = [
    {
      label: "Tất cả trạng thái",
      value: "",
    },
    {
      label: "Đang tìm tài xế",
      value: "ordered",
    },
    {
      label: "Đang thưc hiện",
      value: "processing",
    },
    {
      label: "Hoàn thành",
      value: "done",
    },
    {
      label: "Đã hủy",
      value: "canceled",
    },
  ];

  const paymentMethodOptions = [
    {
      label: "Tất cả phương thức",
      value: "",
    },
    {
      label: "Tiền mặt",
      value: "cod",
    },
    {
      label: "VietQR",
      value: "qrcode",
    },
    {
      label: "Ví Goka",
      value: "goka-wallet",
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>
          [{pagination?.total || "0"}] Lịch sử đơn hàng
        </Typography.Title>
        <Space size="middle">
          <RangePicker
            presets={rangePresets}
            value={searchValue.range}
            onChange={onChangeSearchRange}
            style={{ width: "250px" }}
          />
          <Select
            options={orderStatusOptions}
            onChange={(value) => onChangeSearchValue("status", value, true)}
            value={searchValue.status}
            allowClear
            placeholder="Trạng thái đơn hàng"
            style={{ width: "160px" }}
          />
          <Select
            options={paymentMethodOptions}
            onChange={(value) =>
              onChangeSearchValue("paymentMethod", value, true)
            }
            value={searchValue.paymentMethod}
            allowClear
            placeholder="Phương thức thanh toán"
            style={{ width: "180px" }}
          />
          <Input
            placeholder="Mã đơn hàng"
            onChange={(e) => onChangeSearchValue("code", e.target.value)}
            allowClear
            value={searchValue.code}
          />
          <Input
            placeholder="SĐT khách hàng"
            onChange={(e) => onChangeSearchValue("customer", e.target.value)}
            allowClear
            value={searchValue.customer}
          />
          <Input
            placeholder="ID quán"
            onChange={(e) => onChangeSearchValue("restaurant", e.target.value)}
            allowClear
            value={searchValue.restaurant}
          />
          <Input
            placeholder="ID tài xế"
            onChange={(e) => onChangeSearchValue("shipper", e.target.value)}
            allowClear
            value={searchValue.shipper}
          />
          <Button onClick={onSearch} icon={<SearchOutlined />} type="primary">
            Tìm kiếm
          </Button>
        </Space>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={orders}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
      {openDetailDrawer && (
        <OrderDetailDrawer
          open={openDetailDrawer}
          onClose={onCloseDetailDrawer}
          order={selectingOrder}
          onViewCost={() => {
            setOpenDetailDrawer(false);
            setOpenCostDrawer(true);
          }}
        />
      )}
      {openCostDrawer && (
        <OrderCostModal
          open={openCostDrawer}
          onClose={onCloseCostDrawer}
          order={selectingOrder}
        />
      )}
      {openRejectDrawer && (
        <RejectModal
          open={openRejectDrawer}
          onClose={onCloseRejectDrawer}
          order={selectingOrder}
        />
      )}
      {openCancelModal && (
        <ConfirmCancelModal
          open={openCancelModal}
          onClose={onCloseCancelModal}
          order={selectingOrder}
          onSubmit={onCancelOrder}
          loading={isProcessing}
        />
      )}
      {openChatModal && (
        <ChatModal
          open={openChatModal}
          onClose={onCloseChatModal}
          order={selectingOrder}
        />
      )}
      {openEditHistoryModal && (
        <EditHistoryModal
          open={openEditHistoryModal}
          onClose={onCloseEditHistoryModal}
          order={selectingOrder}
        />
      )}
      <Image
        width={200}
        style={{
          display: "none",
        }}
        preview={{
          visible: showDirectionImage,
          src: directionImage,
          destroyOnClose: true,
          onVisibleChange: (value) => onVisibleDirectionImageChange(value),
        }}
      />
    </>
  );
}

export default Order;
