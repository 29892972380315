import { PlusOutlined } from "@ant-design/icons";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { Form, Input, Modal, Select, Upload } from "antd";
import SelectPhotoModal from "components/common/SelectPhotoModal";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

const { TextArea } = Input;
const { Option } = Select;

function MessageDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onSubmit, onCancel, message } = props;
  const [imageUrl, setImageUrl] = useState(
    getPhotoUrl(message?.image?.path || null, "w=200&h=102")
  );
  const [content, setContent] = useState("");
  const [visiblePhotoModal, setVisiblePhotoModal] = useState(false);
  const [image, setImage] = useState(null);
  const [receiverType, setReceiverType] = useState(
    message?.receiverType || "all"
  );

  const initialValues = message
    ? {
        ...message,
        receiver: message.receiver?.join(", ") || "",
        sendTime: dayjs(message.sendTime),
      }
    : {
        title: "",
        content: "",
        receiverModel: "Merchant",
        receiverType: "all",
        receiver: "",
        deleted: false,
        url: "",
      };
  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.receiverType) {
      setReceiverType(allValues.receiverType);
    }
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        const message = { ...initialValues, ...values };
        Object.entries({
          data: JSON.stringify(message),
          image,
        }).forEach(([key, value]) => {
          formData.append(key, value);
        });
        onSubmit(message, formData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const beforeUpload = async (file) => {
    const validType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!validType) {
      toast.error("Chỉ chấp nhận file ảnh!");
    } else {
      const preview = await getBase64(file);
      setImageUrl(preview);
      setImage(file);
    }
    return false;
  };

  const handleOpenPhotoModal = async () => {
    setVisiblePhotoModal(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal
        title="Tin nhắn"
        okText="Lưu"
        cancelText="Huỷ"
        centered
        open={open}
        onOk={onOK}
        onCancel={onCancel}
        confirmLoading={isProcessing}
        width={960}
      >
        <Form
          {...formItemLayoutHorizontal}
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="title"
            label="Tiêu đề"
            rules={[
              {
                required: true,
                message: "Trường này là bắt buộc",
              },
            ]}
          >
            <Input placeholder="Tiêu đề" />
          </Form.Item>
          <Form.Item
            name="content"
            label="Nội dung"
            rules={[
              {
                required: true,
                message: "Trường này là bắt buộc",
              },
            ]}
          >
            {/* <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder="Nội dung tin nhắn"
          /> */}
            <MDEditor
              value={content}
              onChange={setContent}
              height={320}
              commands={[
                { ...commands.title1, icon: "H1" },
                { ...commands.title2, icon: "H2" },
                { ...commands.title3, icon: "H3" },
                { ...commands.title4, icon: "H4" },
                { ...commands.title5, icon: "H5" },
                commands.divider,
                commands.bold,
                commands.italic,
                commands.strikethrough,
                commands.divider,
                {
                  ...commands.link,
                  execute: (state, api) => {
                    let text = state.selectedText || "text";
                    let template = `[${text}](url)`;
                    api.replaceSelection(template);
                  },
                },
                {
                  ...commands.image,
                  execute: (state, api) => {
                    handleOpenPhotoModal();
                    let alt = state.selectedText || "alt";
                    let template = `![${alt}](url)`;
                    api.replaceSelection(template);
                  },
                },
                commands.quote,
                commands.code,
                commands.codeBlock,
                commands.divider,
                commands.unorderedListCommand,
                commands.orderedListCommand,
                commands.checkedListCommand,
              ]}
            />
          </Form.Item>

          <Form.Item name="receiverModel" label="Tin nhắn tới">
            <Select>
              <Option value={"User"}>Người dùng</Option>
              <Option value={"Merchant"}>Chủ quán</Option>
              <Option value={"Shipper"}>Shipper</Option>
            </Select>
          </Form.Item>

          <Form.Item name="receiverType" label="Người nhận">
            <Select>
              <Option value={"all"}>Tất cả</Option>
              <Option value={"specific"}>Tùy chọn</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="receiver"
            label="DS người nhận"
            rules={[
              {
                required: receiverType !== "all",
                message: "Trường này là bắt buộc",
              },
            ]}
          >
            <TextArea
              disabled={receiverType === "all"}
              autoSize={{ minRows: 2, maxRows: 5 }}
              placeholder="Danh sách ID người nhận cách nhau dấu ,"
            />
          </Form.Item>

          <Form.Item label="Hình ảnh">
            <Upload
              accept={"image/*"}
              name="image"
              listType="picture-card"
              className="avatar-uploader-cover"
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="image" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item name="url" label="URL">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {visiblePhotoModal && (
        <SelectPhotoModal
          open={visiblePhotoModal}
          onClose={() => setVisiblePhotoModal(false)}
        />
      )}
    </>
  );
}

MessageDetailModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.object,
};

MessageDetailModal.defaultProps = {
  isProcessing: false,
  open: false,
  message: null,
};

export default MessageDetailModal;
