import { Form, Modal, Switch, Typography } from "antd";
import { useState } from "react";

export default function ActiveUnactiveModal(props) {
  const { open, isProcessing, onSubmit, onCancel, restaurant } = props;
  const [sendNoti, setSendNoti] = useState(false);
  const isActive = restaurant.active;

  const onChangeSendNoti = (e) => {
    setSendNoti(e);
  };

  return (
    <Modal
      title={`Xác nhận ${isActive ? "huỷ kích hoạt" : "kích hoạt"} quán`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(restaurant, !isActive, sendNoti)}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Typography.Paragraph>
        Bạn có chắc chắn muốn {isActive ? "huỷ kích hoạt" : "kích hoạt"} quán
        này?
      </Typography.Paragraph>
      {isActive && (
        <Form>
          <Form.Item label="Gửi thông báo hủy kích hoạt đến quán">
            <Switch checked={sendNoti} onChange={onChangeSendNoti} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
