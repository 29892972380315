import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";

function ChangePasswordModal({ open, isProcessing, onSubmit, onCancel }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onValuesChange = (changedValues) => {
    if (changedValues.oldPassword) {
      setOldPassword(changedValues.oldPassword);
    }
    if (changedValues.newPassword) {
      setNewPassword(changedValues.newPassword);
    }
  };

  const onOK = () => {
    if (oldPassword.length < 6 || newPassword.length < 6) {
      toast.error("Mật khẩu phải có ít nhất 6 ký tự");
      return;
    }
    if (oldPassword === newPassword) {
      toast.error("Mật khẩu mới không được trùng với mật khẩu cũ");
      return;
    }
    if (!oldPassword) {
      toast.error("Vui lòng nhập mật khẩu cũ");
      return;
    }
    if (!newPassword) {
      toast.error("Vui lòng nhập mật khẩu mới");
      return;
    }
    onSubmit({ oldPassword, newPassword });
  };

  return (
    <Modal
      title="Đổi mật khẩu"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Form layout="vertical" onValuesChange={onValuesChange}>
        <Form.Item
          name="oldPassword"
          label="Mật khẩu cũ"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input placeholder="Mật khẩu cũ" type="password" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="Mật khẩu mới"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input placeholder="Mật khẩu mới" type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangePasswordModal;
