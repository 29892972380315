import {
  Avatar,
  Button,
  DatePicker,
  Input,
  List,
  Modal,
  Descriptions,
  Empty,
} from "antd";
import { flatten } from "flat";
import { useState } from "react";
import { useEffect } from "react";
import shipperApi from "../../api";
import { getPhotoUrl, priceToCurrency, secondToTime } from "utils/common";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export default function PerformanceModal({ open, onClose, shipper }) {
  const [day, setDay] = useState(dayjs());
  const [performance, setPerformance] = useState(null);

  useEffect(() => {
    const getOrderPerformance = async () => {
      const data = await shipperApi.getPerformance(
        shipper._id,
        day.format("YYYY-MM-DD")
      );
      if (!data || data.error) {
        toast.error(data.error);
        setPerformance(null);
      } else setPerformance(data);
    };
    if (shipper) getOrderPerformance();
  }, [shipper, day]);

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const onDayChange = (date) => {
    setDay(date);
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        title="Hiệu suất tài xế"
        footer={[
          <Button type="primary" key="back" onClick={onClose}>
            OK
          </Button>,
        ]}
      >
        <DatePicker
          value={day}
          onChange={onDayChange}
          format={"DD-MM-YYYY"}
          disabledDate={disabledDate}
        />
        <br />
        <br />
        {performance ? (
          <Descriptions bordered contentStyle={{ fontWeight: 500 }} column={1}>
            <Descriptions.Item label="Tài xế">{shipper.name}</Descriptions.Item>
            <Descriptions.Item label="Tổng Thời gian online">
              {secondToTime(performance.onlineTime)}
            </Descriptions.Item>
            <Descriptions.Item label="Số đơn hoàn thành">
              {performance.totalCompleteOrders}
            </Descriptions.Item>
            <Descriptions.Item label="Số đơn bỏ qua">
              {performance.totalSkipOrders}
            </Descriptions.Item>
            <Descriptions.Item label="Tổng quãng đường">
              {`${performance.totalDistance}km`}
            </Descriptions.Item>
            <Descriptions.Item label="Tổng thu nhập">
              {priceToCurrency(performance.totalEarning)}
            </Descriptions.Item>
            <Descriptions.Item label="Số sao tích lũy">
              {`${performance.point ?? 0} ★`}
            </Descriptions.Item>
            <Descriptions.Item label="Thưởng ngày">
              {priceToCurrency(performance.bonus ?? 0)}
            </Descriptions.Item>
            <Descriptions.Item label="Trả thưởng">
              {performance.rewardPay ? "✅" : "❌"}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <Empty
            description={`Không có dữ liệu hiệu suất tài xế ${shipper.name} trong ngày này!`}
          />
        )}
      </Modal>
    </>
  );
}
