import { Table, Tag, Typography, Image, Switch } from "antd";
import { getPhotoUrl, priceToCurrency } from "utils/common";

const { Paragraph } = Typography;

function LowerBalanceRestaurant(props) {
  const { dataSource, isLoading } = props;

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Hình ảnh",
      width: "10%",
      dataIndex: "avatar",
      render: (avatar) =>
        avatar ? (
          <Image
            alt="Avatar of restaurant"
            src={getPhotoUrl(avatar?.path || null)}
            width="80px"
            height="80px"
          />
        ) : null,
    },
    {
      title: "Tên quán",
      width: "24%",
      // dataIndex: "name",
      render: (record) => (
        <>
          <b style={{ display: "block" }}>{record.name}</b>
          {record.isPartner ? (
            <Tag
              color="blue"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Đối tác
            </Tag>
          ) : null}
          {record.isNew ? (
            <Tag
              color="green"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Quán mới
            </Tag>
          ) : null}
          {record.isHot ? (
            <Tag
              color="orange"
              style={{ display: "inline-block", marginTop: "4px" }}
            >
              Nổi bật
            </Tag>
          ) : null}
        </>
      ),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      width: "20%",
      render: (address) => (
        <Paragraph ellipsis={{ rows: 2, tooltip: address }}>
          {address}
        </Paragraph>
      ),
    },
    {
      title: "Tổng đơn",
      width: "10%",
      align: "center",
      render: (record) => record.orderCount,
    },
    {
      title: "Số dư ví",
      width: "8%",
      render: (record) => priceToCurrency(record.balance ?? 0),
    },
    {
      title: "Chiết khấu",
      width: "10%",
      align: "center",
      render: (record) =>
        record.chargeMode === "default" ? (
          <Tag color="geekblue">Mặc định</Tag>
        ) : (
          <Tag color="orange">{record.charge}%</Tag>
        ),
    },
    {
      title: "Có voucher",
      align: "center",
      width: "10%",
      render: (record) => <Switch size="default" checked={record.isPromo} />,
    },
    {
      title: "Đang mở",
      // dataIndex: "isOpen",
      align: "center",
      width: "10%",
      render: (record) => <Switch size="default" checked={record.isOpen} />,
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      align: "center",
      width: "10%",
      render: (active) =>
        active ? (
          <Tag color="#4caf50">Active</Tag>
        ) : (
          <Tag color="#f44336">In-active</Tag>
        ),
    },
  ];

  return (
    <Table
      bordered
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record._id}
      pagination={false}
    />
  );
}

export default LowerBalanceRestaurant;
