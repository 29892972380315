import { useEffect, useState } from "react";
import ReactMapGL, {
  FlyToInterpolator,
  FullscreenControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { MAPBOX_TOKEN, MAP_STYLE } from "utils/constants";
import icon from "../../../../assets/img/shipper32.png";

function Map(props) {
  const { shippers, selectedShipper, setSelectedShipper } = props;
  const [viewport, setViewport] = useState({
    latitude: selectedShipper?.location
      ? selectedShipper.location[0]
      : 10.928664,
    longitude: selectedShipper?.location
      ? selectedShipper.location[1]
      : 107.242395,
    zoom: 14,
  });

  useEffect(() => {
    if (selectedShipper?.location) {
      setViewport({
        ...viewport,
        longitude: selectedShipper.location[1],
        latitude: selectedShipper.location[0],
        zoom: 15,
        transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
        transitionDuration: "auto",
      });
    } else {
      setViewport({
        ...viewport,
        latitude: 10.928664,
        longitude: 107.242395,
        zoom: 14,
        transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
        transitionDuration: "auto",
      });
    }
  }, [selectedShipper]);

  const onViewportChange = (viewport) => {
    setViewport(viewport);
  };

  // const debouncedOnViewportChange = debounce(onViewportChange, 0);

  const onClickMarker = (shipper) => {
    setSelectedShipper(shipper);
    // setViewport((viewport) => ({
    //     ...viewport,
    //     longitude: shipper.location ? shipper.location[1] : 107.242395,
    //     latitude: shipper.location ? shipper.location[0] : 10.928664,
    //     transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
    //     transitionDuration: "auto",
    // }));
  };

  return (
    <div className="map">
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle={MAP_STYLE}
        onViewportChange={onViewportChange}
      >
        {" "}
        {shippers?.length
          ? shippers
              .filter((shipper) => shipper.location)
              .map((shipper) => (
                <Marker
                  key={shipper._id}
                  longitude={shipper.location[1]}
                  latitude={shipper.location[0]}
                  // offsetLeft={-20}
                  // offsetTop={-23}
                >
                  <img
                    src={icon}
                    className={`shipper-marker ${
                      selectedShipper?.phone === shipper.phone ? "active" : ""
                    }`}
                    onClick={() => onClickMarker(shipper)}
                  />
                </Marker>
              ))
          : null}
        <div className="map-fullscreen">
          <FullscreenControl />
        </div>
        <div className="map-nav">
          <NavigationControl />
        </div>
        {/* <div className="map-geo">
                    <GeolocateControl
                        positionOptions={{ enableHighAccuracy: true }}
                        trackUserLocation={true}
                    />
                </div> */}
      </ReactMapGL>
    </div>
  );
}

export default Map;
