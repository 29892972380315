import {
  CloseOutlined,
  LockOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Alert, Button, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { formItemLayoutHorizontal } from "utils/constants";
import { formatTime, isValidTime } from "utils/common";

moment.locale("vi");

const DAYS = [
  { name: "Thứ 2", value: 1 },
  { name: "Thứ 3", value: 2 },
  { name: "Thứ 4", value: 3 },
  { name: "Thứ 5", value: 4 },
  { name: "Thứ 6", value: 5 },
  { name: "Thứ 7", value: 6 },
  { name: "Chủ nhật", value: 0 },
];

function TimeModal(props) {
  const [form] = Form.useForm();
  const { time, open, onCancel, onDone } = props;
  const initialValues = { time: { ...time } };

  const [_time, setTime] = useState(time);

  useEffect(() => {
    setTime({ ...time });
  }, [open, time]);

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const time = { ...values.time };
        for (let day of Object.keys(time)) {
          if (day === "type") continue;
          for (let i = 0; i < time[day].length; i++) {
            time[day][i].open = formatTime(time[day][i].open);
            time[day][i].close = formatTime(time[day][i].close);
            if (time[day][i].close < time[day][i].open)
              time[day][i].close += 1440;
          }
        }
        return onDone(time);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkTime = (rule, value) => {
    if (value.trim() === "") {
      return Promise.reject("Trường này là bắt buộc");
    }
    if (!isValidTime(value)) {
      return Promise.reject("Thời gian không đúng định dạng");
    }
    return Promise.resolve();
  };

  const onSetSameTime = () => {
    let currentTime = form.getFieldValue("time");
    for (let day of Object.keys(time)) {
      if (day === "type") continue;
      currentTime[day] = [...currentTime[1]];
    }
    form.setFieldsValue({ time: currentTime });
    setTime({ ..._time, ...currentTime });
  };

  const onAddTime = (day) => {
    let currentTime = form.getFieldValue("time");
    currentTime[day].push({ open: "07:00", close: "23:00" });
    setTime({ ..._time, ...currentTime });
  };

  const onRemoveTime = (day) => {
    let currentTime = form.getFieldValue("time");
    currentTime[day].pop();
    setTime({ ..._time, ...currentTime });
  };

  return (
    <Modal
      title="Giờ mở cửa"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      width={580}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      className="time-modal"
    >
      <Form
        {...formItemLayoutHorizontal}
        initialValues={initialValues}
        name="timeForm"
        form={form}
      >
        <div>
          <Alert
            style={{ marginBottom: "10px" }}
            message="* Nếu đóng cửa: chọn giờ mở cửa và giờ đóng cửa là 00:00"
            type="info"
          />
          <Alert
            style={{ marginBottom: "20px" }}
            message="* Nếu mở cửa cả ngày: chọn giờ mở cửa là 00:00 và giờ đóng cửa là
        23:59"
            type="info"
          />
          {DAYS.map((day) => (
            <Form.Item
              key={day.value}
              label={day.name}
              style={{ marginBottom: 0 }}
            >
              {_time[`${day.value}`].map((t, index) => (
                <Fragment key={"time_" + index}>
                  <Form.Item
                    name={["time", day.value, index, "open"]}
                    rules={[{ validator: checkTime }]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 60px)",
                    }}
                  >
                    <Input placeholder="Mở cửa" suffix={<UnlockOutlined />} />
                    {/* <TimePicker placeholder="Mở cửa" minuteStep={5} format={"HH:mm"} suffixIcon={<UnlockOutlined />} style={{width: "100%"}}/> */}
                  </Form.Item>
                  <span
                    style={{
                      display: "inline-block",
                      width: "30px",
                      lineHeight: "32px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    -{" "}
                  </span>
                  <Form.Item
                    name={["time", day.value, index, "close"]}
                    rules={[{ validator: checkTime }]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 60px)",
                    }}
                  >
                    <Input placeholder="Đóng cửa" suffix={<LockOutlined />} />
                    {/* <TimePicker placeholder="Đóng cửa" minuteStep={5} format={"HH:mm"} suffixIcon={<LockOutlined />} style={{width: "100%"}}/> */}
                  </Form.Item>
                  <Button
                    style={{ height: "32px", marginLeft: "10px" }}
                    size={"small"}
                    onClick={() => onAddTime(day.value)}
                  >
                    <PlusOutlined />
                  </Button>
                  {index !== 0 ? (
                    <Button
                      style={{ height: "32px", marginLeft: "10px" }}
                      size={"small"}
                      onClick={() => onRemoveTime(day.value)}
                    >
                      <CloseOutlined />
                    </Button>
                  ) : null}
                </Fragment>
              ))}
              {day.value === 1 ? (
                <Form.Item
                  style={{
                    marginTop: "-18px",
                    marginBottom: "6px",
                    marginRight: "20px",
                  }}
                >
                  <Button
                    block
                    size={"small"}
                    type="primary"
                    onClick={onSetSameTime}
                  >
                    Áp dụng cho các ngày khác
                  </Button>
                </Form.Item>
              ) : null}
            </Form.Item>
          ))}
        </div>
      </Form>
      {/* <Alert message="* Nếu đóng cửa: chọn giờ mở cửa và giờ đóng cửa là 00:00" type="info" style={{ marginBottom: "10px" }} />
      <Alert message="* Nếu mở cửa cả ngày: chọn giờ mở cửa là 00:00 và giờ đóng cửa là
        23:59" type="info" style={{ marginBottom: "20px" }} /> */}
    </Modal>
  );
}

export default TimeModal;
