import moment from "moment";
import { CDN_PATH } from "./constants";

export const classNames = (...classes) => {
  return classes.filter((className) => !!className).join(" ");
};

export const getPhotoUrl = (path, query) => {
  if (!path || typeof path !== "string") return null;
  if (path.includes("http")) return path;
  if (path.includes("default-avatar.svg")) return path;
  // path = path.replace("uploads", "static");
  if (query) return `${CDN_PATH}/${path}?${query}`;
  return `${CDN_PATH}/${path}`;
};

export const getPhotoThumbnailUrl = (path) => {
  return getPhotoUrl(path, "w=200&h=200");
};

export const getSlug = (str) => {
  str = str.toLowerCase();
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
  str = str.replace(/(đ)/g, "d");
  str = str.replace(/([^0-9a-z-\s])/g, "");
  str = str.replace(/- /g, "");
  str = str.replace(/(\s+)/g, "-");
  str = str.replace(/^-+/g, "");
  str = str.replace(/-+$/g, "");
  return str;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const parseTime = (time) => {
  time = time % 1440;
  const hour = time / 60;
  const minute = time % 60;
  return moment(`${hour}:${minute}`, "H:m").format("HH:mm");
};

export const isValidTime = (time) => {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
};

export const isValidLatitude = (lat) => {
  return isFinite(lat) && Math.abs(lat) <= 90;
};

export const isValidLongitude = (lng) => {
  return isFinite(lng) && Math.abs(lng) <= 180;
};

export const formatTime = (time) => {
  time = moment(time, "HH:mm");
  const hour = time.hour();
  const minute = time.minute();
  return hour * 60 + minute;
};

export const priceToCurrency = (price) =>
  Number(price).toLocaleString("vi-VN", { style: "currency", currency: "VND" });

export const currencyToPrice = (currency) => {
  return Number(currency.replace(/[^0-9-]+/g, ""));
};

export const secondToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours}:${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  } else if (minutes > 0) {
    formattedTime += `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
  } else {
    formattedTime += `${remainingSeconds}s`;
  }

  return formattedTime;
};

export const downloadCSV = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};

export const calculateCenterFromPoints = (polygonPoints) => {
  // Kiểm tra xem mảng tọa độ có rỗng hay không
  if (polygonPoints.length === 0) {
    throw new Error("Mảng tọa độ rỗng");
  }

  // Tính toán trung bình cộng của tọa độ x và y
  let sumX = 0;
  let sumY = 0;

  for (const point of polygonPoints) {
    sumX += point[0];
    sumY += point[1];
  }

  const centerX = sumX / polygonPoints.length;
  const centerY = sumY / polygonPoints.length;

  // Trả về tọa độ trung tâm
  return [centerX, centerY];
};
