import { DatePicker, Form, Input, Modal, Typography } from "antd";
import moment from "moment";
import { useState } from "react";

const disabledDate = (current) => current && current.diff(moment(), "days") < 0;

export default function BlockModal(props) {
  const { open, isProcessing, onSubmit, onCancel, shipper } = props;
  const [blockedReason, setBlockedReason] = useState("Vi phạm nội quy");
  const [blockedTo, setBlockedTo] = useState("");
  const isBlocked = shipper?.blocked || false;

  const onChangeBlockedTo = (time) => {
    setBlockedTo(time);
  };

  const onChangeBlockedReason = (e) => {
    setBlockedReason(e.target.value);
  };

  return (
    <Modal
      title={`Xác nhận ${isBlocked ? "bỏ khóa" : "khóa"}  xế`}
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={() => onSubmit(shipper, { blockedTo, blockedReason })}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Typography.Paragraph>
        Bạn có chắc chắn muốn {isBlocked ? "bỏ khóa" : "khóa"} xế này?
      </Typography.Paragraph>
      {!isBlocked && (
        <Form layout="vertical">
          <Form.Item label="Khóa đến ngày">
            <DatePicker
              showTime
              onChange={onChangeBlockedTo}
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              placeholder={"Thời gian hết bị khóa"}
            />
          </Form.Item>
          <Form.Item label="Lý do khóa">
            <Input.TextArea
              value={blockedReason}
              onChange={onChangeBlockedReason}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
