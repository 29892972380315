import axiosClient from "utils/api/axios-client";

const reviewApi = {
  getAllShipper: (params) => {
    const url = "/api/v1/shipper-reviews";
    return axiosClient.get(url, { params });
  },
  getAllRestaurant: (params) => {
    const url = "/api/v1/restaurant-reviews";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/reviews/${id}`;
    return axiosClient.get(url);
  },
  update: (review) => {
    const url = `/api/v1/reviews/${review._id}`;
    return axiosClient.put(url, review);
  },
  updateHiddenStatus: (id, hiddenReason) => {
    const url = `/api/v1/reviews/${id}/hidden`;
    return axiosClient.put(url, { hiddenReason });
  },
  delete: (id) => {
    const url = `/api/v1/reviews/${id}`;
    return axiosClient.delete(url);
  },
};

export default reviewApi;
