import { Form, Modal, Tabs } from "antd";
import { getPhotoThumbnailUrl } from "utils/common";
import TabAccount from "./TabAccount";
import TabBlock from "./TabBlock";
import TabInfo from "./TabInfo";
import TabBanking from "./TabBanking";

function ReviewDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onCancel, user } = props;
  const imageUrl = getPhotoThumbnailUrl(user.avatar?.path || null);

  const items = [
    {
      key: "info",
      label: "Thông tin",
      forceRender: true,
      children: <TabInfo user={user} imageUrl={imageUrl} />,
    },
    {
      key: "account",
      label: "Chi tiết thiết bị",
      forceRender: true,
      children: <TabAccount user={user} />,
    },
    {
      key: "banking",
      label: "Tài khoản ngân hàng",
      forceRender: true,
      children: <TabBanking user={user} />,
    },
    {
      key: "block",
      label: "Chi tiết chặn",
      forceRender: true,
      disabled: !user.blocked,
      children: <TabBlock user={user} />,
    },
  ];

  return (
    <Modal
      title="Người dùng"
      cancelText="Đóng"
      centered
      open={open}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Form layout="vertical" form={form} initialValues={user}>
        <Tabs defaultActiveKey="info" items={items} />
      </Form>
    </Modal>
  );
}

export default ReviewDetailModal;
