import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import * as authApi from "./api";

const initialState = {
  isLoggedIn: false,
  userInfo: null,
  isProcessing: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsProcessing: (state, action) => {
      state.isLoggingIn = action.payload;
    },
    checkTokenAndLogin: (state, action) => {
      const token = action.payload?.token || localStorage.getItem("token");
      let isValid = false;
      if (token) {
        const tokenInfo = jwtDecode(token);
        if (tokenInfo?.exp && new Date(tokenInfo.exp * 1000) > new Date()) {
          state.isLoggedIn = true;
          state.userInfo = tokenInfo;
          isValid = true;
          if (action.payload?.navigate) {
            action.payload.navigate("/");
          }
        }
      }
      if (!isValid) {
        localStorage.removeItem("token");
        window.location.href = "/dang-nhap";
      }
    },
    logout: (state, action) => {
      state.isLoggedIn = false;
      state.userInfo = null;
      localStorage.removeItem("token");
      window.location.href = action.payload ? "/dang-nhap?pw=1" : "/dang-nhap";
    },
  },
});

export const login = (username, password, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const token = await authApi.login({ username, password });
      if (token) {
        localStorage.setItem("token", token);
        dispatch(checkTokenAndLogin({ token, navigate }));
      }
    } catch (e) {
      console.error(e);
      toast.error(e);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch(setIsProcessing(true));
      const response = await authApi.changePassword({
        oldPassword,
        newPassword,
      });
      if (response) {
        dispatch(logout(true));
      }
    } catch (e) {
      console.error(e);
      toast.error(e);
    } finally {
      dispatch(setIsProcessing(false));
    }
  };
};

export const { checkTokenAndLogin, logout, setIsProcessing } =
  authSlice.actions;

export default authSlice.reducer;
