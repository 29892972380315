import ShipperItem from "./ShipperItem";

function List(props) {
  const { shippers, selectedShipper, setSelectedShipper } = props;
  return (
    <div className="list">
      <ul>
        {shippers.map((shipper) => (
          <ShipperItem
            shipper={shipper}
            selectedShipper={selectedShipper}
            setSelectedShipper={setSelectedShipper}
            key={shipper._id}
          />
        ))}
      </ul>
    </div>
  );
}

export default List;
