import axiosClient from "utils/api/axios-client";

const userApi = {
  getAll: (params) => {
    const url = "/api/v1/users";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/users/${id}`;
    return axiosClient.get(url);
  },
  update: (user) => {
    const url = `/api/v1/users/${user._id}`;
    return axiosClient.put(url, user);
  },
  block: (id, blockedReason) => {
    const url = `/api/v1/users/${id}/block`;
    return axiosClient.put(url, { blockedReason });
  },
  activate: (id) => {
    const url = `/api/v1/users/${id}/active`;
    return axiosClient.put(url);
  },
};

export default userApi;
