import { CarOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Empty, Image, Modal } from "antd";
import { useEffect, useState } from "react";
import { getPhotoUrl } from "utils/common";
import orderApi from "../api";

export default function ChatModal(props) {
  const { open = true, onClose, order } = props;
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && order?._id) {
      onRefresh();
    }
  }, [open]);

  const onRefresh = async () => {
    setIsLoading(true);
    try {
      const data = await orderApi.getMessages(order._id);
      setMessages(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setMessages([]);
    onClose();
  };
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title={`Lịch sử chat đơn hàng #${order.code}`}
      footer={[
        <Button key="back" onClick={handleClose}>
          Đóng
        </Button>,
        <Button
          key="refresh"
          type="primary"
          onClick={onRefresh}
          loading={isLoading}
        >
          Refresh
        </Button>,
      ]}
    >
      <div className="chat-wrapper">
        {messages.length === 0 && <Empty description="Không có tin nhắn nào" />}
        {messages.map((message) => {
          return (
            <div className={`chat-item ${message.sender}`} key={message._id}>
              <div className="chat-avatar">
                <Avatar
                  icon={
                    message.sender === "User" ? (
                      <UserOutlined />
                    ) : (
                      <CarOutlined />
                    )
                  }
                  style={{ backgroundColor: "#FFD22F" }}
                />
              </div>
              <div className="chat-content-wrapper">
                <div className="chat-content">
                  {message.type === "text" && (
                    <div className="chat-text">{message.message}</div>
                  )}
                  {message.type === "image" && message.imageSrc && (
                    <div className="chat-image">
                      <Image src={message.imageSrc} />
                    </div>
                  )}
                  {message.type === "image" && message.image?.path && (
                    <div className="chat-image">
                      <Image
                        src={getPhotoUrl(message.image.path)}
                        alt="Lỗi hiển thị"
                      />
                    </div>
                  )}
                  {message.type === "location" && message.location && (
                    <div className="chat-location">
                      <Image src={message.location.image} />
                    </div>
                  )}
                  {message.type === "banking" && message.banking && (
                    <div className="chat-banking">
                      Ngân hàng: {message.banking.bankName}
                      <br />
                      Số tài khoản: {message.banking.accountNumber}
                      <br />
                      Chủ tài khoản: {message.banking.accountName}
                    </div>
                  )}
                </div>
                <div className="chat-time">{message.createdAt}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
