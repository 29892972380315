import { Form, Input, InputNumber, Modal, Segmented, Select } from "antd";
import { toast } from "react-toastify";
import { currencyToPrice, priceToCurrency } from "utils/common";

function WalletRequestDetailModal(props) {
  const [form] = Form.useForm();
  const { open, isProcessing, onSubmit, onCancel } = props;

  const initialValues = {
    type: "RECHARGE",
    creatorModel: "",
    creator: "",
    amount: 0,
    note: "",
  };

  const onOK = () => {
    form
      .validateFields()
      .then((values) => {
        const walletRequest = { ...initialValues, ...values };
        onSubmit(walletRequest);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Modal
      title="Lệnh nạp/rút ví"
      okText="Lưu"
      cancelText="Huỷ"
      centered
      open={open}
      onOk={onOK}
      onCancel={onCancel}
      confirmLoading={isProcessing}
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <Form.Item
          name="type"
          label="Loại giao dịch"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Segmented
            options={[
              {
                label: "Nạp tiền",
                value: "RECHARGE",
              },
              {
                label: "Rút tiền",
                value: "WITHDRAW",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="creatorModel"
          label="Đối tượng giao dịch"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Cụm",
                value: "Region",
              },
              {
                label: "Người dùng",
                value: "User",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="creator"
          label="ID đối tượng"
          tooltip="ID của Cụm hoặc Người dùng, dựa theo Đối tượng giao dịch đã chọn."
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Số tiền"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
            {
              type: "number",
              min: 1000,
              message: "Số tiền tối thiểu là 1.000đ",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            formatter={(value) => priceToCurrency(value)}
            parser={(value) => currencyToPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="note"
          label="Ghi chú"
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default WalletRequestDetailModal;
