import { Divider, Row, Table, Typography } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "./slice";

function Contact() {
  const dispatch = useDispatch();
  const { contacts, pagination, isLoading } = useSelector(
    (state) => state.contact
  );

  useEffect(() => {
    dispatch(fetchContacts(pagination));
  }, [dispatch]);

  const onTableChange = async (pagination) => {
    dispatch(
      fetchContacts({
        ...pagination,
      })
    );
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "App",
      dataIndex: "app",
      width: "10%",
      render: (app) => (
        <div className="p-2 bg-yellow-200 rounded-full text-center lg:w-fit">
          <span>{app}</span>
        </div>
      ),
    },
    {
      title: "Người gửi",
      width: "20%",
      render: (record) => (
        <>
          <Typography.Paragraph
            strong
            copyable={{
              text: record.author,
            }}
          >
            {record.author?.restaurant?.name ||
              record.author?.name ||
              "#" + (record.author || "no_login")}
          </Typography.Paragraph>
          <Typography.Text className="whitespace-nowrap">
            {record.author?.phone || record.phone || "_"}
          </Typography.Text>
        </>
      ),
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      render: (content) => (
        <Typography.Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: "Xem thêm" }}
        >
          {content}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      align: "center",
      width: "10%",
      render: (createdAt) => moment(createdAt).fromNow(),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Typography.Title level={2}>Quản Lý Liên Hệ</Typography.Title>
      </Row>
      <Divider />
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={contacts}
        rowKey={(record) => record._id}
        pagination={pagination}
        onChange={onTableChange}
      />
    </>
  );
}

export default Contact;
