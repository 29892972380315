import { Form, Input, Typography } from "antd";
const { Paragraph } = Typography;

export default function TabAccount({ user }) {
  return (
    <>
      <Form.Item name="deviceId" label="Mã thiết bị">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="appToken" label="FCM Token">
        {user?.appTokens?.map((token) => (
          <Paragraph copyable key={token}>
            {token}
          </Paragraph>
        )) || null}
      </Form.Item>
    </>
  );
}
