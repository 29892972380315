import { Button, Divider, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import api from "./api";

export default function System() {
  const [banking, setBanking] = useState({});
  const [isLoadingBanking, setIsLoadingBanking] = useState(true);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const getBanks = async () => {
      const data = await commonApi.getBanks();
      if (!data || data.error) {
        toast.error(data.error.msg);
      } else {
        setBanks(data);
      }
    };
    const getBanking = async () => {
      try {
        setIsLoadingBanking(true);
        const data = await api.getBanking();
        setBanking(data.data);
      } catch (error) {
        console.error(error);
        toast.error("Lỗi khi lấy dữ liệu banking");
      } finally {
        setIsLoadingBanking(false);
      }
    };
    getBanks();
    getBanking();
  }, []);

  const onSaveBanking = async () => {
    setIsLoadingBanking(true);
    try {
      const data = await api.updateBanking(banking);
      if (data.error) throw data.error;
      toast.success("Cập nhật thành công");
    } catch (error) {
      console.error(error);
      toast.error(error.msg || "Lỗi khi cập nhật banking");
    } finally {
      setIsLoadingBanking(false);
    }
  };

  const handleChangeBanking = (key, value) => {
    setBanking({ ...banking, [key]: value });
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      autoComplete="off"
    >
      <Divider orientation="left">Tài khoản ngân hàng GOKA</Divider>
      <br />
      <Form.Item
        label="Ngân hàng"
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Select
          showSearch
          placeholder="Chọn ngân hàng"
          optionFilterProp="label"
          options={banks.map((bank) => ({
            value: bank.id,
            label: bank.name,
          }))}
          value={banking.bankId}
          onChange={(value) => handleChangeBanking("bankId", value)}
        ></Select>
      </Form.Item>
      <Form.Item
        label="Số tài khoản"
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input
          placeholder="Nhập số tài khoản"
          value={banking.accountNumber}
          onChange={(e) => handleChangeBanking("accountNumber", e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Chủ tài khoản"
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input
          placeholder="Nhập chủ tài khoản"
          value={banking.accountName}
          onChange={(e) => handleChangeBanking("accountName", e.target.value)}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingBanking}
          onClick={onSaveBanking}
        >
          Cập nhật
        </Button>
      </Form.Item>
    </Form>
  );
}
