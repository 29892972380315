import axiosClient from "utils/api/axios-client";

const bannerApi = {
  getAll: (params) => {
    const url = "/api/v1/banners";
    return axiosClient.get(url, { params });
  },
  get: (id) => {
    const url = `/api/v1/banners/${id}`;
    return axiosClient.get(url);
  },
  create: (banner) => {
    const url = `/api/v1/banners`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, banner, config);
  },
  update: (id, banner) => {
    const url = `/api/v1/banners/${id}`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.put(url, banner, config);
  },
  delete: (id) => {
    const url = `/api/v1/banners/${id}`;
    return axiosClient.delete(url);
  },
};

export default bannerApi;
