import { useEffect, useState } from "react";
import Icon from "assets/img/icon.png";
import { Button, Form, Input } from "antd";
import * as authActions from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isProcessing } = useSelector((state) => state.auth);

  useEffect(() => {
    if (location.search === "?pw=1") {
      toast.success(
        "Đổi mật khẩu thành công! Vui lòng đăng nhập lại bằng mật khẩu mới."
      );
    }
  }, []);

  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
  });

  const onChangeLoginInfo = (property) => (e) => {
    setLoginInfo({ ...loginInfo, [property]: e.target.value });
  };

  const onLogin = async () => {
    dispatch(
      authActions.login(loginInfo.username, loginInfo.password, navigate)
    );
  };

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="w-1/5 rounded-xl bg-white drop-shadow-2xl p-4">
          <div className="flex items-center justify-center mb-4">
            <img src={Icon} alt="logo" height={60} width={60} />
            <h1 className="text-xl font-bold">Admin Page</h1>
          </div>
          <Form onFinish={onLogin}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Trường này là bắt buộc" }]}
              hasFeedback
            >
              <Input
                placeholder="Email hoặc tên người dùng"
                onChange={onChangeLoginInfo("username")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Trường này là bắt buộc" }]}
              hasFeedback
            >
              <Input
                type="password"
                placeholder="Mật khẩu"
                onChange={onChangeLoginInfo("password")}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={isProcessing}
            >
              Đăng nhập
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
