import { Button, DatePicker, Divider, Form, Input } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "../slice";

export default function FreeVoucher() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { config, isLoading } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    const freeVouchers = {
      bestGoka: config.freeVouchers?.bestGoka,
      newUser: config.freeVouchers?.newUser,
      newUserFrom: config.freeVouchers?.newUserFrom
        ? dayjs(config.freeVouchers?.newUserFrom)
        : null,
    };
    const { categoryHasVoucher, campaignFreeShip } = config;
    form.setFieldsValue({ freeVouchers, categoryHasVoucher, campaignFreeShip });
  }, [config]);

  const onFinish = (config) => {
    dispatch(updateConfig({ ...config }));
  };

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      autoComplete="off"
      onFinish={onFinish}
    >
      <Divider orientation="left">Tặng voucher Best GOKA</Divider>
      <br />
      <Form.Item
        label="ID Voucher tặng"
        name={["freeVouchers", "bestGoka"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input placeholder="Nhập ID Voucher Best GOKA" />
      </Form.Item>
      <br />
      <Divider orientation="left">Tặng voucher cho khách đăng ký mới</Divider>
      <br />
      <Form.Item
        name={["freeVouchers", "newUserFrom"]}
        label="Từ ngày"
        rules={[
          {
            required: true,
            message: "Trường này là bắt buộc",
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%" }}
          format="YYYY-MM-DD"
          placeholder={"Ngày khách đăng ký mới được tặng voucher"}
        />
      </Form.Item>
      <Form.Item
        label="ID Voucher tặng"
        name={["freeVouchers", "newUser"]}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input placeholder="Nhập ID Voucher tặng khách đăng ký mới" />
      </Form.Item>
      <br />
      <Divider orientation="left">Danh mục khuyến mãi</Divider>
      <br />
      <Form.Item
        label="ID danh mục"
        name={"categoryHasVoucher"}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input placeholder="Nhập ID danh mục khuyến mãi" />
      </Form.Item>
      <br />
      <Divider orientation="left">Chiến dịch freeship</Divider>
      <br />
      <Form.Item
        label="ID chiến dịch"
        name={"campaignFreeShip"}
        rules={[{ required: true, message: "Trường này là bắt buộc" }]}
      >
        <Input placeholder="Nhập ID chiến dịch freeship" />
      </Form.Item>
      <br />
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Cập nhật
        </Button>
      </Form.Item>
    </Form>
  );
}
