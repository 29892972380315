import { Button, Card, Divider, Row, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { priceToCurrency } from "utils/common";
import api from "./api";
import { createWithdrawRequest, fetchTransactions } from "./slice";
import Transactions from "./Transactions";
import { WalletOutlined } from "@ant-design/icons";
import WithdrawModal from "./WithdrawModal";

export default function Wallet() {
  const dispatch = useDispatch();
  const { transactions, pagination, isLoading, isProcessing, isCompleted } =
    useSelector((state) => state.walletTransaction);

  const [balance, setBalance] = useState(0);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);

  const getBalance = async () => {
    try {
      setIsLoadingBalance(true);
      const data = await api.getWalletBalance();
      setBalance(data.data);
    } catch (err) {
      console.log(err);
      toast.error("Lỗi khi lấy số dư ví");
    } finally {
      setIsLoadingBalance(false);
    }
  };

  useEffect(() => {
    getBalance();
    dispatch(fetchTransactions(pagination));
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setOpenWithdrawModal(false);
      getBalance();
    }
  }, [isCompleted]);

  const onTableChange = async (pagination) => {
    dispatch(
      fetchTransactions({
        ...pagination,
      })
    );
  };

  const onWithdraw = () => {
    setOpenWithdrawModal(true);
  };

  const onCancelWithdraw = () => {
    setOpenWithdrawModal(false);
  };

  const onCreateWithdrawRequest = async (amount) => {
    if (!amount) {
      toast.error("Vui lòng nhập số tiền cần rút");
      return;
    }
    dispatch(createWithdrawRequest({ amount }));
  };

  return (
    <>
      <Divider orientation="left">Số dư ví</Divider>
      {isLoadingBalance ? (
        <div
          style={{
            margin: "20px 0",
            padding: "30px 50px",
            textAlign: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Card
          style={{
            width: 380,
          }}
        >
          <Row align="middle" justify="space-between">
            <h1>
              <WalletOutlined style={{ marginRight: 10 }} />
              {priceToCurrency(balance)}
            </h1>
            <Button type="primary" onClick={onWithdraw}>
              Rút tiền
            </Button>
          </Row>
        </Card>
      )}
      <br />
      <Divider orientation="left">Lịch sử giao dịch</Divider>
      <Transactions
        transactions={transactions}
        pagination={pagination}
        isLoading={isLoading}
        onTableChange={onTableChange}
      />

      {openWithdrawModal && (
        <WithdrawModal
          open={openWithdrawModal}
          isProcessing={isProcessing}
          onCancel={onCancelWithdraw}
          onSubmit={onCreateWithdrawRequest}
        />
      )}
    </>
  );
}
